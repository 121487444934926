import React, {useEffect, useRef, useState } from "react";
import {useHistory,useParams} from "react-router-dom"
import {secureApiCall} from "../../../services/conf/apiCall"
import { ToastContainer, toast } from "react-toastify";
import {getUserData} from "../../../helpers/utils";
import "../step1/step.scss";

const Step = (props) => {
  const history=useHistory();
  const params=useParams();
  console.log("Step -> params", params)
  const search_box=useRef();
  const getTokenData=getUserData();

  let viewFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/my-friends";
  const sendInviteFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/invite-friends";
  const sendInviteTribeUrl=process.env.REACT_APP_API_ENDPOINT+"/api/invite-tribes";
  const searchFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/search-friends";
  const getAllTribesUrl = process.env.REACT_APP_API_ENDPOINT + "/api/list-all-tribes";


  const [usersData,setUsersData]=useState([]);
  const [tribesData,setTribesData]=useState([]);
  const [invite, setInvite] = useState([]);
  const [tribe, setTribe] = useState([]);
  const [searchedUser,setSearchedUser]=useState([]);
  const [view,setView]=useState(false);
  const [disableBtn,setDisableBtn]=useState(false);
  


    useEffect(() => {
    secureApiCall(viewFriendUrl,"get")
    .then((response)=>{
      console.log(response);
      if(!response.err){
        setUsersData(response.payload);
        // console.log(response.payload)
      }
    }).catch((err)=>{
      console.log(err);
    })

    secureApiCall(getAllTribesUrl, "get")
    .then((response) => {
      console.log("---asad---asd",response);
      setTribesData(response.payload);
    })
    .catch((err) => {
      console.log(err);
    });
  },[])

  let {event_id,event_name,event_type}=props.stepData;
  
  console.log("----imp data------",props.stepData)
// console.log(invite)
const sendInviteFriend = () => {
  try{
    let switchId="publish";
    if(props.isEdit){
      switchId="update";
    }
    secureApiCall(sendInviteFriendUrl,"post",JSON.stringify({invite,event_id,event_name}),{'Content-type':'application/json'})
    .then((response) => {
        console.log("--response--", response);
          switch(switchId){
            case "publish":
               history.push("/events");
            break;
            case "update": 
              history.push(`/my-event-detail/${params.slug}`);
            break;
            default:console.log('error');
          }
        toast.success(response.msg);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  }catch(err){
    console.log(err);
    toast.error(err.msg);
    } 
  };
  const sendInviteTribe = () => {
    try{
      let switchId="publish";
      if(props.isEdit){
        switchId="update";
      }
      secureApiCall(sendInviteTribeUrl,"post",JSON.stringify({tribe,event_id,event_name}),{'Content-type':'application/json'})
      .then((response) => {
          console.log("--response--", response);
            switch(switchId){
              case "publish": history.push("/events");
              break;
              case "update": history.push(`/my-event-detail/${params.slug}`);
              break;
              default:console.log('error');
            }
          toast.success(response.msg);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err);
        });
    }catch(err){
      console.log(err);
      toast.error(err.msg);
      } 
    };
    
  const handleUserSearch=(e,userName)=>{
    console.log("--my data",search_box.current.value)
    secureApiCall(searchFriendUrl,"post",JSON.stringify({user_name:search_box.current.value}),{'Content-type':'application/json'})
      .then((response) => {
        console.log("--searched user--",response.payload)
         setSearchedUser(response.payload)
         setUsersData(response.payload)
       })
      .catch((err) => {
          console.log(err);
      });
      // search_box.current.value="";

  }

  const handelNextClick = () => {
    props.nextStep();
  };
  const handelPrevClick = (e) => {
    props.prevStep();
  };

  return (
    <div className="steps-form create-steps">
      <div className="row search-results"> <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <div className="event_training_checkbox">
              <input
                className="change-btn change-btn-secondary"
                type="button"
                id="radioButton1"
                defaultChecked
                value="Friends"
                name="friends/tribe"
                onClick={(e) => {
                  setView(false);
                }}
              />
              {/* <label
                className="form-check-label text_size padding_right"
                for="radioButton1"
              >
              Friends
              </label> */}
            </div>
            <div
              className="event_training_checkbox">
              <input
                className="change-btn change-btn-secondary"
                type="button"
                id="radioButton2"
                value="Tribes"
                name="friends/tribe"
                onClick={(e) => {setView(true);
                }}
              />
              {/* <label className="form-check-label text_size" for="radioButton2">
                Tribes
              </label> */}
            </div>
          </div>
        </div>
        {!view?
          <div className="col-md-6 col-md-offset-3">
          
          <div className="form-group">
            <label className="text-center">
              Invite your friends to join...
            </label>
            <input
              className="form-control"
              placeholder="Search for friends"
              type="text"
              ref={search_box}
              
            />
            <a  onClick={()=>handleUserSearch()} className="icon-btn">
              <i className="fas fa-search" />
            </a>
          </div>
          <label className="text-center">
            Recommended Friends / Search Results
          </label>
          <ul>
          
          {usersData && usersData.map((value,index)=>(
            <li key={index}>
              <div className="img-box">
                <img
                  src={require("../../../assets/img/email-icon.png")}
                  alt=""
                />
              </div>
              <div className="user-detail">
                 <h6 className="username">{value.friend_name}</h6>
                <p className="stream">Runner</p>
                <p className="location">Leicester</p>
              </div>
              <label className="customcheck">
                <input type="checkbox"
                value={ value._id }
                onChange={
                  (e)=>{
                    let user_id= e.target.value;
                        if( e.target.checked){
                          invite.push(user_id)
                          if(invite.length>0){
                            setDisableBtn(true);
                          }
                        }else{
                          console.log("position ", invite.indexOf(user_id) ,  user_id );
                         invite.splice(invite.indexOf(user_id),1)
                         if(invite.length<=0){
                          setDisableBtn(false);
                          }
                        }
                        setInvite(invite)
                        console.log( invite)
                      
                      }
                    
                    }
                />
                <span className="checkmark" />
              </label>
            </li>
          ))}
          
           </ul>
          <div className="action">
          <span></span>
            {disableBtn?
              <button className="btn btn-orange" type="submit" onClick={sendInviteFriend}>
              Invite to Event
            </button>
            :
            <></>
            }
          </div>
          <div className="text-center">
          {event_type==="event"?
            <a href="/events" className="view-event">
              Skip &amp; View Your Event
            </a>
            :
            <a href="/trainings" className="view-event">
              Skip &amp; View Your Trainings
            </a>
          }
          </div>
        </div>
          :
          <div className="col-md-6 col-md-offset-3">
          
          <div className="form-group">
            <label className="text-center">
              Invite your tribes to join...
            </label>
            <input
              className="form-control"
              placeholder="Search for friends"
              type="text"
              ref={search_box}
              
            />
            <a  onClick={()=>handleUserSearch()} className="icon-btn">
              <i className="fas fa-search" />
            </a>
          </div>
          <label className="text-center">
            Recommended Tribes / Search Results
          </label>
          <ul>
          
          {tribesData && tribesData.map((value,index)=>(
          
          value.owner_id===getTokenData._id?
            <li key={index}>
              <div className="img-box">
                <img
                  src={require("../../../assets/img/email-icon.png")}
                  alt=""
                />
              </div>
              <div className="user-detail">
                 <h6 className="username">{value.title}</h6>
                <p className="stream">Runner</p>
                <p className="location">Leicester</p>
              </div>
              <label className="customcheck">
                <input type="checkbox"
                value={ value._id }
                onChange={
                  (e)=>{
                    let tribe_id= e.target.value;
                        if( e.target.checked){
                          tribe.push(
                            tribe_id
                          )
                        }else{
                          console.log("position ", tribe.indexOf(tribe_id) ,  tribe_id );
                         tribe.splice(tribe.indexOf(tribe_id),1) 
                          
                        }
                        setTribe(tribe)
                        console.log("--tribe---",tribe)
                      
                      }
                    
                    }
                />
                <span className="checkmark" />
              </label>
            </li>
            :<></>
         ))}
          
           </ul>
          <div className="action">
          <span></span>
            <button className="btn btn-orange" type="submit" onClick={sendInviteTribe}>
              Invite to Event
            </button>
          </div>
          <div className="text-center">
          {event_type==="event"?
            <a href="/events" className="view-event">
              Skip &amp; View Your Event
            </a>
            :
            <a href="/trainings" className="view-event">
              Skip &amp; View Your Trainings
            </a>
          }
          </div>
        </div>

        }
     
      </div>
    </div>
  );
};

export default Step;

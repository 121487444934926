import React, { Component, Suspense, useEffect } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// // import { withRouter } from "react-router-dom";
// import isAuthenticated from "./router/isAuthenticated.js";
// import NotFoundPage from "./views/notFoundPage";
// import HomePage from "./views/homePage";
// import LoginPage from "./views/loginPage";
// import EventList from "./views/eventList";
// import Event from "./views/event";
// import ProfilePage from "./views/profilePage";
// import NotificationPage from "./views/notificationPage";
// import ViewEventPage from "./views/viewEventPage";
// import ViewFriendsPage from "./views/viewFriendsPage";
// import AddFriendsPage from "./views/addFriendsPage";
// import TermsPage from "./views/termsPage";
// import createEvent from "./views/createEvent";
// import RegisterPage from "./views/registerPage";
// import Test from "./views/test";
import AppRouter from "./router/appRouter";
// import firebase from "./firebase";
//import './App.scss';




const App = props => {

  // useEffect(()=>{
  //   const messaging=firebase.messaging();
  //     messaging.getToken()
  //     .then((currentToken)=>{
  //       console.log("currentToken", currentToken)
  //     })
  //   .catch((err)=>{
  //     console.log('An error occurred while retrieving token. ', err);
  //   })
  // })
  //let currentPath = props.location.pathname;
  //const name = localStorage.getItem("name") ? localStorage.getItem("name") : "";
  return (
    <>
      <AppRouter />
      <ToastContainer />

    </>
  );


};

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />

//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
//export default App;

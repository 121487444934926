import React, { useEffect, useState } from 'react'
import {useParams,useHistory} from "react-router-dom";
import { secureApiCall } from '../../services/conf/apiCall';
import Header from "../../components/header";
import Navbar from "../../components/navbar";
import { ToastContainer, toast } from "react-toastify";
import {getUserData} from "../../helpers/utils"
import defaultFeatureImage from "../../assets/img/feature_image.jpg";
// import defaultUserImage from "../../assets/img/default_user.png"
import defaultUserImage from "../../assets/img/default-user-3.jpg";
import moment from "moment";

const ProfileView = () => {
    const getTokenData =getUserData();
    const params=useParams();
    const history=useHistory();

    const friendRequestUrl = process.env.REACT_APP_API_ENDPOINT + "/api/friends-request";
    const getUserDataUrl=process.env.REACT_APP_API_ENDPOINT+`/api/get-user-data-slug/${params.user_slug}`
    const getAttendedEvents=process.env.REACT_APP_API_ENDPOINT+`/api/get-attended-events/${params.user_slug}`;
    const removeFriendRequestUrl = process.env.REACT_APP_API_ENDPOINT + "/api/remove-friend";
    const getMyFriendUrl=process.env.REACT_APP_API_ENDPOINT+`/api/my-friend/${params.user_slug}`;
    
    const [userData,setUserData]=useState({});
    const [removeFriend,setRemoveFriend]=useState([]);
    const [attendedEvents,setAttendedEvents]=useState([]);
    const [myFriend,setMyFriend]=useState([])
    const [friendRequestStatus,setFriendRequestStatus]=useState("Add Friend");
    useEffect(()=>{
        secureApiCall(getUserDataUrl,"get")
        .then((response)=>{
            console.log("---userData---",response.payload)
            if(!response.err){
                setUserData(response.payload);
            }
        }).catch((err)=>{
             //console.log(err);
        })

        secureApiCall(getAttendedEvents,"get")
        .then((response)=>{
             //console.log("---Attended events---",response)
            if(!response.err){
                setAttendedEvents(response.payload);
            }
        }).catch((err)=>{
             //console.log(err);
        })

        secureApiCall(getMyFriendUrl,"get")
        .then((response)=>{
             //console.log("---Is My friend---",response)
            if(!response.err){
                setMyFriend(response.payload);
            }
        }).catch((err)=>{
             //console.log(err);
        })
    },[]);
    
  const sendFriendRequest = (friend_id, position) => {
   let res=window.confirm("Do you want to send a friend request");
    if(res){
      let requestData = {
        friend_id: friend_id,
      };
      secureApiCall(friendRequestUrl, "post", JSON.stringify(requestData), {
        "Content-type": "application/json",
      })
      .then((response) => {
          if (!response.err) {
            toast.success( "Friend request raise");
            setFriendRequestStatus("Pending");
          }else{
            toast.error(response.msg);
          }
           //console.log("--response--", response);
        })
        .catch((err) => {
           toast.error("something went wrong ");
        });
      }else{
         //console.log("cancel add friend",res);
      }
    };
  
  
    const handelRemoveFriend = (friend_id,position) => {
         //console.log(friend_id, position);
        let res=window.confirm("Do you want to remove");
        if(res){
            let requestData = {
                friend_id: friend_id,
              };
          secureApiCall(removeFriendRequestUrl, "post", JSON.stringify(requestData), {
            "Content-type": "application/json",
          }).then( res=>{
             //console.log( res)
            if( !res.err ){
                setRemoveFriend(removeFriend);
                setFriendRequestStatus("Add Friend");
              toast.success("remove friend");
            }else{
              toast.error(res.msg);
            }
          })
          .catch( err =>{
            toast.error("something went wrong ");
    
          })
      }else{
           //console.log("cancel add friend",res);
        }
      };

    return (
        <div className="wrapper">
            <Header></Header>
            <Navbar></Navbar>
            <div className="content-wrapper">
                <section className="profilesetting pro_vie">
                    <div className="setting-form">
                        <div className="setting-form-inner">
                        <div className="row">
                            <div className="col-md-3">
                            {!userData.profile_pic?
                                <div className="pro_img">
                                    <img src={defaultUserImage} alt=""/>
                                </div>
                                :
                                <div className="pro_img">
                                    <img src={process.env.REACT_APP_IMG_URL_ENDPOINT+userData.profile_pic.profileImage} alt=""/>
                                </div>
                            }
                            </div>
                            <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="form-group">
                                    <label>
                                    <i className="fas fa-user profileicn" />{" "}
                                    <span className="light_span">{`${userData && userData.first_name} ${userData && userData.last_name}`}</span>
                                    </label>
                                </div>
                                </div>
                                <div className="col-md-12 ">
                                <div className="form-group">
                                    <label>
                                    <i className="fas fa-map-marker-alt profileicn" />{" "}
                                    <span className="light_span">
                                        {userData && userData.address ?
                                                `Address not available`
                                            :
                                                `${userData.address && userData.address.address1},
                                                 ${userData.address && userData.address.address2},
                                                 ${userData.address && userData.address.city},
                                                 ${userData.address && userData.address.provinces},
                                                 ${userData.address && userData.address.pincode}`
                                        }
                                    </span>
                                    </label>
                                </div>
                                </div>
                                <div className="col-md-12 ">
                                <div className="form-group">
                                    <label>
                                    <i className="fa fa-info-circle profileicn" />
                                    <span className="light_span">
                                        {userData && !userData.user_bio? "Bio not available": userData && userData.user_bio}
                                    </span>
                                    </label>
                                </div>
                                </div>
                                <div className="col-md-12">
                                {getTokenData._id === userData && userData._id
                                ?
                                    (<></>)
                                
                                // console.log("---my friend",myFriend)
                                :myFriend && myFriend.status=="accepted"
                                    ?(<div className="row">
                                        <div className="col-md-3 rem_fr_btn btnfrnd-pad">
                                            <button onClick={()=>{handelRemoveFriend(userData && userData._id)}} >Remove Friend</button>
                                        </div>
                                    </div>)
                                    :
                                    (<div className="row">
                                        <div className="col-md-3 add_fr_btn btnfrnd-pad">
                                            <button onClick={()=>{sendFriendRequest(userData && userData._id)}}>{friendRequestStatus}</button>
                                        </div>
                                    </div>)
                                }
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    
                    </div>
                </section>
              

                <section className="content-header content-hedr-pad-top">
                    <h1>
                    <span>Attending Events</span>
                    </h1>
                </section>
                <section className="content">
                <div className="row">
                {attendedEvents && attendedEvents.reverse().map((event,index)=>(   
                    <div key={index} className="col-lg-3 col-sm-6 col-xs-12">
                    <a href={`/event/${event.slug}`} className="event-box">
                    {event.banner.length===0?
                        <span
                            className="event-img"
                            style={{ backgroundImage: `url(${defaultFeatureImage})` }}
                        >
                            {/* <span className="participants">
                            <span>35</span>
                            Participants Confirmed
                            </span> */}
                        </span>
                    :
                        <span
                            className="event-img"
                            style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL_ENDPOINT+event.banner[0].featureImage})` }}
                        >
                            {/* <span className="participants">
                            <span>35</span>
                            Participants Confirmed
                            </span> */}
                        </span>
                    }
                    <span className="event-description">
                        <h5>{event.title}</h5>
                       <p>{event.owner_name} created on {moment(event.date).format('ll')}</p>
                    </span>
                    </a>
                    
                </div>
                ))}
                </div>
            </section>
            </div>
        </div>
    )
}

export default ProfileView

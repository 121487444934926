import React from "react";
import "./notFoundPage.scss";

const PageNotFound =()=>{
    return(
        <>
            <h1 className="page-heading">Page Not Found</h1>
            <section className="error-container">
            <span>4</span>
            <span><span className="screen-reader-text">0</span></span>
            <span>4</span>
            </section>
            <p className="zoom-area">Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place?
                <p>Let's go <a href="/">home</a> and try from there. </p>
            </p>
        </>
    )
}
export default PageNotFound;
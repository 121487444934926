import React,{useEffect,useState,useRef} from "react";
import Header from "../../components/header";
import Navbar from "../../components/navbar";
import {useHistory} from "react-router-dom"
import user from "../../assets/img/follower3.png";
import { secureApiCall } from "../../services/conf/apiCall";
import { ToastContainer, toast } from "react-toastify";
import "./viewFriendsPage.scss";

const ViewFriendsPage = (props) => {
  const history=useHistory();
  const search_box=useRef();
  const [viewFriends,setViewFriends]=useState([]);
  const [searchedUser,setSearchedUser]=useState("");
 
  const viewFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/my-friends";
  const removeFriendRequestUrl = process.env.REACT_APP_API_ENDPOINT + "/api/remove-friend";
  const searchFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/search-friends";
  
  useEffect(() => {
    secureApiCall(viewFriendUrl,"get")
    .then((response)=>{
      console.log(response);
      if(!response.err){
        setViewFriends(response.payload);
      }
    }).catch((err)=>{console.log(err)})

  }, [])

  const handelRemoveFriend = (friend_id,position) => {
    console.log(friend_id, position);
    let choice=window.confirm("Do you want to remove");
    if(choice){
      let requestData = {
        friend_id: friend_id,
      };
      secureApiCall(removeFriendRequestUrl, "post", JSON.stringify(requestData), {
        "Content-type": "application/json",
      })
      .then( res=>{
        console.log( res)
        if( !res.err ){
          let a=viewFriends.splice(position, 1);
          console.log("=====", a, viewFriends);
          setViewFriends([...viewFriends]);
          toast.success("remove friend");
        }else{
          toast.error(res.msg);
        }
      })
      .catch( err =>{
        toast.error("something went wrong ");

      })
  }else{
      console.log("cancel add friend");
    }
  };

  const handleUserSearch=()=>{
    console.log("--my data",search_box.current.value)
    secureApiCall(searchFriendUrl,"post",JSON.stringify({user_name:search_box.current.value}),{'Content-type':'application/json'})
      .then((response) => {
        console.log("--searched user--",response.payload)
         setSearchedUser(response.payload)
         setViewFriends(response.payload)
       })
      .catch((err) => {
          console.log(err);
      });
      // search_box.current.value="";

  }


  return (
    <div className="wrapper">
      <Header></Header>
      <Navbar></Navbar>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <h1>
          <span>View Friends</span>
		          <a className="text-black" href="/view-invites-friends">
              <span  className="send_re">View Invites </span>
            </a>
          </h1>
          <span className="ser_span">
            <input 
              type="text" 
              name="search" 
              ref={search_box} 
              placeholder="search here"
            />
            <button className="ser_in_btn">
              <i className="fas fa-search" onClick={()=>handleUserSearch()}></i>
            </button>
          </span>
          
        </section>
        <section className="viewfriend_sec">
          <ul className="friendlist clearfix">
            {viewFriends &&
              viewFriends.map((value, index) => (
                <li key={index}>
                  <div className="singlebox">
                    <div className="friendimg">
                      <a href="#">
                        <img src={user} alt="" />
                      </a>
                    </div>
                    <div className="main-content">
                      <div className="friend-name">
                        <a href="#">{value.friend_name}</a>
                      </div>
                      <div className="row btnbox">
                        <div className="col-md-6" style={{marginLeft:"-10px"}} >
                          <button
                            type="submit"
                            className="new-btn btn-dark new-btn-secondary"
                            onClick={()=>history.push(`/u/${value.friend_name_slug}`)}
                          >
                            View Profile
                          </button>
                        </div>
                        <div className="col-md-6"  style={{margin:"0px 5px"}}>
                          <button
                            type="submit"
                            className="new-btn new-btn-primary "
                            onClick={(e) => {
                              // setFriendId(val._id);
                              handelRemoveFriend(value.friend_id, index);
                            }}
                          >
                            Remove
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          {/* <div className="loadmore text-center">
            <button className="btn btn-orange">Load More</button>
          </div> */}
        </section>
      </div>
      ;
    </div>
  );
};

export default ViewFriendsPage;

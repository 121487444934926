import { useState } from "react";

function useValidation(props) {
  const [totalDistance, setTotalDistance] = useState(props.total_distance);
  const [time, setTime] = useState(props.time);
  const [averageSpeed, setAverageSpeed] = useState(props.average_speed);
  const [uploadMap, setUploadMap] = useState(props.file);
  const [visibility, setVisibility] = useState(props.visibility);
  const [foodStop, setFoodStop] = useState(props.food_stop);
  const [approvalRequired, setApprovalRequired] = useState(props.approval_required);
  const [externalLink, setExternalLink] = useState(props.external_link);

  const [totalDistanceError, setTotalDistanceError] = useState(null);
  // const [timeError, setTimeError] = useState(null);
  const [uploadMapError, setUploadMapError] = useState(null);
  // const [averageSpeedError, setAverageSpeedError] = useState(null);
  const [visibilityError, setVisibilityError] = useState(null);
  const [foodStopError, setFoodStopError] = useState(null);
  const [discipline, setdiscipline] = useState(props.discipline);

  const validateForm = () => {
    let error = true;
    if (!totalDistance || totalDistance === "") {
      setTotalDistanceError("Please enter total distance");
      error = false;
    }
    // if (!time || time === "") {
    //   setTimeError("Please enter time");
    //   error = false;
    // }
    // if (!averageSpeed || averageSpeed === "") {
    //   setAverageSpeedError("Please select average speed");
    //   error = false;
    // }
    if (!uploadMap || uploadMap === "") {
      //setUploadMapError("Please upload map");
      //error = false;
    }
    if (!visibility || visibility === "") {
      setVisibilityError("Please select visibility");
      error = false;
    }
    // if ((!foodStop || foodStop === "" )&& discipline?.toLowerCase() !== 'swimming') {
    //   const disciplineNew = discipline;
    //   console.log('discipline',discipline);
    //   setFoodStopError("Please select pit stop");
    //   error = false;
    // }
    return error;
  };

  return {
    totalDistance,
    time,
    averageSpeed,
    visibility,
    uploadMap,
    foodStop,
    approvalRequired,
    externalLink,
    uploadMapError,
    totalDistanceError,
    visibilityError,
    foodStopError,
    // averageSpeedError,
    // timeError,
    setApprovalRequired,
    setTotalDistance,
    setUploadMap,
    setTime,
    setAverageSpeed,
    setVisibility,
    setFoodStop,
    setExternalLink,
    validateForm,
  };
}
export default useValidation;

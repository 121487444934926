import React, { useState } from "react";
import useValidationHook from "./validation";
import {countries, regions} from "../../../constants/global";
import TimeKeeper from  "react-timekeeper"
import { restrictDatepicker } from "../../../helpers/utils";
import "./step.scss";
// import "react-datepicker/dist/react-datepicker.css";

const Step = (props) => {
  const [next, setNext] = useState(null);
  const [stepData, setStepData] = useState(props.stepData);
  console.log('stepData',props.stepData);
  const validationHook = useValidationHook(props.stepData);
  const [regionData,setRegionData]=useState(regions)
  const [countriesData,setCountriesData]=useState(countries);
  const [sTime,setSTime]=useState(props.stepData.start_time)
  const [showSTime, setShowSTime] = useState(false)
  const [eTime,setETime]=useState(props.stepData.end_time)
  const [showETime, setShowETime] = useState(false)
  let todayDate=new Date();
  const pushdata = () =>{
      return {
        location: validationHook.location,
        region: validationHook.region,
        country:validationHook.country,
        date: validationHook.date,
        start_time: validationHook.startTime,
        end_time: validationHook.endTime,
      };
  }
console.log("==",pushdata());
  const handleNext = (e) => {
    e.preventDefault();
    setNext(true);
    if (validationHook.validateForm()) {
      props.nextStep(pushdata());

    }
    setTimeout(() => {
      setNext(false);
    }, 1000);
  };
  

  const handelPrevClick = (e) => {
    props.prevStep();
  };
  return (
    <div className="steps-form create-steps">
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <label>Location</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter Location"
                defaultValue={stepData.location}
                onChange={(e) => {
                  validationHook.setLocation(e.target.value);
                }}
              >
              </input>
              {validationHook.locationError && (
                <span className="text-danger">
                  {validationHook.locationError}
                </span>
              )}
          </div>
          <div className="form-group">
            <label>Country</label>
            <div className="select-wrap">
              <select
                className="form-control"
                value={validationHook.country}
                onChange={(e) => {
                  validationHook.setCountry(e.target.value);
                }}
              >
                <option>Select Country</option>
                {countriesData.map((value,index)=>(
                  <option key={index}>{value.name}</option>
                ))}
                
              </select>
              {validationHook.countryError && (
                <span className="text-danger">
                  {validationHook.countryError}
                </span>
              )}
            </div>
          </div>

          {
            validationHook.country ==='United Kingdom'?
            <div className="form-group">
            <label>Region</label>
            <div className="select-wrap">
              <select
                className="form-control"
                defaultValue={stepData.region}
                onChange={(e) => {
                  validationHook.setRegion(e.target.value);
                }}
              >
                <option>Select Region</option>
                {regionData.map((value,index)=>(
                  <option key={index}>{value.name}</option>
                ))}
                
              </select>
              {/* {validationHook.regionError && (
                <span className="text-danger">
                  {validationHook.regionError}
                </span>
              )} */}
            </div>
          </div>
          :
            <></>
          }
              <div className="form-group">
                <label>Date</label>
                {/* <DatePicker className="form-control"></DatePicker> */}
                <input
                  className="form-control"
                  // placeholder="Date"
                  value={validationHook.date}
                  type="date"
                  min={restrictDatepicker(todayDate)}
                  onChange={(e) => {
                    validationHook.setDate(e.target.value);
                  }}
                />
                {validationHook.dateError && (
                  <span className="text-danger">
                    {validationHook.dateError}
                  </span>
                )}
              </div>
           <div className="row">
            
            <div className="col-sm-6 form-group">
              <div className="form-group">
                <label>Start Time</label>
              {!showSTime &&
                <span 
                  className="form-control"
                  defaultValue={stepData.start_time}
                  onChange={(e) => validationHook.setStartTime(e.target.value)}
                  onClick={() => setShowSTime(true)}
                  >{sTime}
                  </span>
                }
              <div>
                {showSTime &&
                  <TimeKeeper
                      time={sTime}
                      className="timer"
                      onChange={(newTime) =>{setSTime(newTime.formatted12)}}
                      onDoneClick={() => { validationHook.setStartTime(sTime);setShowSTime(false)}}
                      switchToMinuteOnHourSelect
                  />
                }

              </div>
                {validationHook.startTimeError && (
                  <span className="text-danger">
                    {validationHook.startTimeError}
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <div className="form-group">
                <label>End Time</label>
              {/* {console.log("----",sTime,eTime)} */}
              {!showETime &&
                <span 
                  className="form-control"
                  defaultValue={stepData.start_time}
                  onChange={(e) => validationHook.setEndTime(e.target.value) }
                  onClick={() => setShowETime(true)}
                  >{eTime}
                  </span>
                }
              <div>
                {showETime &&
                  <TimeKeeper
                      time={eTime}
                      onChange={(newTime) =>setETime(newTime.formatted12)}
                      onDoneClick={() =>{validationHook.setEndTime(eTime); setShowETime(false)}}
                      switchToMinuteOnHourSelect
                  />
                }
              </div>
                {validationHook.endTimeError && (
                  <span className="text-danger">
                    {validationHook.endTimeError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="action">
            <a
              href="#"
              className="icon-btn"
              onClick={(e) => {
                handelPrevClick();
              }}
            >
              <i className="fas fa-arrow-left" />
            </a>
            {next ? (
              <button className="btn btn-orange">Loading...</button>
            ) : (
              <button
                className="btn btn-orange"
                onClick={handleNext}
                type="submit"
              >
                Next step
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step;

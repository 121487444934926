import React, { useEffect, useState } from "react";
import useValidationHook from "./validation";
import { secureApiCall, ApiCall } from "../../../services/conf/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { useParams,useHistory } from "react-router-dom";
import Spinner from "../../spinner";
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import "./step.scss";

const Step = (props) => {
  const animatedComponents = makeAnimated();
  const history=useHistory();
  const params=useParams();
  const [stepsData, setStepsData] = useState(props.stepData);
  const [next, setNext] = useState(false);
  const [populatedPitStops,setPopulatedPitStops]=useState(0);
  const validationHook = useValidationHook({...props.stepData,discipline:props.stepData1.discipline});
  const [value,setValue]=useState([]);
  const [inputValue,setInputValue]=useState("")

  console.log('pravin step',props.stepData);
  console.log('pravin stepsData1',props.stepData1);

  let eventId=props.stepData.event_id;
  let url = process.env.REACT_APP_API_ENDPOINT + "/api/create-event";
  let updateEventUrl=process.env.REACT_APP_API_ENDPOINT+`/api/update-event/${eventId}`;

  const createObj=(arr)=>{
    let valueObj=[];
    for(let i of arr){
      valueObj.push({'label':i,'value':i});
    }    
    return valueObj;
  }
  useEffect(()=>{
    if(props.isEdit){
      if(stepsData.food_stop){
        let pitStop=stepsData.food_stop.split(",");
        let res=createObj(pitStop);
        setValue(res);
      }
    }
  },[])
  const pushData =()=>{

    let pitStops=value.map((val)=>val.value);
    return {
      total_distance: validationHook.totalDistance,
      time: validationHook.time,
      average_speed: validationHook.averageSpeed,
      upload_map: validationHook.uploadMap,
      food_stop: pitStops,
      visibility: validationHook.visibility,
      approval_required:validationHook.approvalRequired,
      external_link:validationHook.externalLink,
    };
  }
  // console.log("Step One & Two Data on StepThree=======>", props.stepData1, props.stepData2);
  const handleNext = (e) => {
    e.preventDefault();
    if (validationHook.validateForm()) {
        setNext(true);
        publishEvent()
    }
  };


  const handelPrevClick = (e) => {
    props.prevStep();
  };


  const publishEvent = async () => {
    let eventData = {
      ...props.stepData1,
      ...props.stepData2,
      ...pushData(),
    };


    const formData = new FormData();
    Object.keys(eventData).map((key, index) => {
      if (key === 'file'){
        let files = eventData[key];
        if( files && files.length >0){
          for (let i = 0; i < files.length; i++) {
            formData.append(`file`, files[i]);
          }
        }
        //formData.append(`file`, files);
      }else
      if (key === "upload_map"){
        let files = eventData[key];
        if (files && files.length > 0) {
          formData.append(key, files[0]);
        }
      }
      else{
         formData.append(key, eventData[key]);
      }
      //console.log(eventData[key]);
    });


    try {
      let response;
      let switchId="publish";
      if(props.isEdit){
        switchId = "update";
      }
      switch (switchId) {
        case "publish":
          response = await secureApiCall(url, "post", formData);
          setNext(false);
          break;
        case "update":
          response = await secureApiCall(updateEventUrl, "post", formData);
          setNext(false);  
          history.push("/my-events");
          break;
        default:
          setNext(false);
          console.log("error");
      }
       console.log(response);
      if (!response.err) {
        props.nextStep(response.payload);
        toast.success(response.msg);
        setNext(false);
      } else {

      }
    } catch (err) {
      console.log(err);
      toast.error(err.msg);
    }
  };

  const  isNumber =(n)  =>{
  return !isNaN(parseInt(n))
}

  const calculateSpeed = ( distance , time ) =>{
    if(isNumber(distance ) && isNumber(distance ) ){
      // console.log("distance and time ---===",distance, time);
      let speed = distance/time;
      if( isNumber(speed)){
        return speed.toFixed(2);
      }else{
        return 0;
      }
      //validationHook.setAverageSpeed(speed);
    }else{
      return 0;
    }
  }

  let uploadMapName=stepsData.upload_map;
    if(uploadMapName){
      var imageName=(stepsData.upload_map).split("/").splice(3,1);
    }else{
      uploadMapName="";
    }

  const [countUploadMapFile,setCountUploadMapFile]=useState(0);

  // for populate pit stops

  let data=props.stepData && props.stepData.food_stop.split(",")
  let newObj=[];
  for(let i in data){
    newObj[i]={value:data[i],label:data[i]}
  }


  const createOption = (label) => ({
    label,
    value: label,
  });
  
  const components = {
    DropdownIndicator: null,
  };
  
  const handleChange=(value)=>{
    setValue(value);
  }
  
  const handleInputChange = (inputValue) => {
    console.log("handleInputChange -> inputValue", inputValue)
    setInputValue(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        console.group('Value Added');
        console.log(value);
        console.groupEnd();
        setInputValue('');
        validationHook.setFoodStop([...value, createOption(inputValue)]);
        // value.push(createOption(inputValue))
        setValue([...value, createOption(inputValue)])
        event.preventDefault();
    }
  }
  return (
    <div className="steps-form create-steps">
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <label>Total Distance (KM)</label>
            <input
              className="form-control"
              placeholder="Distance"
              // placeholder="Distance (in miles)"
              // maxLength="3"
              defaultValue={stepsData.total_distance}
              type="number"
              onBlur={ (e) =>{
                let speed = calculateSpeed(
                  e.target.value,
                  validationHook.averageSpeed
                );
                setStepsData((prevState) => {
                  return { ...prevState, average_speed: speed };
                });
                console.log("speeddddddddddddddddddddddd",stepsData, speed);
                 validationHook.setAverageSpeed(speed);
              } }
              onChange={(e) => {
                validationHook.setTotalDistance(e.target.value);

              }}
            />
            {validationHook.totalDistanceError && (
              <span className="text-danger">
                {validationHook.totalDistanceError}
              </span>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6 form-group"> 
              <label>Time (HOURS)</label>
              <input
                className="form-control"
                placeholder="Time"
                // placeholder="Time (in minutes)"
                defaultValue={stepsData.time}
                type="number"
                // maxLength="4"
                onChange={(e) => {
                  validationHook.setTime(e.target.value);
                  let speed = calculateSpeed(validationHook.totalDistance,e.target.value);
                  console.log("Step -> speed", speed)
                  
                   validationHook.setAverageSpeed(speed);
                   setStepsData({average_speed: speed});
                }}
              />
              {/* {validationHook.timeError && (
                <span className="text-danger">{validationHook.timeError}</span>
              )} */}
            </div>
            <div className="col-sm-6 form-group">
              <label>Average Speed (KM/H)</label>
              <input
                className="form-control"
                placeholder="Average Speed"
                readOnly
                // defaultValue={stepsData.average_speed}
                value={validationHook.averageSpeed}
                type="number"
                onChange={(e) => {
                  validationHook.setAverageSpeed(e.target.value);
                }}
              />
              {/* {validationHook.averageSpeedError && (
                <span className="text-danger">
                  {validationHook.averageSpeedError}
                </span>
              )} */}
            </div>
          </div>
          <div className="form-group">
            <label>Upload Map</label>
            {/* <input type="file" id="exampleInputFile"> */}
            <label
              className="form-control custom-file"
              htmlFor="my-file-selector"
            >
                <input
                  id="my-file-selector"
                  type="file"
                  accept=".gpx,.tcx,.fit,.csv,.kml"
                  style={{ display: "none" }}
                  defaultValue={"" && stepsData.upload_map}
                  onChange={(e) => {
                    let count = e.target.files.length;
                    let check = 1;
                    let i = 0;
                    while(i < count){
                      let name = e.target.files[0].name;
                      let ext = name.split('.').pop();
                      if(
                        ext.toLowerCase() != 'gpx' 
                        && ext.toLowerCase() != 'tcx' 
                        && ext.toLowerCase() != 'fit'
                        && ext.toLowerCase() != 'csv'
                        && ext.toLowerCase() != 'kml'
                      ){
                        check = 0;
                        break;
                      }
                      i++;
                    }

                    if(!check){
                      document.getElementById("my-file-selector").value=null;
                      alert('Only support .gpx,.tcx,.fit,.csv and .kml ext file.')
                      return false;
                    }

                    setCountUploadMapFile(count);
                    validationHook.setUploadMap(e.target.files);
                  }}
                />
              <span className id="upload-file-info">
                {imageName?imageName[0]:`${countUploadMapFile} file selected`}
              </span>
              <i className="fas fa-upload" />
            </label>
            <span className="text-note">
              Note:- Only support .gpx,.tcx,.fit,.csv and .kml ext file.
            </span>
            {validationHook.uploadMapError && (
              <span className="text-danger">
                {validationHook.uploadMapError}
              </span>
            )}
          </div>
          <div className="row">
          {props.stepData1.discipline=='Swimming'?
          <></>
          :
            <div className="col-sm-6 form-group">
              <label>Pit Stops</label>
              <div className="select">
                <CreatableSelect
                  // components={components}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Type pitstop and press enter"
                  value={value}
                />
                {validationHook.foodStopError && (
                  <span className="text-danger">
                    {validationHook.foodStopError}
                  </span>
                )}
              </div>
            </div>
          }
            
            <div className="col-sm-6 form-group">
              <label>Visibility</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  defaultValue={stepsData.visibility}
                  onChange={(e) => {
                    validationHook.setVisibility(e.target.value);
                  }}
                >
                  <option>Select Visibility</option>
                  <option>Public</option>
                  <option>Private</option>

                </select>
                {validationHook.visibilityError && (
                  <span className="text-danger">
                    {validationHook.visibilityError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>External Link</label>
            <input
              className="form-control"
              placeholder="External registration link (optional)"
              // placeholder="Distance (in miles)"
              // maxLength="3"
              defaultValue={stepsData.external_link}
              type="text"
              onChange={(e) => {
                validationHook.setExternalLink(e.target.value);
              }}
            />
          </div>
          <div className="row search-results">
            <div className=" action">
            <span>
                <label className="switch">
                  <input 
                    type="checkbox" 
                    defaultChecked={props.isEdit?stepsData.approval_required?false:true:props.stepData1.event_type=='event'?true:false} 
                    onChange={(e)=>{
                      validationHook.setApprovalRequired(e.target.checked?false:true)}}
                    />
                  <span className="slider round" />
                </label>
                <div className="ml-10">Approval Needed</div>
              </span>
            </div>
          </div>
          <div className="action">
            <a
              href="#"
              className="icon-btn"
              onClick={handelPrevClick}
            >
              <i className="fas fa-arrow-left" />
            </a>
            {next ? (

              <button className="btn btn-orange"><Spinner loading={next} color="#fff"></Spinner></button>
            ) : (
              <button
                className="btn btn-orange"
                onClick={handleNext}
                type="submit"
              >
                {props.isEdit ? "Update" : "Publish" }
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <div className="row">
          <div className="col-md-12 text-center">
              <div className="form-group">
                  <label>Would you like to invite all your friends?</label>
                  <div className="clearfix"></div>
                  <label className="radio-inline">
                    <input type="radio" name="optradio" checked>Yes
                  </label>
                  <label className="radio-inline">
                    <input type="radio" name="optradio">No
                  </label>
                  <br><br>
              </div>
          </div>
      </div>
      <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center">
              <div className="form-group">
                  <label>Search people to invite to your friends</label>
                  <div className="search-container">
                      <input className="form-control" placeholder="Search people here" type="text">
                      <button className="search-btn" type="button"><i className="ion-search"></i></button>
                  </div>
              </div>
          </div>
      </div>
      <div className="row">
          <div className="col-md-12 text-center">
              <button className="btn btn-orange btn-border" type="submit">Go back</button> &nbsp;&nbsp;
              <button className="btn btn-orange" type="submit">Publish event</button>
          </div>
      </div> */}
    </div>
  );
};

export default Step;

import React, { useEffect, useState } from 'react'
import Header from "../../components/header";
import Navbar from "../../components/navbar";
import { secureApiCall } from "../../services/conf/apiCall";
import  eventImage from "../../assets/img/sample-photos/event5.png"
import "../viewFriendsPage/viewFriendsPage.scss";
import defaultFeatureImage from "../../assets/img/feature_image.jpg";
import moment from "moment";

const MyTribes = (props) => {

    const getMyTribesUrl=process.env.REACT_APP_API_ENDPOINT+"/api/my-tribes";
    const [myEventsData,setMyEventsData]=useState([]);
    const [eventNameFilter,setEventNameFilter]=useState("");
    const [eventDateFilter,setEventDateFilter]=useState("");  

    useEffect(()=>{
        secureApiCall(getMyTribesUrl,'get')
        .then((response)=>{
            console.log(response)
            if(!response.err){
                setMyEventsData(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        })
    },[])

    const handleEventFilters=()=>{
        secureApiCall(`${getMyTribesUrl}?event_name=${eventNameFilter}&event_date=${eventDateFilter}`,'get')
        .then((response)=>{
            console.log("--filtered data",response)
            if(!response.err){
                setMyEventsData(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        })
    }
    return (
        <div>
        <Header></Header>
        <Navbar></Navbar>
         <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
            <h1>
            <span>My Tribes</span>
            </h1>
        </section>
        <section className="filters">
            <div className="container m-0">
            <div className="form-horizontal">
            <div className="form-group">
                <label htmlFor className="col-lg-1 col-md-3 control-label">
                    Filter By
                </label>
                <div className="col-lg-3 col-md-3">
                    <input
                    type="text"
                    className="form-control input-dashed"
                    placeholder="Tribe name"
                    onChange={(e)=>setEventNameFilter(e.target.value)}
                    />
                </div>
                {/* <div className="col-lg-3 col-md-3">
                    <div className="input-group date" id="datepicker">
                    <input
                        type="date" 
                        className="form-control" 
                        placeholder="Select date"
                        onChange={(e)=>setEventDateFilter(e.target.value)}
                        />
                    <div className="input-group-addon">
                    </div>
                    </div>
                </div> */}
                <div className="col-lg-3 col-md-3" onClick={handleEventFilters}>
                    <label className="col-lg-1 col-md-3 control-label">&nbsp;</label>
                    <button className="new-btn new-btn-secondary" style={{borderRadius:"20px"}}>
                        <span className="fas fa-search" /> Search
                    </button>
                </div> 
                </div>

            </div>
            </div>
        </section>
        <section className="content">
            <div className="row">
           {myEventsData
           ?
            myEventsData.map((value,index)=>(

                    <div key={index} className="col-lg-4 col-sm-6 col-xs-12" >
                    <a href={`/my-tribe-details/${value.slug}`} 
                        className="event-box" 
                        data-toggle="modal"
                        data-target="#myModal"
                    >
                        {/* {console.log("---------",value.slug)} */}
                    {value.banner.length===0?
                        <div
                            className="event-img"
                            style={{ backgroundImage: `url(${defaultFeatureImage})` }}
                        >
                            <span className="participants">
                            <span>{value.total_joined} </span>
                            Members Confirmed
                            </span>
                        </div>
                        :
                        <div
                            className="event-img"
                            style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL_ENDPOINT+value.banner[0].featureImage})` }}
                        >
                            <span className="participants">
                            <span>{value.total_joined} </span>
                            Members Confirmed
                            </span>
                        </div>
                    }
                        
                    <div className="event-description" style={{minWidth:"300px"}}>
                        <h5>{value.title}</h5>
                        <p>You created on {moment(value.createdAt).format('ll')} </p>
                    </div>
                    </a>
                </div>
                ))
            :
            <div>No Data Available</div>
            }
            </div>
        </section>
        </div>
        </div>
    )
}

export default MyTribes;

import React, { useEffect, useState } from "react";
 import { ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";

import Header from "../../components/header";
import Navbar from "../../components/navbar";

import user from "../../assets/img/follower3.png";
import { useHistory } from "react-router-dom";
import { secureApiCall } from "../../services/conf/apiCall";
import {getUserData, removeElement} from "../../helpers/utils"
import Style from  "./addFriendsPage.scss";
// import defaultUser from "../../assets/img/default_user.png";
import defaultUser from "../../assets/img/default-user-3.jpg";
const AddFriendsPage = (props) => {

  const getTokenData=getUserData();

  const [usersData,setUsersData]=useState([]);
  const [originalUserData,setOriginalUserData]=useState([]);

  let friendRequestUrl = process.env.REACT_APP_API_ENDPOINT + "/api/friends-request";
  let removeFriendRequestUrl = process.env.REACT_APP_API_ENDPOINT + "/api/ignore-friends";
  let fetchUsers = process.env.REACT_APP_API_ENDPOINT + "/api/recommended-friend";

  useEffect(()=>{
    secureApiCall(fetchUsers, "get")
      .then((response) => {
        console.log(response);
        if( !response.err){
            //setUsersData(removeElement(response.payload,'_id',getTokenData._id));
            setUsersData(response.payload);
            setOriginalUserData(response.payload)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[])

  const sendFriendRequest = (friend_id, position) => {
  let res=window.confirm("Do you want to send a friend request");
  if(res){
    let requestData = {
      friend_id: friend_id,
    };
    secureApiCall(friendRequestUrl, "post", JSON.stringify(requestData), {
      "Content-type": "application/json",
    })
      .then((response) => {
        if (!response.err) {
           usersData.splice(position, 1);
           setUsersData([...usersData]);
           toast.success( "Friend request raise");
        }else{
          toast.error(response.msg);
        }
        console.log("--response--", response);
      })
      .catch((err) => {
         toast.error("something went wrong ");
        console.log(err);
      });
    }else{
      console.log("cancel add friend",res);
    }
  };


  const handelRemoveFriend = (friend_id,position) => {
    console.log(friend_id, position);
    let res=window.confirm("Do you want to ignore");
    if(res){
      let requestData = {
        friend_id: friend_id,
      };
      secureApiCall(removeFriendRequestUrl, "post", JSON.stringify(requestData), {
        "Content-type": "application/json",
      })
      .then( res=>{
        console.log( res)
        if( !res.err ){
          let a=usersData.splice(position, 1);
          console.log("=====", a, usersData);
          setUsersData([...usersData]);
          toast.success("remove friend");
        }else{
          toast.error(res.msg);
        }
      })
      .catch( err =>{
        toast.error("something went wrong ");

      })
  }else{
      console.log("cancel add friend",res);
    }
  };

  const onSearch =(searchText)=>{
    //onSearch
    if(searchText.length > 0){
      const newusersData = [...usersData].filter(a=>
        a.display_name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())    );
       setUsersData(newusersData);
    }else{
      setUsersData(originalUserData);
    }
  
  }

  // const handleRequest = (e) => {
  //   e.preventDefault();
  //   setRequest(true);
  //   sendRequest();
  // };

  return (
    <div className="wrapper">
      <Header></Header>
      <Navbar></Navbar>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <h1>
            <span>Add Friends</span>
          </h1>
          <div style={{position:'absolute',right:0}}>
            <input type="text" id="search" className="form-control" onChange={(e)=>onSearch(e.target.value)} style={{width:'300px',height:'40px'}} placeholder="Search friend" name="search" />
          </div>
        </section>
        <section className="viewfriend_sec">
          <ul className="friendlist clearfix">
            {console.log("sdsdsdsdsdsdsd", usersData)}
            {usersData &&
              usersData.map((val, index) => (
                <li key={index}>
                  {/* <form > */}
                  <div className="singlebox">
                    <div className="friendimg">
                      <a href="#"><img src={defaultUser} alt="" /></a>
                    </div>
                    <div className="main-content">
                      <div className="friend-name">
                        <a href="#">
                          {val.first_name}&nbsp;{val.last_name}
                        </a>
                      </div>
                      <div className="row btnbox">
                        <div className="col-md-12">
                          <button
                            type="button"
                            onClick={(e) => {
                              sendFriendRequest(val._id, index);
                              //setFriendName(`${val.first_name} ${val.last_name}`);
                              //setStatus("pending");
                            }}
                            className="btn-dark new-btn new-btn-secondary"
                          >
                            Add Friend
                          </button>  
                        </div>
                        <div style={{marginTop:15}} className="col-md-12">
                          <button
                            type="button"
                            onClick={(e) => {
                              // setFriendId(val._id);
                              handelRemoveFriend(val._id, index);
                            }}
                            className="new-btn new-btn-primary"
                          >
                            Ignore
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          {/* <div className="loadmore text-center">
            <button className="btn btn-orange">Load More</button>
          </div> */}
        </section>
      </div>
    </div>
  );
};

export default AddFriendsPage;

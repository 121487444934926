import { useState } from "react";

function useValidation(props) {
  // console.log("----------", props.dataStepOne);
  const [eventName, setEventName] = useState(props.title);
  const [description, setDescription] = useState(props.description);
  const [discipline, setDiscipline] = useState(props.discipline);
  const [eventImage, setEventImage] = useState(props.banner);
  const [distance, setDistance] = useState(props.distance);
  const [eventType, setEventType] = useState(props.event_type);

  const [eventNameError, setEventNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [disciplineError, setDisciplineError] = useState(null);
  const [eventImageError, setEventImageError] = useState(null);
  const [distanceError, setDistanceError] = useState(null);
  const [eventTypeError, setEventTypeError] = useState(null);

  const validEvent = (eventName) => {
    if (eventName.length > 4) {
      if (eventName) {
        setEventNameError(null);
        return true;
      } else {
        setEventNameError("Please enter event name");
        return false;
      }
    } else {
      if (eventName === "") {
        setEventNameError("Please enter event name");
      } else {
        setEventNameError("Event name is too short");
      }
    }
  };
  //   const valid
  const validateForm = (e) => {
    let error = true;
    if (!validEvent(eventName)) {
      error = false;
    }
    if (!description || description === "") {
      setDescriptionError("Please enter description");
      error = false;
    }
    if (!discipline || discipline === "") {
      setDisciplineError("Please select discipline");
      error = false;
    }
    if (!eventImage || eventImage === "") {
      setEventImageError("Please upload an image");
      error = false;
    }
    if (!distance || distance === "") {
      setDistanceError("Please select distance");
      error = false;
    }
    if (!eventType || eventType === "") {
      setEventTypeError("Please select event type");
      error = false;
    }

    //setEventName(props.dataStepOne.title);
    return error;
  };

  return {
    eventName,
    description,
    discipline,
    eventImage,
    distance,
    eventType,
    setEventName,
    setDescription,
    setDiscipline,
    setEventImage,
    setDistance,
    setEventType,
    eventNameError,
    descriptionError,
    disciplineError,
    eventImageError,
    distanceError,
    eventTypeError,
    validateForm,
  };
}

export default useValidation;

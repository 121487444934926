import React, { useState,useEffect } from "react";
import {useHistory} from "react-router-dom";
import Header from "../../components/header";
import Navbar from "../../components/navbar";
import user from "../../assets/img/follower3.png";
import { secureApiCall } from "../../services/conf/apiCall";
import {getTime} from "../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const NotificationPage = (props) => {

  const [notificationData,setNotificationData]=useState([]);
  const [eventInvite,setEventInvite]=useState([]);
  const history=useHistory();
  const url = process.env.REACT_APP_API_ENDPOINT+"/api/notification";
  //const addFriendUrl  = process.env.REACT_APP_API_ENDPOINT+"/api/confirm-friend-request";
  //const rejectFriendUrl = process.env.REACT_APP_API_ENDPOINT+"/api/reject-friend-request";
  //const joinEventUrl = process.env.REACT_APP_API_ENDPOINT+"/api/accept-join-event";
  //const rejectEventUrl = process.env.REACT_APP_API_ENDPOINT+"/api/reject-join-event";


  useEffect(()=>{
      secureApiCall(url,"get").then((response)=>{
        console.log(response)

        if(!response.err){
          setNotificationData(response.payload);

          //setNotificationData(data1);
          //setEventInvite(data2);
        }
      }).catch((err)=>{
        console.log(err);
      })
  },[])


  const getApiUrl=( action, notification_type  ) => {
    let url = process.env.REACT_APP_API_ENDPOINT;
    switch (notification_type) {
      case "friend_request":
        if (action === "accept") {
          url = url +  "/api/confirm-friend-request";
        } else {
          url = url + "/api/reject-friend-request";
        }
        break;
      case "joining_event_request":
        if (action === "accept") {
          url = url + "/api/confirm-joining-request";
        } else {
          url = url + "/api/reject-joining-request";
        }
        break;
      case "invite_for_event":

        if( action === 'accept'){
          url = url + "/api/confirm-event-invite"
        }else{
          url = url + "/api/reject-event-invite"
        }
        break;
      case "invite_for_tribe":
        if (action === "accept") {
          url = url + "/api/confirm-tribe-joining-request";
        } else {
          url = url + "/api/reject-tribe-joining-request";
        }
        break;
      case "joining_tribe_request":
        if (action === "accept") {
          url = url + "/api/accept-tribe-joining-request";
        } else {
          url = url + "/api/deny-tribe-joining-request";
        }
        break;
      default:
        console.log("default case");
    }
    return url;
  }

  const handleRequest=(notification_id,notification_type, position , action)=>{
    let url = getApiUrl(action, notification_type);
    let formData = JSON.stringify({ notification_id: notification_id });
    console.log("handleRequest -> url", url)
    let header = {
      "Content-type": "application/json",
    };
    
      secureApiCall(url, "post", formData,header )
        .then((response) => {
          console.log(response);
          if (!response.err) {
            notificationData.splice(position, 1);
            setNotificationData([...notificationData]);
            toast.success("Success");
            if(notificationData.length == 0){
              history.push("/joined-events");
            }
          } else {
            toast.error(response.msg);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong ");
          console.log(err);
        });
  }


  // const handleDelete=(notification_id, position)=>{

  //   secureApiCall(rejectFriendUrl,"post",
  //   JSON.stringify({'notification_id':notification_id,}),
  //   {'Content-type':'application/json'})
  // .then((response)=>{
  //   console.log(response)
  //   if(! response.err){
  //     notificationData.splice(position, 1);
  //     setNotificationData([...notificationData]);
  //     toast.success("Success");
  //   }else{
  //     toast.error(response.msg);
  //   }

  // }).catch((err)=>{
  //   toast.error("Something went wrong ");
  //   console.log(err)
  // })
  // }


  // const handleJoin=(notification_id,fromUserId, position)=>{
  //       eventInvite.splice(position,1);
  //       secureApiCall(joinEventUrl,"post",
  //       JSON.stringify({'notification_id':notification_id,'fromUserId':fromUserId}),
  //       {'Content-type':'application/json'})
  //     .then((response)=>{
  //       console.log(response)
  //       setEventInvite(eventInvite)
  //     }).catch((err)=>{
  //       console.log(err)
  //     })
  // }


  // const handleReject=(notification_id,fromUserId, position)=>{
  //     eventInvite.splice(position,1);
  //     secureApiCall(rejectFriendUrl,"post",
  //     JSON.stringify({'notification_id':notification_id,'fromUserId':fromUserId}),
  //     {'Content-type':'application/json'})
  //   .then((response)=>{
  //     console.log(response)
  //     setEventInvite(eventInvite)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  //   }


  return (
    <div className="wrapper">
      <Header></Header>
      <Navbar></Navbar>
      <div className="content-wrapper">
        {/* <!-- Content Header (Page header) --> */}
        <section className="content-header">
          <h1>
            <span>Your Notifications</span>
          </h1>
        </section>
        <section className="notifyarea">
          <ul className="notificationlist clearfix">
            {notificationData&&notificationData.map((value, index) => (
              <li key={index}>
                <a href="#">
                  <div className="notifcationmg">
                    <img src={user} alt />
                  </div>
                  <div className="notifcationcontent">
                    <span className="notificationtext">
                    <span>{value.send_user } {" "}</span>
                      { value.message}
                    </span>
                    <span className="notificationtime">{moment(value.createdAt).startOf('minute').fromNow()}</span>

                    <div className="btnbox">
                      <button
                        className="btn btn-success mr-4"
                        onClick={() => {
                          handleRequest(value._id, value.type, index, "accept");
                        }}
                      >
                        Confirm
                      </button>{" "}
                      <button
                        defaultValue="delete"
                        className="btn btn-danger mr-4"
                        onClick={() => {
                          handleRequest(value._id, value.type, index, "reject");
                        }}
                      >
                        Delete
                      </button>
                      {" "}
                      {
                      value.type==='friend_request'?
                        <></>
                      :
                        <button
                          defaultValue="View Details"
                          className="btn btn-dark "
                          onClick={() => {
                            let event = value.payload;
                            history.push(
                              event.event_type==="training" ?
                                `/event/${event.event_slug}`
                              : 
                                event.event_type === "tribe" ?
                                  `/tribes/${event.event_slug}`
                              :
                                `/event/${event.event_slug}`
                              )
                          }}
                        >
                          View Details
                        </button>
                      }
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
          {/* <div className="load-morebtn text-center">
            <button className="btn btn-orange" type="submit">
              Load More
            </button>
          </div> */}
        </section>
      </div>
    </div>
  );
};

export default NotificationPage;

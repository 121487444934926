import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PageHeading from "../../components/pageHeading";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import isAuthenticated from "../../router/isAuthenticated";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";
import { secureApiCall, ApiCall } from "../../services/conf/apiCall";
import {getUserData} from "../../helpers/utils"
// import userImg from "../../assets/img/follower3.png"
import userImg from "../../assets/img/default-user-3.jpg";
import DialogBox from "../../components/dialog/dialogBox";
import { Markup } from 'interweave';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import defaultCoverImage from "../../assets/img/cover_image.jpg";
import defaultThumbnailImage from "../../assets/img/thumbnail_image.jpg";
import defaultUser from "../../assets/img/default-user-3.jpg";
let nl2br=require('nl2br');

const Tribes = (props) => {

  let history = useHistory();
  let params = useParams();
  let userId=getUserData()._id;
  let isToken=localStorage.getItem("authToken");
  // console.log(getUserData())

  const [eventData, setEventData] = useState([]);
  const [joinedUsersData,setJoinedUsersData]=useState([]);
  const [interestedUsers,setInterestedUsers]=useState([]);
  const [viewJoinedUsers,setViewJoinedUsers]=useState(true);
  const [viewInvitedUsers,setViewInvitedUsers]=useState(false);
  const [carousalImages,setCarousalImages]=useState([]);
  const [joinBtnStatus,setJoinBtnStatus]= useState("");
  const [leaveBtnStatus,setLeaveBtnStatus]= useState("");
  const [isDisabled,setIsDisabled] = useState(false);


  // let getEventsUrl = process.env.REACT_APP_API_ENDPOINT + `/list-events/${params.id}`;
  let getEventsUrl = process.env.REACT_APP_API_ENDPOINT + `/list-events/${params.slug}`;
  let getEventsSecureUrl = process.env.REACT_APP_API_ENDPOINT + `/api/list-events/${params.slug}`;
  let joiningRequest =process.env.REACT_APP_API_ENDPOINT + `/api/request-join-tribe`;
  let InterestRequest =process.env.REACT_APP_API_ENDPOINT + `/api/interest-send-tribe`;
  // let getJoinedUsersUrl=process.env.REACT_APP_API_ENDPOINT+`/api/event-joined-users/${params.id}`;
  // let getInterestedUsersUrl=process.env.REACT_APP_API_ENDPOINT+`/api/event-interested-users/${params.id}`;
  let getJoinedUsersUrl=process.env.REACT_APP_API_ENDPOINT+`/event-joined-users/${params.slug}`;
  let getInterestedUsersUrl=process.env.REACT_APP_API_ENDPOINT+`/api/event-interested-users/${params.slug}`;
  let friendRequestUrl = process.env.REACT_APP_API_ENDPOINT + "/api/friends-request";
  let leaveEventUrl=process.env.REACT_APP_API_ENDPOINT + "/api/leave-event";

  const [relatedEventData, setRelatedEventData] = useState([]);

  let relatedEventUrl = process.env.REACT_APP_API_ENDPOINT + "/api/list-events";

  useEffect(() => {
    let url;
    if(isToken===null){
      url=getEventsUrl;
    }else{
      url=getEventsSecureUrl;
    }
    secureApiCall( url, 'get')
    .then( response =>{
      if(! response.err){
        console.log("----",response.payload);
        setEventData([response.payload]);

        if(response.payload.isPending){
          setJoinBtnStatus("Pending");
          setIsDisabled(true);
        }else{
          setJoinBtnStatus("Join Now");
          setIsDisabled(false);
        }

        if(response.payload.banner.length===0){
          carousalImages.push({
            original:defaultCoverImage ,
            thumbnail:defaultThumbnailImage,
          })
        }
        response.payload.banner.map((image)=>{
            carousalImages.push({
              original:process.env.REACT_APP_IMG_URL_ENDPOINT+ image.coverImage ,
              thumbnail:process.env.REACT_APP_IMG_URL_ENDPOINT+ image.thumbnailImage,
            })
        })
        setCarousalImages(carousalImages);
      }
    })
    .catch( err=>{
      console.log( err);
    })

    secureApiCall(getJoinedUsersUrl,'get')
        .then((response)=>{
            console.log(response);
            if(!response.err){
                setJoinedUsersData(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        });

    secureApiCall(getInterestedUsersUrl,'get')
        .then((response)=>{
            console.log(response);
            if(!response.err){
                setInterestedUsers(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        });

    secureApiCall(relatedEventUrl, "get")
    .then((response) => {
      console.log(response);
      setRelatedEventData(response.payload);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);
  
  const handelJoin = (event_id)=>{
    let res=window.confirm("Do you want to join this tribe");
    if(res){
      if(!isAuthenticated()){
        history.push('/login');
      }

      let headers = {
        "Content-type": "application/json",
      };
      let formData = {
        event_id: event_id
      };
      let formDataString = JSON.stringify(formData);
      secureApiCall(joiningRequest, "post", formDataString, headers)
      .then( res =>{
        if( !res.err){
          setJoinBtnStatus("Pending");
          setIsDisabled(true);
          toast.success("Your joining request raised successfully");
        }else{
          toast.error(res.msg);
        }

      })
      .catch( err =>{

        setJoinBtnStatus("Join Now");
        setIsDisabled(false);
        console.log( err);
        toast.error("Something went wrong");
      });
      }else{
        console.log("result: ",res);
      }
  }

  const handelInterest = (event_id,position) =>{
    let res=window.confirm("Do you want to raised interested");
    if(res){
    if (!isAuthenticated()) {
      history.push("/login");
    }
    
    let len=interestedUsers.length;
    let headers = {
      "Content-type": "application/json",
    };
    let formData = {
      event_id: event_id,
    };
    let formDataString = JSON.stringify(formData);
    secureApiCall(InterestRequest, "post", formDataString, headers)
      .then((res) => {
        if (!res.err) {
          interestedUsers.splice(len+1,0,interestedUsers);
          toast.success("Your interest raised successfully");
          setInterestedUsers([...interestedUsers]);
        }else{
          toast.error(res.msg);
        }

      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
    }else{
      console.log("result: ",res);
    }
  }

  const handleAddFriend=(friend_id,position)=>{
    let requestData = {
      friend_id: friend_id,
    };
    secureApiCall(friendRequestUrl, "post", JSON.stringify(requestData), {
      "Content-type": "application/json",
    })
      .then((response) => {
        if (!response.err) {
           interestedUsers.splice(position, 1);
           toast.success( "Friend request raise");
           setInterestedUsers([...interestedUsers]);
        }else{
          toast.error(response.msg);
        }
        console.log("--response--", response);
      })
      .catch((err) => {
         toast.error("something went wrong ");
        console.log(err);
      });
  };

  const handleLeaveEvent=(eventId,event_type)=>{
    let confirm=window.confirm(`Do you want to Leave this ${event_type}`);
    if(confirm){
      secureApiCall(`${leaveEventUrl}?event_id=${eventId}`,'get')
      .then((response) => {

        setLeaveBtnStatus("Leaved");
        setIsDisabled(true);
        toast.success(response.msg);
        // history.push("/events");
      })
      .catch((err) => {
         toast.error("something went wrong ");
        console.log(err);
      });
    }
  }
  return (
    <>
      <Header></Header>
      {
        eventData  && eventData.map((val,index)=>(
          <PageHeading key={index} title={val.title}></PageHeading>     
        ))
      }
      <section className="main bg-transparent">
        <div className="steps-area">
          {/* {eventData.map((value) => ( */}
          <div className="steps-form single-event">
            <div className="row">
              <div className="col-xs-12 col-md-6 col-lg-8">
              <div
                  id="myCarousel"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner" role="listbox">
                    <ImageGallery 
                      items={carousalImages}
                      showFullscreenButton={false}
                      showPlayButton={false}
                    ></ImageGallery>
                  </div>
                </div>
               {eventData && eventData.map((val,index)=>(
                 <>
                 <div className="search-title">About {val.title} </div>
                {/* <p>{value.description}</p> */}
                <p key={index}><Markup content={nl2br(val.description) }/>
                </p>
                </>
               ))}
                {/* <DialogBox></DialogBox> */}
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4">
                <div className="event-details">
                  {/* <h5>Event Details</h5> */}
                  <h5>{eventData && eventData.map(event=>event.title)}</h5>
                  {eventData && eventData.map((event,index)=>(
                    <ul>
                    <li>
                      {event.discipline === "Running"? 
                          <span>
                            <i className="fas fa-running" />
                            {event.discipline}
                          </span>
                          :event.discipline==="Swimming"?
                          <span>
                            <i className="fas fa-swimmer" />
                            {event.discipline}
                          </span>
                          :event.discipline==="Cycling"?
                          <span>
                            <i className="fas fa-biking" />
                            {event.discipline}
                          </span>
                          :event.discipline==="Athlons"?
                          <span>  
                            <i className="fas fa-football-ball" />
                            {event.discipline}
                          </span>:<></>
                      }
                    </li>                   
                    <li> 
                      <i className="fas fa-user"></i>
                      <a href={`/u/${event.owner_name_slug}`}><span>{event.owner_name}</span></a> 
                    </li>
                  </ul>
                  
                  ))}

                  {
                  isToken===null?
                  <div className="action">
                    <button className="btn btn-secondary-outline" onClick={()=>{history.push("/login")}}>Join Now</button>
                  </div>
                  :

                  eventData && eventData.map((val,index)=>(
                    <div className="action">
                    {
                      val && val.isEditable && (
                        <a href={`/edit-tribe/${val.slug}`} className="btn btn-secondary-outline">{`Edit ${val.event_type}`}</a>
                      ) 
                    }
                     {
                        val && !val.isEditable && (
                          <>
                          {
                            val.alreadyJoined ?  (
                              <button className="btn btn-secondary-outline" onClick={() =>handleLeaveEvent(val._id,val.event_type)} disabled={isDisabled} >{isDisabled ? leaveBtnStatus :`Leave ${val.event_type}`}</button>
                            ) : (
                              <button className="btn btn-secondary-outline" onClick={() => {handelJoin(val._id);}} disabled={isDisabled} >{joinBtnStatus}</button>
                            )
                            
                          }
                          
                          </>
                        )

                     }
              </div>
              ))}
             </div>
              {/* {!joinedUsersData =="" && !interestedUsers ==""?
               (<></>)
               : <div>
                  <div className="event-details event-details-joined">
                    {joinedUsersData?
                      <a className="btn-tab-style active" data-toggle="tab">
                        <span
                           onClick={()=>{setViewJoinedUsers(true);setViewInvitedUsers(false)}}
                          id="jo_span"
                        >
                          Joined
                        </span>
                      </a>
                    :(<></>)}
                    
                    {interestedUsers? 
                      <a className="btn-tab-style" data-toggle="tab">
                        <span
                           onClick={()=>{setViewInvitedUsers(true);setViewJoinedUsers(false)}}
                          id="in_span"
                          
                        >
                          Interested
                        </span>
                      </a>
                    :(<></>)}
                  <div className="tab-content">
                  {viewJoinedUsers 
                      ? 
                      (
                        <div
                        className="join-interested-list join-interested-list1 tab-pane fade in active"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="list-ul list-ul1">
                            {joinedUsersData && joinedUsersData.map((user,index)=>(
                              userId===user.user_id
                              ?
                              // <>No one joined</>
                              <li key={index}>
                                <div className="singlebox singlebox1">
                                  <a href="#">
                                  <img src={userImg} alt className="featured-img" />
                                    <span className="clientname">{user.user_name}</span>
                                  </a>
                                </div>
                              </li>
                              :
                              <li key={index}>
                                <div className="singlebox singlebox1">
                                  <a href="#">
                                  <img src={userImg} alt className="featured-img" />
                                    <span className="clientname">{user.user_name}</span>
                                  </a>
                                  <span className="usr-icon">
                                    <a href="#">
                                      <i className="fas fa-user-plus" onClick={()=>{handleAddFriend(user.user_id,index)}} />
                                    </a>
                                  </span>
                                </div>
                              </li>
                            ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      )
                      :(<></>)}
                    {viewInvitedUsers?
                      (
                        <div
                        className="join-interested-list join-interested-list1 tab-pane fade in active"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="list-ul list-ul1">
                            {interestedUsers && interestedUsers.map((user,index)=>(
                              userId===user.user_id?
                              <></>:
                            <li>
                                <div className="singlebox singlebox1">
                                  <a href="#">
                                    <img src={userImg} alt className="featured-img" />
                                    <span className="clientname">{user.user_name}</span>
                                  </a>
                                  <span className="usr-icon">
                                    <a href="#">
                                      <i className="fas fa-user-plus" />
                                    </a>
                                  </span>
                                </div>
                              </li>
                            ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      )
                      :(<></>)
                    }
                </div>
            </div>
                 
                </div>
              
            } */}
                {/* <div className="col-xs-6 col-md-12 col-lg-12" >
                  <div className="event-details">
                
                    <h5>Joined Participant</h5>
                    
                        {joinedUsersData.map((user,index)=>(
                          userId===user.user_id?
                          <ul key={index}>
                              <li><label>{index+1}.&nbsp;{user.user_name}</label></li>
                              // {/* <button className="new-btn new-btn-secondary"  onClick={()=>{handleAddFriend(user.user_id,index)}}>Add Friend</button> 
                              <button className="new-btn new-btn-secondary">View Profile</button>
                          </ul>
                          :
                          <ul key={index}>
                              <li><label>{index+1}.&nbsp;{user.user_name}</label></li>
                              <button className="new-btn new-btn-secondary"  onClick={()=>{handleAddFriend(user.user_id,index)}}>Add Friend</button>
                          </ul>
  
                        ))}
                  </div>
                </div>
                <div className="col-xs-6 col-md-12 col-lg-12">
                <div className="event-details" style={{paddingLeft:"10px"}}>
                    <h5>Interested&nbsp;Participant</h5>
                      {interestedUsers.map((user,index)=>(
                      userId===user.user_id?
                        <ul key={index}>
                            <li><label>{index+1}.&nbsp;{user.user_name}</label></li>
                            {/* <button className="new-btn new-btn-secondary"  onClick={()=>{handleAddFriend(user.user_id,index)}}>Add Friend</button> 
                            <button className="new-btn new-btn-secondary">View Profile</button>
                        </ul>
                        :
                        <ul key={index}>
                            <li><label>{index+1}.&nbsp;{user.user_name}</label></li>
                            <button className="new-btn new-btn-secondary"  onClick={()=>{handleAddFriend(user.user_id,index)}}>Add Friend</button>
                        </ul>
                      )
                    )
                    }
              
                    </div>
                </div>
               */}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="search-title">TRIBE MEMBERS</div>
              </div>
            </div>
            {eventData && eventData.map((val,index)=>(
            <div className="row">
                  { joinedUsersData && joinedUsersData.map((user,index)=>(
                  <div key={index} className="col-xs-12 col-sm-6 col-md-3">
                    <a href={`/u/${user.user_name_slug}`} className="event-box">
                      <div className="event-img">
                        <img
                          src={defaultUser}
                          className="img-responsive"
                          alt
                          width="200px"
                        />
                      </div>
                      <div className="event-description">
                        <h5>{user.user_name}</h5>
                        <div>
                          <ul>
                            <li>
                              {(user.status).toUpperCase()}   
                            </li>
                          </ul>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          ))}
          </div>
          {/* ))} */}
        </div>
      </section>
    
      <div className="clearfix"></div>  
      <Footer event_type="tribe"></Footer>
    </>
  );
};

export default Tribes;

import React, { useEffect, useState } from "react";
import PageHeading from "../../components/pageHeading";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import { secureApiCall, ApiCall } from "../../services/conf/apiCall";
import {regions,disciplines} from "../../constants/global";
import { getStartDateOfWeek,getTodayDate,getStartingDateOfMonth } from "../../helpers/utils";
import defaultFeatureImage from "../../assets/img/feature_image.jpg";
import moment from "moment"


const TribesList = (props) => {
  const getAllTribesUrl = process.env.REACT_APP_API_ENDPOINT + "/list-all-tribes";

  const [tribesData, setTribesData] = useState([]);
  const [regionData,setRegionData]=useState(regions);
  const [disciplineData,setDisciplineData]=useState(disciplines);

  const [dateFilter,setDateFilter]=useState("");
  const [disciplineFilter,setDisciplineFilter]=useState("");
  const [regionFilter,setRegionFilter]=useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    secureApiCall(getAllTribesUrl, "get")
      .then((response) => {
        console.log(response);
        setTribesData(response.payload);
      })
      .catch((err) => {
        console.log(err);
      });

    // async function fetchData() {
    //   const result = await axios(url);
    //   setEventData(result.data.payload);
    //   console.log(result.data.payload);
    // }
    // fetchData();
  }, []);

  const handleTribeFilter=()=>{
    console.log(dateFilter,disciplineFilter,regionFilter);

    secureApiCall(`${getAllTribesUrl}?duration=${dateFilter}&discipline=${disciplineFilter}&region=${regionFilter}&query=${searchText}`, "get")
    .then((response) => {
      console.log(response);
      setTribesData(response.payload);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect( () => {
    handleTribeFilter();
  }, [searchText,dateFilter,disciplineFilter,disciplineData,regionFilter]);


  return (
    <>
      <Header></Header>
      <PageHeading title="Tribes"></PageHeading>
      <section className="main bg-transparent">
        <div className="steps-area">
          <div className="steps-form">
            <div className="row">
              <div className="col-xs-8 col-8">
                <ul className="filter-options">
                  {/* <li>
                    <div className="form-group">
                      <label>Select Date </label>
                      <div className="select-wrap">
                        <i className="fas fa-calendar" />
                        <select name id className="form-control" onChange={(e)=>{setDateFilter(e.target.value)}}>
                          <option value="">Select one</option>
                          <option value={getTodayDate()} >Today</option>
                          <option value={getStartDateOfWeek(new Date())}>This week</option>
                          <option value={moment(getStartingDateOfMonth(new Date())).format('L')}>This Month</option>
                          <option value={'all'}>All</option>
                        </select>
                      </div>
                    </div>
                  </li> */}
                  {/* <li>
                    <div className="form-group">
                      <label>Select your date range:</label>
                      <div className="select-wrap">
                        <i className="ion-android-calendar" />
                        <select name id className="form-control">
                          <option value>-Select one-</option>
                          <option value="Today" selected>
                            Fri, Aug 20 - Fri, Dec 20
                          </option>
                        </select>
                      </div>
                    </div>
                  </li> */}
                  {/* <li>
                    <div className="form-group">
                      <label>Tribes type:</label>
                      <div className="select-wrap">
                        <i className="fas fa-flag" />
                        <select name id className="form-control">
                          <option value>-Select one-</option>
                          <option value="Today" selected>
                          &nbsp;Event
                          </option>
                        </select>
                      </div>
                    </div>
                  </li> */}
                  <li>
                    <div className="form-group">
                      <label>Discipline:</label>
                      <div className="select-wrap">
                      {disciplineFilter == ''  && <i className="fas fa-trophy"></i> }
                      {disciplineFilter == 'Running'  && <i className="fas fa-running" /> }
                      {disciplineFilter == 'Athlons' && <i className="fas fa-football-ball" />}
                      {disciplineFilter == 'Cycling' && <i className="fas fa-biking"/>}
                      {disciplineFilter == 'Swimming' && <i className="fas fa-swimmer" />}
                        <select name id className="form-control" onChange={(e)=>{setDisciplineFilter(e.target.value)}}>
                          <option value="">All</option>
                          {disciplineData.map((discipline,index)=>(  
                            <option key={index} value={discipline.name}>{discipline.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label>Region:</label>
                      <div className="select-wrap">
                        <i className="fas fa-map-marker-alt" />
                        <select name id className="form-control" onChange={(e)=>{setRegionFilter(e.target.value)}}>
                          <option value="">Select one</option>
                          {regionData.map((region,index)=>(  
                            <option key={index} value={region.name}>{region.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>

             


            <div className="form-group search-input">
              <label className="empty-label">&nbsp;</label>
              <div className="">
                <span className="fas fa-search search-icon" /> 

                <input type="text" id="search" className="form-control" onChange={(e)=>setSearchText(e.target.value)} style={{width:'200px',height:'40px'}} placeholder="Search" name="search" />

              </div>
            </div>
            </li>
                  {/* <li>
                    <div className="form-group">
                      <label className="empty-label">&nbsp;</label>
                      <button className="btn btn-primary btn-block" onClick={handleTribeFilter}>
                        <span className="fas fa-search" /> Search
                      </button>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="search-title">
                  {tribesData && tribesData.length == 0 ? " No tribes found" : `Showing ${tribesData && tribesData.length} Tribes`}
                </div>
              </div>
            </div>
            <div className="row">
              {tribesData && tribesData.map((value, key) => (
                <div className="col-xs-12 col-sm-6 col-md-4" key={key}>
                  <a
                    href={`/tribes/${value.slug}`}
                    className="event-box"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <div className="event-img">
                     {value.banner.length===0?
                        <img
                        // src={`${value.banner}`}
                        src={defaultFeatureImage}
                        className="img-responsive"
                        alt=""
                      />
                      :
                      <img
                      // src={`${value.banner}`}
                      src={process.env.REACT_APP_IMG_URL_ENDPOINT+value.banner[0].featureImage}
                      className="img-responsive"
                      alt=""
                    />
                      }
                    </div>
                    <div className="event-description">
                      <h5>{value.title}</h5>
                      <div>
                        <ul>
                          <li>
                            <i className="fas fa-biking" />
                            &emsp;{value.discipline}
                          </li>
                          <li>
                            <i className="fas fa-users" style={{color:"#ff5100"}}></i>
                            <span>&emsp;Joined ({value.total_joined})</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer event_type="event"></Footer>
    </>
  );
};
export default TribesList;

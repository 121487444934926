import React, { useState } from "react";
import Stepper from "react-js-stepper";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import PageHeading from "../../components/pageHeading";
import Step1 from "../../components/createTribe/step1";
import Step2 from "../../components/createTribe/step2";
import Step3 from "../../components/createTribe/step3";
import Step4 from "../../components/createTribe/step4";

const CreateEventNew = () => {
  const steps = [
    {
      title: "Information",
    },
    {
      title: "Invite",
    },
  ];

  const [activeStep, setActiveStep] = useState(1);
  const [eventId,setEventId]=useState([]);
  const [stepOneData, setStepOneData] = useState({
    title:"",
    description: "",
    discipline: "",
    file: "",
    distance: "", 
    discipline_type: "",
    event_type:"tribe",
    approval_required:false,
  });
  console.log("Step One Data=====>",stepOneData);

  const [stepTwoData, setStepTwoData] = useState({
    location: "",
    region: "",
    date:"",
    start_time: "00:00",
    end_time: "00:00",
  });

  console.log("Step Two Data=====>",stepTwoData);

  const [stepThreeData, setStepThreeData] = useState({
    total_distance: "",
    time: "",
    average_speed: "",
    upload_map: "",
    food_stop: "",
    visibility: "",
  });
  const handleOnClickStepper = (step) => {
    setActiveStep(step);
  };

  const handleOnClickNext = (val) => {
    console.log("next click");
    if (activeStep === 1) {
      setStepOneData(val);
      console.log("step 3",val);
      setEventId({
        'event_id':val.eventRes._id,
        'event_name':val.eventRes.title
      })
    
    }
    if (activeStep === 2) {
      setStepTwoData(val);
      
    }
    if (activeStep === 3) {
      setStepThreeData(val);
      
    }
    let nextStep = activeStep + 1;
    setActiveStep(nextStep);
    //this.setState({ activeStep: nextStep });
  };

  const handleOnClickBack = () => {
    console.log("back click");
    let prevStep = activeStep - 1;
    setActiveStep(prevStep);

    //this.setState({ activeStep: prevStep });
  };
  return (
    <>
      <Header></Header>
      <PageHeading title="CREATE YOUR TRIBE"></PageHeading>

      <section className="main bg-transparent">
        <div className="steps-area">
          <Stepper
            className="new-step"
            steps={steps}
            activeStep={activeStep}
            onSelect={handleOnClickStepper}
            showNumber={false}
          />
          <div className="step_content ">
            {activeStep === 1 && (
              <Step1
                isEdit={false} 
                stepData={stepOneData}
                stepData1={stepTwoData}
                stepData2={stepThreeData}
                nextStep={handleOnClickNext}
              ></Step1>
            )}
            {activeStep === 2 && (
              <Step4
                stepData={eventId}
                // dataStepTwo={stepTwoData}
                prevStep={handleOnClickBack}
                nextStep={handleOnClickNext}
              ></Step4>
            )}
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default CreateEventNew;

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PageHeading from "../../components/pageHeading";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import {secureApiCall} from "../../services/conf/apiCall";
import Spinner from "../../components/spinner";
import { ToastContainer, toast } from "react-toastify";

import "./forgetPassword.scss";

const NewPassword = (props) => {
  const params=useParams();
  const history=useHistory();
  console.log("EmailVerify -> params", params.token)
  const [status,setStatus]=useState("Change");
  const [viewVerifiedButton,setViewVerifiedButton]=useState(false)
  const passwordUpdateUrl=process.env.REACT_APP_API_ENDPOINT+`/profile/reset-password/${params.token}`;
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [retypePasswordError, setRetypePasswordError] = useState(null);
  const [passwordCheck, setPasswordCheck] = useState(false);

  const validatePassword = (newPassword) => {
    if (newPassword.length > 6) {
      if (newPassword) {
        setNewPasswordError(null);
        return true;
      } else {
        setNewPasswordError("Please enter password");
        return false;
      }
    } else {
      setNewPasswordError("Please enter password");
    }
  };
  const checkRetypePassword = (retypePassword) => {
    if (validatePassword(newPassword)) {
      if (retypePassword === newPassword) {
        setRetypePasswordError(null);
        return true;
      } else {
        setRetypePasswordError("Please enter same password");
        return false;
      }
    } else {
      setRetypePasswordError("Please re-enter password");
    }
  };

  const validatePasswordForm = () => {
    let error = true;
    if (!validatePassword(newPassword)) {
      error = false;
    }
    if (!checkRetypePassword(retypePassword)) {
      error = false;
    }
    return error;
  };

  const pushPasswordData = async () => {
    let passwordData={
    password:newPassword,
  };

    secureApiCall(passwordUpdateUrl, "post" ,JSON.stringify(passwordData),{'Content-type':'application/json'})
      .then((response) => {
        console.log(response);
        setPasswordCheck(false);
        toast.success("Success");
        history.push('/login');
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const updatePassword=()=>{
    if(validatePasswordForm()){
      pushPasswordData();
    }
  }
  return (
    <>
      <Header></Header>
      <PageHeading title={'Reset Password'}></PageHeading>     
      <section className="main bg-transparent">
        <div className="steps-area">
          <div className="steps-form single-event">
            <div className="row">
              <div style={{textAlign:'center'}}>
                    <div className="col-md-6 col-md-offset-3">
                    <div className="form-group">
                      <label style={{textAlign:'start'}}>New Password</label>
                      <input
                        className="form-control"
                        placeholder="Enter password.."
                        type="password"
                        autoComplete="off"
                        name="newPassword"
                        onChange={(e) => {
                           setNewPassword(e.target.value);
                        }}
                      />
                      { newPasswordError && (
                        <span className="text-danger small">
                          { newPasswordError}
                        </span>
                      )}
                    </div>
                      <div className="form-group">
                      <label style={{textAlign:'start'}}>Confirm Password</label>
                      <input
                        className="form-control"
                        placeholder="Re-enter password"
                        type="password"
                        name="retypePassword"
                        autoComplete="off"
                        onChange={(e) => {
                           setRetypePassword(e.target.value);
                        }}
                      />
                      {retypePasswordError && (
                        <span className="text-danger small">
                          { retypePasswordError}
                        </span>
                      )}
                    </div>
                    </div>
                    <div className="col-md-6 col-md-offset-3 ">
                      <div className="form-group ">
                      {passwordCheck ? (
                        <div >
                          <Spinner loading={passwordCheck} color="#ff5100" ></Spinner>
                          </div>
                        ) : (
                          <button className="email-check-btn email-check-btn-primary" onClick={updatePassword} >{status}</button>
                        )}
                       
                      </div>
                    </div>
                  </div>
            </div>
           </div>
        </div>
      </section>
    
      <div className="clearfix"></div>  
      <Footer></Footer>
    </>
  );
};

export default NewPassword;

import React,{useEffect,useState,useRef} from "react";
import Header from "../../components/header";
import Navbar from "../../components/navbar";

import { ToastContainer, toast } from "react-toastify";
import user from "../../assets/img/follower3.png";
import { secureApiCall } from "../../services/conf/apiCall";
import "./viewFriendsPage.scss";

const ViewInviteFriendsPage = (props) => {

  const [notificationData,setNotificationData]=useState([]);
  const [view,setView]=useState(false);
  const [searchedUser,setSearchedUser]=useState("");
  const search_box=useRef();
 
  const notificationUrl = process.env.REACT_APP_API_ENDPOINT+"/api/notification";
  const viewFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/my-friends";
  const acceptFriendRequestUrl=process.env.REACT_APP_API_ENDPOINT+"/api/confirm-friend-request";
  const declineFriendRequestUrl=process.env.REACT_APP_API_ENDPOINT+"/api/reject-friend-request";
  const searchFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/search-friends";
  
  useEffect(() => {
    secureApiCall(notificationUrl,"get")
    .then((response)=>{
      console.log(response);
      if(!response.err){
        response.payload.map((user,index)=>{
          console.log("----",user.type);
          if(user.type=="friend_request" && user.remarks=="pending"){
            setNotificationData([user])
          }
        })
        
      }
    }).catch((err)=>{console.log(err)})
  }, [])

  const handleAcceptFriendRequest=(notification_id,position)=>{
    let formData = JSON.stringify({ notification_id: notification_id });
    let header = {
      "Content-type": "application/json",
    };
    let choice=window.confirm("Do you want to accept user's request");
    if(choice){
    secureApiCall(acceptFriendRequestUrl, "post", formData,header)
        .then((response) => {
          console.log(response);
          if (!response.err) {
            notificationData.splice(position, 1);
            setNotificationData([...notificationData]);
            toast.success("Success");
          } else {
            toast.error(response.msg);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong ");
          console.log(err);
        });
    }else{
      console.log("cancel");
    }
  }
  const handleDeclineFriendRequest=(notification_id,position)=>{  
  let formData = JSON.stringify({ notification_id: notification_id });
  let header = {
    "Content-type": "application/json",
  };
  let choice=window.confirm("Do you want to decline user's request");
    if(choice){
      secureApiCall(declineFriendRequestUrl, "post", formData,header)
          .then((response) => {
            console.log(response);
            if (!response.err) {
              notificationData.splice(position, 1);
              setNotificationData([...notificationData]);
              toast.success("Decline");
            } else {
              toast.error(response.msg);
            }
          })
          .catch((err) => {
            toast.error("Something went wrong ");
            console.log(err);
          }); 
    }else{
      console.log("cancel")
    }   
  }

  const handleUserSearch=()=>{
    console.log("--my data",search_box.current.value)
    secureApiCall(searchFriendUrl,"post",JSON.stringify({user_name:search_box.current.value}),{'Content-type':'application/json'})
      .then((response) => {
        console.log("--searched user--",response.payload)
         setSearchedUser(response.payload)
         setNotificationData(response.payload)
       })
      .catch((err) => {
          console.log(err);
      });
      // search_box.current.value="";

  }
  return (
    <div className="wrapper">
      <Header></Header>
      <Navbar></Navbar>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <h1>
            <a className="text-black" href="/my-friends">
            <span  className="add_fr">View Friends</span>
            </a>
            
            <span className="send_re active">View Invites</span>
          </h1>
          <span className="ser_span">
            <input type="text" name="search" ref={search_box} placeholder="search here"/>
            <button className="ser_in_btn"><i className="fas fa-search" onClick={()=>handleUserSearch()}></i></button>
          </span>
        </section>
        <section className="viewfriend_sec">
          <ul className="friendlist clearfix">
            {notificationData ?
              notificationData.map((value, index) => (
                <li key={index}>
                  <div className="singlebox">
                    <div className="friendimg">
                      <a href="#">
                        <img src={user} alt />
                      </a>
                    </div>
                    <div className="main-content">
                      <div className="friend-name">
                        <a href="#">{value.send_user}</a>
                      </div>
                      <div className="btnbox" style={{ padding: "0px" }}>
                        <button
                          type="submit"
                          className="new-btn btn-dark new-btn-secondary"
                          onClick={()=>{handleAcceptFriendRequest(value._id,index)}}
                        >
                          Accept
                        </button>
                        <button
                          type="submit"
                          className="new-btn new-btn-primary "
                          onClick={()=>{handleDeclineFriendRequest(value._id,index)}}
                        >
                          Decline
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))
              :<></>
            }
          </ul>
          {/* <div className="loadmore text-center">
            <button className="btn btn-orange">Load More</button>
          </div> */}
        </section>
      </div>
      ;
    </div>
  );
};

export default ViewInviteFriendsPage;

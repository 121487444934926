import React, { Component, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import useValidationHook from "./validation";
import { ToastContainer, toast } from "react-toastify";
import {secureApiCall} from "../../services/conf/apiCall";
import registerPage from ".";
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const RegisterPage = (props) => {
  const validationHook = useValidationHook();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  const googleUrl=process.env.REACT_APP_API_ENDPOINT+"/auth/google-signup";
  const facebookUrl=process.env.REACT_APP_API_ENDPOINT+"/auth/facebook-signup";
  
  const GoogleClientId="592022656893-orjr9jbu1o02p2pa3630ja35vrn65uiu.apps.googleusercontent.com";
  const FacebookAppId="372159620034555";
  
  const onSuccess=async(res)=>{
    console.log("onSuccess -> res", res);

    // let profileObj=await getUserSocialProfileData(res.tokenId);
    signupWithGoogle(res.tokenId);
  }
  const onFailure=(res)=>{
    console.log("onFailure -> res", res)  
  }

  const goToLogin = () => {
    let formData = {
      email: validationHook.userEmail.toLowerCase(),
      password: validationHook.password,
    };
    let url = process.env.REACT_APP_API_ENDPOINT + "/auth/login";

    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((response) => {
        //response.json();
        console.log("===================", response);
        if (!response.err) {
          let token = response.token;
          localStorage.setItem("authToken", token);
          history.push("/profile");
          toast.success(`Welcome Back`);
        } else {
          // setServerError(response.statusText);
          setServerError("Incorrect Email id or Password");
        }
      })
      .catch((err) => {
        setServerError("Incorrect Email id or Password");
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const pushLoginData = () => {
    let formData = {
      first_name: validationHook.userFirstName,
      last_name: validationHook.userLastName,
      email: validationHook.userEmail,
      password: validationHook.password,
    };
    const url = process.env.REACT_APP_API_ENDPOINT + "/auth/register";

    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if(response.ok){
          console.log("pushLoginData -> response", response)
          toast.success("Success! you registered successfully. Please verify your email");
          // history.push("/profile");
          goToLogin();
        }else{
          toast.error("User already exist");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validationHook.validationForm()) {
      setLoading(true);
      pushLoginData();
    }
    setInterval(() => {
      setLoading(false);
    }, 1000);
  };

  const signupWithGoogle=(googleProfileToken)=>{
    secureApiCall(googleUrl, "post" ,{},{'googleProfileToken':googleProfileToken})
    .then((response)=>{
      console.log("signupWithGoogle -> response", response.payload.length);
      if(response.payload.length>0){
        toast.success(response.msg);
        history.push('/login');
      }else{
        toast.warn(response.msg);
        history.push('/login');
      }
    })
    .catch((err)=>{
      console.log("signupWithGoogle -> err", err)
      toast.error(err.msg);
    })
  }

  const signupWithFacebook=(facebookProfileToken)=>{
  secureApiCall(facebookUrl, "post" ,{},{'facebookProfileToken':facebookProfileToken})
    .then((response)=>{
      console.log("signupWithGoogle -> response", response.payload.length);
      if(response.payload.length>0){
        toast.success(response.msg);
        history.push('/login');
      }else{
        toast.warn(response.msg);
        history.push('/login');
      }
    })
    .catch((err)=>{
      console.log("signupWithGoogle -> err", err)
      toast.error(err.msg);
    })

  }
  const responseFacebook=(res)=>{
  console.log("responseFacebook -> res", res)
  signupWithFacebook(res.accessToken)
  }


  return (
    <div>
      <Header></Header>
      <section className="main register-area">
        <div className="register-container">
          <div className="title">Register</div>
          <div className="sub-title">Register below with your credentials</div>
          <div className="row">
          <form>
            <div className="col-md-6 or-divider">
              <div className="regi-form">
                {/* <div className="form-group">
              <label>User name</label>
              <input className="form-control" placeholder="enter username" value="" type="text">
          </div> */}
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    defaultValue={validationHook.userFirstName}
                    // onChange={(e) => {
                    //   validateEmail(e.target.value);
                    //   setUserEmail(e.target.value);
                    // }}
                    onChange={(e) => {
                      validationHook.setUserFirstName(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Enter first name"
                  />
                  {validationHook.firstNameError && (
                    <p className="error-label text-danger">
                      {validationHook.firstNameError}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    defaultValue={validationHook.userLastName}
                    // onChange={(e) => {
                    //   validateEmail(e.target.value);
                    //   setUserEmail(e.target.value);
                    // }}
                    onChange={(e) => {
                      validationHook.setUserLastName(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Enter last name"
                  />
                  {validationHook.lastNameError && (
                    <p className="error-label text-danger">
                      {" "}
                      {validationHook.lastNameError}{" "}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    name="userEmail"
                    defaultValue={validationHook.userEmail}
                    onChange={(e) => {
                      validationHook.setUserEmail(e.target.value);
                    }}
                    // value={userEmail}
                    // onChange={(e) => {
                    //   validateEmail(e.target.value);
                    //   setUserEmail(e.target.value);
                    // }}
                    className="form-control"
                    placeholder="Enter email address"
                  />
                  {validationHook.emailError && (
                    <p className="error-label text-danger">
                      {" "}
                      {validationHook.emailError}{" "}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    name="password"
                    defaultValue={validationHook.password}
                    onChange={(e) => {
                      validationHook.setPassword(e.target.value);
                    }}
                    // value={password}
                    // onChange={(e) => {
                    //   validatePassword(e.target.value);
                    //   setPassword(e.target.value);
                    // }}
                    className="form-control"
                    placeholder="Enter password"
                  />
                  {validationHook.passwordError && (
                    <p className="error-label text-danger">
                      {" "}
                      {validationHook.passwordError}{" "}
                    </p>
                  )}
                </div>
                {serverError && (
                  <p className="text-danger text-center">{serverError}</p>
                )}
                <div className="text-center">
                  {loading ? (
                    <button className="btn btn-orange">loading...</button>
                  ) : (
                    <button
                      onClick={handleLogin}
                      className="btn btn-orange"
                      type="submit"
                    >
                      Sign Up
                    </button>
                  )}
                  <br />
                  <br />
                  <div className="clearfix" />
                  If have an account?{" "}
                  <a className="orange-link" href="/login">
                    Log in here
                  </a>
                </div>
              </div>
            </div>
          </form>
            <div className="col-md-6">
              <div className="regi-social">
                <ul className="social-login">
                  <li className="fb">
                    <FacebookLogin
                        appId={FacebookAppId}
                        onClick={signupWithFacebook}
                        fields="name,email,picture"
                        scope="public_profile,user_friends"
                        callback={responseFacebook}
                        render={renderProps => (
                          <button 
                            style={{backgroundColor:"transparent",color:"white",border:"0px solid",padding:"16px"}} 
                            onClick={renderProps.onClick} 
                            disabled={renderProps.disabled} >Connect with FaceBook</button>
                        )}
                    ></FacebookLogin>
                  </li>
                  <li className="g-plus">
                    <GoogleLogin 
                      clientId={GoogleClientId}
                      render={renderProps => (
                        <button 
                          style={{backgroundColor:"transparent",color:"white",border:"0px solid",padding:"16px"}} 
                          onClick={renderProps.onClick} 
                          disabled={renderProps.disabled}>Connect with Google</button>
                      )}
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      buttonText="Connect with Google"
                    ></GoogleLogin>
                    {/* <a onClick={signupWithGoogle}>Connect with Google</a> */}
                  </li>
                </ul>
                <div className="terms-text">
                  By clicking the buttons above, you agree to tribeathlon's{" "}
                  <a className="orange-link" href="#">
                    terms
                  </a>
                  and <a href="#">privacy policy </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </section>
      <Footer></Footer>
    </div>
  );
};

export default RegisterPage;

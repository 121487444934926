import React, { useEffect, useState } from "react";
import useValidationHook from "./validation";
import {useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  disciplines,
  typesOfAthlons,
  typesOfCycling,
  typesOfRunning,
  typesOfSwimming,
  distanceTypeCycling,
  distanceTypeRunning,
  distanceTypeSwimming,
  distanceTypeAthlons,
} from "../../../constants/global";
import DialogBox from "../../dialog";
import image from "../../../assets/img/events_bg.jpg"
import "./step.scss"
import { render } from "@testing-library/react";
import { secureApiCall } from "../../../services/conf/apiCall";
import Spinner from "../../spinner";

const Step = (props) => {
  const params=useParams();
  const history = useHistory();
  let eventId=props.stepData.event_id;
  const createTribeUrl=process.env.REACT_APP_API_ENDPOINT+"/api/create-tribe";
  const updateTribeUrl=process.env.REACT_APP_API_ENDPOINT+`/api/update-tribe/${eventId}`;
  const deleteImageUrl=process.env.REACT_APP_API_ENDPOINT+`/api/delete-event-image/${eventId}`;

  // console.log("===============---------------",props.stepData);
  const [next, setNext] = useState(null);
  const [stepsData, setStepsData] = useState(props.stepData);
  const validationHook = useValidationHook(props.stepData);
  const [disciplineData,setDisciplineData]=useState([]);
  const [distanceData,setDistanceData]=useState([]);
  const [disciple,setDisciple]=useState(disciplines);
  const [newImgData,setNewImgData]=useState([]);
  const [loading,setLoading]=useState(false);
  const [imageSelected,setImageSelected]=useState(0)
  const pushData = () => {
    // console.log("validationHook.eventImage,", validationHook.eventImage);
     return {
      event_type:validationHook.eventType,
      title: validationHook.eventName,
      description: validationHook.description,
      file: validationHook.eventImage,
      discipline: validationHook.discipline,
      distance: validationHook.distance || "",
      discipline_type: validationHook.disciplineType || "",
      approval_required:validationHook.approvalRequired,
      external_link:validationHook.externalLink,
    };
  };

  //validationHook.initData(props.stepData);


const handleDiscipline=(dataType)=>{
  //console.log("===asdsad===",dataType,dis)
  if(dataType==="Running"){
    setDisciplineData(typesOfRunning);
    setDistanceData(distanceTypeRunning);
  }
  if(dataType==="Swimming"){
    setDisciplineData(typesOfSwimming);
    setDistanceData(distanceTypeSwimming);
  }
  if(dataType==="Cycling"){
    setDisciplineData(typesOfCycling);
    setDistanceData(distanceTypeCycling);
  }
  if(dataType==="Athlons"){
    setDisciplineData(typesOfAthlons)
    setDistanceData(distanceTypeAthlons);
  // }
  }
}

useEffect(() => {

  handleDiscipline(props.stepData.discipline);
  setStepsData(props.stepData);
  console.log("USEEFFECT====",props.stepData);
   validationHook.initData(props.stepData);
  setNewImgData(props.stepData.banner)

}, [props.stepData.discipline,props.stepData,props.stepData.file,]);


  const handleNext = (e) => {
    e.preventDefault();
    if (validationHook.validateForm()) {
      console.log("++++++++++++",pushData());
      setLoading(true);
      publishEvent();
    }
  };

  const publishEvent = async () => {
    let tribeData  = {
       ...props.stepData1,
      ...props.stepData2,
      ...pushData(),
     
      
    };
    console.log("===tribe data==",tribeData);
    
    const formData = new FormData();
    Object.keys(tribeData).map((key, index) => {
      if (key === 'file'){
        let files =  tribeData [key];
        if( files && files.length >0){
          for (let i = 0; i < files.length; i++) {
            formData.append(`file`, files[i]);
          }
        }
        //formData.append(`file`, files);
      }else
      if (key === "upload_map"){
        let files =  tribeData [key];
        if (files && files.length > 0) {
          formData.append(key, files[0]);
        }
      }
      else{
         formData.append(key, tribeData [key]);
      }
      //console.log(tribeData[key]);
    });


    try {
      let response;
      let switchId="publish";
      if(props.isEdit){
        switchId = "update";
      }
      switch (switchId) {
        case "publish":
          response = await secureApiCall(createTribeUrl, "post", formData);
          setLoading(false);
          break;
        case "update":
          response = await secureApiCall(updateTribeUrl, "post", formData);
          setLoading(false);
           history.push("/my-tribes");
          break;
        default:
          setLoading(false);
          console.log("error");
      }
       console.log(response);
      if (!response.err) {
        props.nextStep(response.payload);
        toast.success(response.msg);
        setLoading(false);
      } else {

      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err.msg);
    }
  };




  const handleImageDelete =(e,position)=>{
    //setNewImgData(stepsData.banner);
    console.log(newImgData);
    let imgDel=newImgData[position];
    let postData = { img_del: imgDel };
    let returnValue= window.confirm("Do you Want to delete");
    if(returnValue){
      secureApiCall(deleteImageUrl, "post", JSON.stringify(postData), {
        "Content-type": "application/json",
      })
        .then((response) => {
          if (!response.err) {
            console.log(response);
            let delImg = newImgData.splice(position, 1);
            console.log("del img", delImg);
            toast.success(response.msg);
            setNewImgData([...newImgData]);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.success(err.msg);
        });
    }

  }

  return (
    <div className="steps-form create-steps">
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <label>Tribe name</label>
            <input
              className="form-control"
              maxLength={150}
              placeholder="Tribe title"
              defaultValue={stepsData.title}
              onChange={(e) => {
                validationHook.setEventName(e.target.value);
              }}
              type="text"
            />
            {validationHook.eventNameError && (
              <span className="text-danger">
                {validationHook.eventNameError}
              </span>
            )}
          </div>
          <div className="form-group">
            <label>Tribe Description</label>
            <textarea
              className="form-control"
              maxLength={500}
              placeholder="Tribe description"
              rows={6}
              defaultValue={stepsData.description}
              onChange={(e) => {
                validationHook.setDescription(e.target.value);
              }}
            />
            {validationHook.descriptionError && (
              <span className="text-danger">
                {validationHook.descriptionError}
              </span>
            )}
          </div>
          <div className="row">
            {props.isEdit === false ? (
              <div className="col-sm-6 form-group">
                <label>Upload Tribe Image</label>
                <label
                  className="form-control custom-file"
                  htmlFor="my-file-selector"
                >
                  <input
                    id="my-file-selector"
                    type="file"
                    name="uploadImage"
                    multiple
                    accept="image/*"
                    style={{ display: "none" }}
                    // defaultValue={}
                    onChange={(e) => {
                      let count=e.target.files.length;
                      setImageSelected(count)
                      validationHook.setEventImage(e.target.files);
                    }}
                  />
                   <span className id="upload-file-info">
                        {imageSelected == 1 || imageSelected==0
                          ?(imageSelected +" image selected")
                        :
                          (imageSelected+" images selected")
                        }
                    </span>
                  <i className="fas fa-upload" />
                </label>
                {validationHook.eventImageError && (
                  <span className="text-danger">
                    {validationHook.eventImageError}
                  </span>
                )}
              </div>
            ) : (
              <div className="col-sm-12 form-group">
                <label>Upload Tribe Image</label>
                <label
                  className="form-control custom-file"
                  htmlFor="my-file-selector"
                >
                  <input
                    id="my-file-selector"
                    type="file"
                    name="uploadImage"
                    multiple
                    accept="image/*"
                    style={{ display: "none" }}

                    onChange={(e) => {
                      validationHook.setEventImage(e.target.files);
                    }}
                  />
                  <span className id="upload-file-info">
                    {stepsData.banner && stepsData.banner
                      ? stepsData.banner.length + " Image"
                      :"Upload"
                      }
                  </span>
                  <i className="fas fa-upload" />
                </label>
                {validationHook.eventImageError && (
                  <span className="text-danger">
                    {validationHook.eventImageError}
                  </span>
                )}
                {/* {console.log(" stepsData.file ", stepsData.banner)} */}
                {stepsData && stepsData.banner ? (
                  stepsData.banner &&
                  stepsData.banner.map((ele, index) => (
                    <div
                      key={index}
                      style={{ display: "inline-block", marginLeft: "10px" }}
                    >
                      <img
                        src={process.env.REACT_APP_IMG_URL_ENDPOINT + ele.thumbnailImage}
                        style={{ height: "50px", width: "50px" }}
                      />
                      <br />
                      <button
                        onClick={(e) => {
                          handleImageDelete(e, index);
                        }}
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            )}

            {props.isEdit === true ? (
              <></>
            ) : (
              <div className="col-sm-6 form-group">
                <label>Discipline</label>
                <div className="select-wrap">
                  <select
                    className="form-control"
                    defaultValue={stepsData.discipline}
                    onChange={(e) => {
                      validationHook.setDiscipline(e.target.value);
                      handleDiscipline(e.target.value);
                    }}
                  >
                    <option>Select Discipline</option>
                    {disciple.map((val, index) => (
                      <option key={index} value={val.name}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {validationHook.disciplineError && (
                    <span className="text-danger">
                      {validationHook.disciplineError}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
{/*          
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Distance</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  defaultValue={stepsData.distance}
                  onChange={(e) => {
                    validationHook.setDistance(e.target.value);
                  }}
                >
                  <option>Select Distance</option>
                  {distanceData.map((value, index) => (
                    <option key={index}>{value.name}</option>
                  ))}
                </select>
                {validationHook.distanceError && (
                  <span className="text-danger">
                    {validationHook.distanceError}
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <label>Type</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  name="discipline_type"
                  onChange={(e) => validationHook.setDisciplineType(e.target.value)}
                  defaultValue={stepsData.event_type}
                >
                  {" "}
                  <option>Select Type</option>
                  {disciplineData.map((value, index) => (
                    <option key={index}>{value.name}</option>
                  ))}
                </select>
                {validationHook.disciplineTypeError && (
                  <span className="text-danger small">
                    {validationHook.disciplineTypeError}
                  </span>
                )}
              </div>
            </div>
          
          </div>
           */}
           {/* <div className="form-group">
            <label>External Link</label>
            <input
              className="form-control"
              placeholder="Enter external registration link (optional)"
              // placeholder="Distance (in miles)"
              // maxLength="3"
              defaultValue={stepsData.external_link}
              type="text"
              onChange={(e) => {
                validationHook.setExternalLink(e.target.value);
              }}
            />
          </div> */}
          <div className="row search-results">
            <div className="action">
            <span>
               <label className="switch">
                 <input type="checkbox" defaultChecked={stepsData.approval_required} onChange={(e)=>{validationHook.setApprovalRequired(e.target.checked?false:true)}} />
                 <span className="slider round" />
                </label>
               <div className="ml-10">Approval Needed</div>
             </span>
             {loading ? (
              <button className="btn btn-orange"><Spinner loading={loading} color="#fff"></Spinner></button>
              
            ) : (
              <button
                className="btn btn-orange"
                onClick={handleNext}
                type="submit"
              >
                {props.isEdit ? "Update" : "Publish" }
              </button>
            )}
            </div>
          </div>
         </div>
      </div>
    </div>
  );
};

export default Step;

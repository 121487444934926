import React, { useState } from "react";
import useValidationHook from "./validation";

const Step = (props) => {
  const [stepData, setStepData] = useState(props.stepData);
  const [next, setNext] = useState(null);
  const validationHook = useValidationHook(props.stepData);

  const handleNext = (e) => {
    e.preventDefault();
    setNext(true);
    if (validationHook.validateForm()) {
      handelNextClick();
    }
    setTimeout(() => {
      setNext(false);
    }, 1000);
  };
  const handelNextClick = () => {
    props.nextStep();
  };

  const handelPrevClick = (e) => {
    props.prevStep();
  };
  return (
    <div className="steps-form create-steps">
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <label>Location</label>
            <div className="select-wrap">
              <select
                className="form-control"
                defaultValue={stepData.location}
                onChange={(e) => {
                  validationHook.setLocation(e.target.value);
                }}
              >
                <option>Select Location</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              {validationHook.locationError && (
                <span className="text-danger">
                  {validationHook.locationError}
                </span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Region</label>
            <div className="select-wrap">
              <select
                className="form-control"
                defaultValue={stepData.region}
                onChange={(e) => {
                  validationHook.setRegion(e.target.value);
                }}
              >
                <option>Select Region</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              {validationHook.regionError && (
                <span className="text-danger">
                  {validationHook.regionError}
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <div className="form-group">
                <label>Start Date</label>
                <input
                  className="form-control"
                  placeholder="Date"
                  defaultValue={stepData.start_date}
                  type="date"
                  onChange={(e) => {
                    validationHook.setStartDate(e.target.value);
                  }}
                />
                {validationHook.startDateError && (
                  <span className="text-danger">
                    {validationHook.startDateError}
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <div className="form-group">
                <label>End Date</label>
                <input
                  className="form-control"
                  placeholder="Date"
                  defaultValue={stepData.end_date}
                  onChange={(e) => {
                    validationHook.setEndDate(e.target.value);
                  }}
                  type="date"
                />
                {validationHook.endDateError && (
                  <span className="text-danger">
                    {validationHook.endDateError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="action">
            <a
              href="#"
              className="icon-btn"
              onClick={(e) => {
                handelPrevClick();
              }}
            >
              <i className="ion-android-arrow-back" />
            </a>
            {next ? (
              <button className="btn">Loading...</button>
            ) : (
              <button
                className="btn btn-orange"
                onClick={handleNext}
                type="submit"
              >
                Next step
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <div className="row">
          <div className="col-md-4">
              <div className="form-group">
                  <label>Total distance</label>
                  <input className="form-control" placeholder="Enter total distance" value="" type="text">
              </div>
          </div>
          <div className="col-md-4">
              <div className="form-group">
                  <label>Average speed</label>
                  <input className="form-control" placeholder="Enter average speed" value="" type="text">
              </div>
          </div>
          <div className="col-md-4">
              <div className="form-group">
                  <label>Time</label>
                  <input className="form-control" placeholder="Enter time" value="" type="text">
              </div>
          </div>
      </div>
      <div className="row">
          <div className="col-md-4">
              <div className="form-group">
                  <label>Upload map file</label>
                  <select className="form-control">
                      <option>- Select the date -</option>
                      <option>Select Time Slot</option>
                  </select>
              </div>
          </div>
          <div className="col-md-4">
              <div className="form-group">
                  <label>Pit stop</label>
                  <input className="form-control" placeholder="Yes or No" value="" type="text">
              </div>
          </div>
          <div className="col-md-4">
              <div className="form-group">
                  <label>Visability</label>
                  <input className="form-control" placeholder="Private or public" value="" type="text">
              </div>
          </div>
      </div>
      <div className="row">
          <div className="col-md-12 text-center">
              <button className="btn btn-orange btn-border" type="submit">Go back</button> &nbsp;&nbsp;
              <button className="btn btn-orange" type="submit">Next step</button>
          </div>
      </div> */}
    </div>
  );
};

export default Step;

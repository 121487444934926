import { useState } from "react";

function useValidation(props) {
  // console.log("----props in validation------", props);
  const [eventType, setEventType] = useState(props.event_type);
  const [eventName, setEventName] = useState(props.title);
  const [description, setDescription] = useState(props.description);
  const [discipline, setDiscipline] = useState(props.discipline);
  const [eventImage, setEventImage] = useState(props.file);
  // const [distance, setDistance] = useState(props.distance);
  // const [disciplineType, setDisciplineType] = useState(props.discipline_type);
  const [approvalRequired,setApprovalRequired]=useState(props.approval_required)
  const [externalLink, setExternalLink] = useState(props.external_link);

  const [eventNameError, setEventNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [disciplineError, setDisciplineError] = useState(null);
  const [eventImageError, setEventImageError] = useState(null);
  // const [distanceError, setDistanceError] = useState(null);
  // const [disciplineTypeError, setDisciplineTypeError] = useState(null);

  const validEvent = (eventName) => {
    if (eventName.length > 4) {
      if (eventName) {
        setEventNameError(null);
        return true;
      } else {
        setEventNameError("Please enter tribe name");
        return false;
      }
    } else {
      if (eventName === "") {
        setEventNameError("Please enter tribe name");
      } else {
        setEventNameError("Event name is too short");
      }
    }
  };
  //   const valid
  const validateForm = (e) => {
    let error = true;
    if (!validEvent(eventName)) {
      error = false;
    }
    if (!description || description === "") {
      setDescriptionError("Please enter description");
      error = false;
    }else{
      setDescriptionError("");
    }
    if (!discipline || discipline === "") {
      setDisciplineError("Please select discipline");
      error = false;
    }else{
      setDisciplineError("");
    }
    if (!eventImage || eventImage === "") {
      //setEventImageError("Please upload an image");
      //error = false;
    }
    // if (!distance || distance === "") {
    //   setDistanceError("Please select distance");
    //   error = false;
    // }else{
    //   setDistanceError("");
    // }
    // if (!disciplineType || disciplineType === "") {
    //   setDisciplineTypeError("Please select discipline type");
    //   error = false;
    // }else{
    //   setDisciplineTypeError("");
    // }

    //setEventName(props.dataStepOne.title);
    return error;
  };
  const initData = ( props) =>{
    if(!props || Object.keys(props).length === 0 ){
      return
    }
    console.log("-----in init method------", props.title);
    setEventType(props.event_type);
    setEventName(props.title);
    setDescription(props.description);
    setDiscipline(props.discipline);
    setEventImage(props.file);
    // setDistance(props.distance);
    // setDisciplineType(props.discipline_type);
    setApprovalRequired(props.approval_required)
    setExternalLink(props.external_link);
  }


  return {
    eventType,
    eventName,
    description,
    discipline,
    eventImage,
    // distance,
    // disciplineType,
    approvalRequired,
    externalLink,
    setEventType,
    setEventName,
    setDescription,
    setDiscipline,
    setEventImage,
    setExternalLink,
    // setDistance,
    // setDisciplineType,
    setApprovalRequired,
    eventNameError,
    descriptionError,
    disciplineError,
    eventImageError,
    // distanceError,
    // disciplineTypeError,
    validateForm,initData
  };
}

export default useValidation;



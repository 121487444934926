import { useState } from "react";

function useValidation(props) {
  const [location, setLocation] = useState(props.location);
  const [region, setRegion] = useState(props.region);
  const [startDate, setStartDate] = useState(props.start_date);
  const [endDate, setEndDate] = useState(props.end_date);
  const [foodStop, setFoodStop] = useState(props.food_stop);

  const [locationError, setLocationError] = useState(null);
  const [regionError, setRegionError] = useState(null);
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [foodStopError, setFoodStopError] = useState(null);

  const validateForm = () => {
    let error = true;
    if (!location || location === "") {
      setLocationError("Please select location");
      error = false;
    }
    if (!region || region === "") {
      setRegionError("Please select region");
      error = false;
    }
    if (!startDate || startDate === "") {
      setStartDateError("Please select staring date");
      error = false;
    }
    if (!endDate || endDate === "") {
      setEndDateError("Please select ending date");
      error = false;
    }
    // if (!foodStop || foodStop === "") {
    //   setFoodStopError("Please select pit stop");
    //   error = false;
    // }
    return error;
  };

  return {
    location,
    region,
    startDate,
    endDate,
    foodStop,
    locationError,
    regionError,
    foodStopError,
    startDateError,
    endDateError,
    setLocation,
    setRegion,
    setStartDate,
    setEndDate,
    setFoodStop,
    validateForm,
  };
}
export default useValidation;

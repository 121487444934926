import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import Header from "../../components/header";
import PageHeading from "../../components/pageHeading";
import Navbar from "../../components/navbar";
import { secureApiCall } from "../../services/conf/apiCall";
import { useHistory } from "react-router-dom";

const phoneRegex=/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
const emailRegex=/^$|^(([^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+([._-][^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+)*)|(".+"))@((?!-)[^}@]+)(((\[)?[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(\])?)|(([a-zA-Z0-9-]+\.)+[a-zA-Z1]{2,}))$/

const supportSchema=yup.object().shape({
  name:yup.string().required("Please enter name").min("2","Name is too short").max("30","Name is too long"),
  phone:yup.string().required().matches(phoneRegex, 'Phone number is not valid. E.g. +44XXXXXXXXXX'),
  email:yup.string().required().matches(emailRegex, 'Email is not valid'),
  subject:yup.string().required("Please enter subject"),
  message:yup.string().required("Please enter message").min(20,"Minimum 20 characters").max(200,"Maximum 200 characters"),
})

const Support = () => {
  const {register, handleSubmit, errors}=useForm({
    resolver:yupResolver(supportSchema)
  });
  const history=useHistory();

  const [name,setName]=useState("");
  const [phone,setPhone]=useState("");
  const [email,setEmail]=useState("");
  const [subject,setSubject]=useState("");
  const [message,setMessage]=useState("");
  
  const supportUrl=process.env.REACT_APP_API_ENDPOINT+"/api/support";

  const onSubmit=(data)=>{

    secureApiCall(supportUrl,"post",JSON.stringify(data),{'Content-type':'application/json'})
    .then((res)=>{
      console.log("onSubmit -> res", res)
      toast.success(res.msg);
      history.push('/joined-events')
    }).catch((err)=>{
      toast.error(err.msg);
      console.log("onSubmit -> err", err)
    })
  }
  return (
    <div className="wrapper">
      <Header></Header>
      <Navbar></Navbar>  

      <div className="content-wrapper">
      <section className="content-header">
          <h1>
            <span>Support</span>
          </h1>
        </section>
        <section className="profilesetting">
              <div className="steps-form create-steps">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                 
                  <div className="col-md-6 col-md-offset-3">
                  <div className="col-md-12 columns">
                    <h6 className="borderBottom">please fill the support form below</h6>
                  </div>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control"
                        placeholder="Enter your name"
                        type="text"
                        name="name"
                        maxLength={50}
                        ref={register}
                        onSelectCapture={(e)=>{
                          // validationHook.setFirstName(e.target.value);
                        }}
                        onChange={(e) => {
                          // validationHook.setFirstName(e.target.value);
                        }}
                      />
                      <span className="text-danger small">
                        {errors.name?.message}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Phone</label>
                      <input
                        className="form-control"
                        placeholder="Enter phone number"
                        type="text"
                        name="phone"
                        ref={register}
                        onSelectCapture={(e)=>{
                          // validationHook.setFirstName(e.target.value);
                        }}
                        onChange={(e) => {
                          // validationHook.setFirstName(e.target.value);
                        }}
                      />
                      <span className="text-danger small">
                        {errors.phone?.message}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control"
                        placeholder="Enter email"
                        type="text"
                        name="email"
                        ref={register}
                        onSelectCapture={(e)=>{
                          // validationHook.setFirstName(e.target.value);
                        }}
                        onChange={(e) => {
                          // validationHook.setFirstName(e.target.value);
                        }}
                      />
                      <span className="text-danger small">
                        {errors.email?.message}
                      </span>
                    </div>                  
                    <div className="form-group">
                      <label>Subject</label>
                      <input
                        className="form-control"
                        placeholder="Enter Subject"
                        type="text"
                        name="subject"
                        ref={register}
                        maxLength={100}
                        onSelectCapture={(e)=>{
                          // validationHook.setFirstName(e.target.value);
                        }}
                        onChange={(e) => {
                          // validationHook.setFirstName(e.target.value);
                        }}
                      />
                      <span className="text-danger small">
                        {errors.subject?.message}
                      </span>
                    </div>               
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        className="form-control"
                        placeholder="Enter message"
                        rows={6}
                        maxLength={500}
                        name="message"
                        ref={register}
                        onChange={(e) => {
                          // validationHook.setDescription(e.target.value);
                        }}
                      />
                      <span className="text-danger small">
                        {errors.message?.message}
                      </span>
                    </div>   
                    <div className="form-group text-center" >
                      <input className="btn btn-secondary" type="submit" />
                    </div>              
                  </div>
                </div>
              </form>
              </div>
          </section>
      </div>
    </div>
  );
};

export default Support;

import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
const Spinner=(props)=>{

  return (
      <div className="sweet-loading" style={{padding:"0px 20px 0px 20px"}}>
        <ClipLoader
          size={25}
          color={props.color}
          loading={props.loading}
        />
      </div>
    );
  }
export default Spinner;
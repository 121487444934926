import React, { useEffect, useState,useRef } from "react";
import moment from "moment";

import "./eventList.scss";
import Footer from "../../components/footer";
import Header from "../../components/publicHeader";
import PageHeading from "../../components/pageHeading";
import { secureApiCall, ApiCall } from "../../services/conf/apiCall";
import defaultFeatureImage from "../../assets/img/feature_image.jpg";
import {
  getStartDateOfWeek,
  getTodayDate,
  getStartingDateOfMonth,
  restrictDatepicker
} from "../../helpers/utils"
import {
  regions,
  disciplines,
  typesOfAthlons,
  typesOfCycling,
  typesOfRunning,
  typesOfSwimming,
  distanceTypeCycling,
  distanceTypeRunning,
  distanceTypeSwimming,
  distanceTypeAthlons,
} from "../../constants/global";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfiniteScroll from 'react-infinite-scroll-component';


const EventList = (props) => {

  // const getTokenData=getUserData()
  const [eventData, setEventData] = useState([]);
  const [regionData,setRegionData]=useState(regions);
  const [disciple,setDisciple]=useState(disciplines);
  const[disciplineData,setDisciplineData]=useState([]);
  const [distanceData,setDistanceData]=useState([]);
  const [durationFilter,setDurationFilter]=useState("");
  const [eventTypeFilter,setEventTypeFilter]=useState("event");
  const [disciplineFilter,setDisciplineFilter]=useState("");
  const [disciplineTypeFilter,setDisciplineTypeFilter]=useState("");
  const [distanceFilter,setDistanceFilter]=useState("");
  const [alleventData, setAllEventData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setloading] = useState(false);
  const [searchText, setSearchText] = useState("");



  const [regionFilter,setRegionFilter]=useState("");
  const [icons,setIcons]=useState("");
  const [iconsValue,setIconsValue]=useState("");
  const getAllEventsUrl = process.env.REACT_APP_API_ENDPOINT + "/list-events";

  const [currentPage,setCurrentPage]=useState(1);
  const [pageSize,setPageSize]=useState(12);
  const [pages,setPages]=useState(10);

console.log('searchText',searchText);
  
  const validDateFormat = (d)=>{
    return  [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');
  }

  const todaysDateStr =validDateFormat(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDateVisible, setIsDateVisible] = useState(false);


  useEffect(()=>{
    if(durationFilter === 'custom'){
      setIsDateVisible(true)
    }else{
      setIsDateVisible(false);
      setStartDate("");
      setEndDate("")
    }

  },[durationFilter])

  useEffect( () => {
    handleEventFilters();
  }, []);

  
  useEffect( () => {
    setloading(true)
    handleEventFilters();
  }, [searchText,startDate,endDate,disciplineTypeFilter,durationFilter,disciplineFilter,disciplineData,distanceFilter,distanceData,regionFilter]);

  // function paginate(array, page_size, page_number) {
  //   // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  //   return array.slice((page_number - 1) * page_size, page_number * page_size);
  // }
  
  const paginate = (arr, size) =>{
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size)
      let page = acc[idx] || (acc[idx] = [])
      page.push(val)
  
      return acc
    }, [])
  }
  
  const handleEventFilters=()=>{
      secureApiCall(`
        ${getAllEventsUrl}?duration=${durationFilter}&event_type=${eventTypeFilter}&discipline=${disciplineFilter}&region=${regionFilter}&disciplineType=${disciplineTypeFilter}&distance=${distanceFilter}&startDate=${startDate}&endDate=${endDate}&query=${searchText}
          `, "get")
      .then((response) => {
        console.log(response);
        setCurrentPage(0)
        const pagedItems = paginate(response.payload,pageSize);
        setPages(pagedItems)
         setEventData(pagedItems[0]);
         setAllEventData(response.payload);
         setloading(false)
      })
      .catch((err) => {
        console.log(err);
        setloading(false)
      }); 
  }

  let eventType=eventData && eventData.map((val)=>val.event_type);


  const handleDiscipline=(dataType)=>{
    //console.log("===asdsad===",dataType,dis)
    if(dataType==="Running"){
      setDisciplineData(typesOfRunning);
      setDistanceData(distanceTypeRunning);
    }
    if(dataType==="Swimming"){
      setDisciplineData(typesOfSwimming);
      setDistanceData(distanceTypeSwimming);
    }
    if(dataType==="Cycling"){
      setDisciplineData(typesOfCycling);
      setDistanceData(distanceTypeCycling);
    }
    if(dataType==="Athlons"){
      setDisciplineData(typesOfAthlons)
      setDistanceData(distanceTypeAthlons);
    // }
    }
  }

  const fetchMoreData = () => {
    const pageIndex = currentPage + 1;
    const currenteventData =  pages[pageIndex];
    if(currenteventData){
      setEventData([...eventData,...currenteventData]);
      setCurrentPage(pageIndex);
      if(currenteventData.length < pageSize){
          setHasMore(false);
      }  
    }else{
      setHasMore(false);

    }
    
}


  return (
    <>
      <Header></Header>
      <PageHeading title="Find An Event"></PageHeading>
      <section className="main bg-transparent">
        <div className="steps-area">
          <div className="steps-form">
            <div className="row">
              <div className="col-xs-12 col-12">
                <ul className="filter-options">
                  <li>
                    <div className="form-group">
                      <label>Select Date </label>
                      <div className="select-wrap">
                        <i className="fas fa-calendar" />
                        <select name="duration" id className="form-control" onChange={(e)=>setDurationFilter(e.target.value)}>
                          <option  value="">&nbsp;Select one</option>
                          <option value="today" >Today</option>
                          <option value="this_week">This week</option>
                          <option value="this_month">This Month</option>
                          <option value="custom">Date Range</option>
                          <option selected value={'all'}>All</option>
                        </select>

                      </div>
                    </div>



                   
                  </li>
                  {/* <li>
                    <div className="form-group">
                      <label>Event type</label>
                      <div className="select-wrap">
                      {eventTypeFilter==='event' &&
                        <i className="fas fa-flag" />
                      }
                      {eventTypeFilter ==='training' &&
                       <i className="fas fa-flag-checkered" />
                      }
                        <select name="event_type" id className="form-control"  onChange={(e)=>setEventTypeFilter(e.target.value)}>
                          <option value="event" selected>Event</option>
                          <option value="training" >Training</option>
                        </select>
                      </div>
                    </div>
                  </li> */}
                  <li>
                    <div className="form-group">
                      <label>Discipline</label>
                      <div className="select-wrap">
                      {disciplineFilter == ''  && <i className="fas fa-trophy"></i> }
                      {disciplineFilter == 'Running'  && <i className="fas fa-running" /> }
                      {disciplineFilter == 'Athlons' && <i className="fas fa-football-ball" />}
                      {disciplineFilter == 'Cycling' && <i className="fas fa-biking"/>}
                      {disciplineFilter == 'Swimming' && <i className="fas fa-swimmer" />}
                        <select 
                          name="discipline" 
                          className="form-control" 
                          onChange={(e)=>{
                            setDisciplineFilter(e.target.value);
                            handleDiscipline(e.target.value);
                            }
                          }>
                          <option selected value="">&nbsp;All</option>
                          {disciple && disciple.map((discipline,index)=>(
                            <option key={index}>{discipline.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label>Discipline Type</label>
                      <div className="select-wrap">
                      { <i className="fas fa-bars"></i> }
                        <select 
                          name="discipline" 
                          className="form-control" 
                          onChange={(e)=>{
                            setDisciplineTypeFilter(e.target.value);
                            }
                          }>
                          <option selected value="">&nbsp;All</option>
                          {disciplineData && disciplineData.map((discipline,index)=>(
                            <option key={index}>{discipline.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label>Distance</label>
                      <div className="select-wrap">
                      {<i className="fas fa-route"></i> }
                        <select 
                          name="discipline" 
                          className="form-control" 
                          onChange={(e)=>{
                            setDistanceFilter(e.target.value);}
                          }>
                          <option selected value="">&nbsp;All</option>
                          {distanceData && distanceData.map((distance,index)=>(
                            <option key={index}>{distance.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label>Region</label>
                      <div className="select-wrap">
                        <i className="fas fa-map-marker-alt"/>
                        <select name="region" id className="form-control"  onChange={(e)=>setRegionFilter(e.target.value)}>
                          <option value="">&nbsp;Select one</option>
                          {regionData&&regionData.map((region,index)=>(
                            <option key={index}>{region.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>

             


                    <div  className="form-group search-input">
                      <label className="empty-label">&nbsp;</label>
                      <div  className="">
                        <span className="fas fa-search search-icon" /> 

                        <input type="text" id="search" className="form-control" onChange={(e)=>setSearchText(e.target.value)} style={{width:'200px',height:'40px'}} placeholder="Search" name="search" />

                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
       {
         isDateVisible &&
                
         <form style={{marginBottom:'20px'}} class="form-inline">
 
         <div className="form-group">
             <label>From Date</label>
             {/* <DatePicker className="form-control"></DatePicker> */}
             <input
               className="form-control"
               // placeholder="Date"
               value={startDate}
               type="date"
               min={restrictDatepicker(new Date())}
               onChange={(e) => {
                 setStartDate(validDateFormat(new Date(e.target.value)))
               }}
             />
             
           </div>

           <div style={{marginLeft:'20px'}} className="form-group">
             <label>To Date</label>
             {/* <DatePicker className="form-control"></DatePicker> */}
             <input
               className="form-control"
               // placeholder="Date"
               value={endDate}
               type="date"
               min={restrictDatepicker(new Date())}
               onChange={(e) => {
                 setEndDate(validDateFormat(new Date(e.target.value)))

               }}
             />
             
           </div>
</form>
       }

                   
            </div>
            <div className="row">
              <div className="col-xs-12">
              {alleventData && alleventData.length==0?
                  <div className="search-title">
                   { loading  ?  '   Loading Data' : 'No events found' }
                 
                  </div>
                :
                  <div className="search-title">
                    Showing {alleventData && alleventData.length} Results
                  </div>
                }
              </div>
            </div>
         
            <InfiniteScroll
    dataLength={eventData && eventData.length}
    next={fetchMoreData}
    hasMore={true}
    loader={<h4> { loading ?  'Loading...' : null } </h4>}
  >

<div className="row">
{eventData &&
                 eventData.map((value, index) => (
                  <div className="col-xs-12 col-sm-6 col-md-4" key={index}>
                    <a
                      // href={`/event/${value._id}`}
                      href={`/event/${value.slug}`}
                      className="event-box"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <div className="event-img">
                     {value.banner.length===0 ?
                          <img
                            src={defaultFeatureImage} 
                            className="img-responsive"
                            alt=""
                          />
                          :  
                            <img
                            src={process.env.REACT_APP_IMG_URL_ENDPOINT+value.banner[0].featureImage } 
                            className="img-responsive"
                            alt=""
                          />
                        }
                      </div>
                      <div className="event-description">
                        <h5>
                          <div className="ellipsis-text" style={{display:'inline-block'}}>
                                {value.title}
                            </div>
                          </h5>
                        <div>
                          <ul>
                            <li>
                              <i className="fas fa-calendar" />
                              {moment(value.date).format('ll')}
                            </li>
                            <li>
                              {value.discipline === "Running"? 
                                <span>
                                  <i className="fas fa-running" />
                                  {value.discipline}
                                </span>
                                :value.discipline==="Swimming"?
                                <span>
                                  <i className="fas fa-swimmer" />
                                  {value.discipline}
                                </span>
                                :value.discipline==="Cycling"?
                                <span>
                                  <i className="fas fa-biking" />
                                   {value.discipline}
                                </span>
                                :value.discipline==="Athlons"?
                                <span>
                                  <i className="fas fa-football-ball" />
                                  {value.discipline}
                                </span>:<></>
                              }
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt " />
                             
                              <span className="ellipsis-text" style={{display:'inline-block'}}>
                              {value.location.substr(0,30)}...
                              </span>
                            </li>
                            <li>
                            {/* <div className="totalUsers"> */}
                              { <>
                                  <i className="fas fa-users" style={{color:"#ff5100"}}></i>
                                  <span style={{paddingRight:10}}>Joined ({value.total_joined})</span> 
                                </>
                              }
                              { <>
                                  {/* <i className="fas fa-users" style={{color:"#ff5100"}}></i> */}
                                  <span>Interested ({value.total_interested})</span> 
                                </>
                              }
                              {/* { value.total_joined==0 ? <div></div> : <div>Total Joined ({value.total_joined})</div> }
                              { value.total_interested==0 ? <div></div> : <div>Total Interested ({value.total_interested})</div> } */}
                            {/* </div> */}

                            </li>
                          </ul>
                          
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
</div>
    </InfiniteScroll>
             
          </div>
        </div>
      </section>
      
      <Footer event_type="event"></Footer>
    </>
  );
};
export default EventList;

import React, { useEffect, useState } from 'react'
import nl2br from 'nl2br';
import moment from "moment";
import { Markup } from 'interweave';
import { useParams,useHistory } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import { ToastContainer, toast } from "react-toastify";


import Header from "../../components/header";
import Navbar from "../../components/navbar";
import  eventImage from "../../assets/img/sample-photos/event1.png"
import userImage from "../../assets/img/follower3.png"
import defaultCoverImage from "../../assets/img/cover_image.jpg";
import defaultUser from "../../assets/img/default-user-3.jpg";
import { secureApiCall } from "../../services/conf/apiCall";
import {dateFormat,setDataToCSV} from "../../helpers/utils"


const MyEventDetail = (props) => {
    let params=useParams();
    const history=useHistory();

    const getEvent=process.env.REACT_APP_API_ENDPOINT+`/api/list-events/${params.id}`;
    const getJoinedUsers=process.env.REACT_APP_API_ENDPOINT+`/api/event-joined-users/${params.id}`;
    const getInterestedUsers=process.env.REACT_APP_API_ENDPOINT+`/api/event-interested-users/${params.id}`;
    const getPendingUsers=process.env.REACT_APP_API_ENDPOINT+`/api/pending-event-invites/${params.id}`;
    const friendRequestUrl = process.env.REACT_APP_API_ENDPOINT + "/api/friends-request";
    const getFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/my-friends";
    const getMyTribesUrl=process.env.REACT_APP_API_ENDPOINT+"/api/my-tribes";
    const duplicateEventsUrl = process.env.REACT_APP_API_ENDPOINT + "/api/duplicate-event";
    const deleteEventUrl = process.env.REACT_APP_API_ENDPOINT + "/api/delete-event";

    const [myEventData,setMyEventData]=useState([]);
    const [joinedUsersData,setJoinedUsersData]=useState([]);
    const [interestedUsers,setInterestedUsers]=useState([]);
    const [eventPendingInvites,setEventPendingInvites]=useState([]);
    const [friend,setFriends]=useState([]);
    const [eventData,setEventData]=useState([])

    const isFriend = (user_id)=>{
      return !friend.filter(a=>a.friend_id === user_id._id).length > 0
    }


    useEffect(()=>{
        secureApiCall(getEvent,'get')
        .then((response)=>{
            console.log(response)
            if(!response.err){
                setMyEventData([response.payload]);
            }
        }).catch((err)=>{
            console.log(err);
        })

        secureApiCall(getJoinedUsers,'get')
        .then((response)=>{
            console.log(response);
            if(!response.err){
                setJoinedUsersData(response.payload);
                // setDataToCSV(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        });

        secureApiCall(getInterestedUsers,'get')
        .then((response)=>{
            console.log(response);
            if(!response.err){
                setInterestedUsers(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        });
        
        secureApiCall(getPendingUsers,'get')
          .then((response)=>{
              console.log(response);
              if(!response.err){
                setEventPendingInvites(response.payload);
              }
          }).catch((err)=>{
              console.log(err);
          });

          secureApiCall(getMyTribesUrl,'get')
          .then((response)=>{
              console.log(response)
              if(!response.err){
                  setEventData(response.payload);
              }
          }).catch((err)=>{
              console.log(err);
          })
          secureApiCall(getFriendUrl,"get")
          .then((response)=>{
            console.log(response);
            if(!response.err){
              setFriends(response.payload);
            }
          }).catch((err)=>{console.log(err)})
      

    },[])

    const sendFriendRequest = (friend_id, position) => {
      let res=window.confirm("Do you want to add friend");
      if(res){
        let requestData = {
          friend_id: friend_id,
        };
        secureApiCall(friendRequestUrl, "post", JSON.stringify(requestData), {
          "Content-type": "application/json",
        })
          .then((response) => {
            if (!response.err) {
               interestedUsers.splice(position, 1);
               setInterestedUsers([...interestedUsers]);
               toast.success( "Friend request raise");
            }else{
              toast.error(response.msg);
            }
            console.log("--response--", response);
          })
          .catch((err) => {
             toast.error("something went wrong ");
            console.log(err);
          });
        }else{
          console.log("cancel add friend",res);
        }
      };

      const duplicateEvent = () => {

        let res=window.confirm("Do you want to Duplicate ?");
        if(res){

          const {_id} = myEventData[0];
          let requestData = {
            eventId: _id,
          };
          secureApiCall(duplicateEventsUrl, "post", JSON.stringify(requestData), {
            "Content-type": "application/json",
          })
            .then((response) => {
              if (!response.err) {
                //eventRes
                const {eventRes} = response.payload;
                console.log('eventRes slug',eventRes.slug);
                history.push(`/edit-event/${eventRes.slug}`);

              }else{
                toast.error(response.msg);
              }
              console.log("--response--", response);
            })
            .catch((err) => {
               toast.error("something went wrong ");
              console.log(err);
            });
          
        }
     
        };


        
      const deleteEvent = () => {

        let res=window.confirm("Do you want to Delete ?");
        if(res){

          const {_id} = myEventData[0];
          let requestData = {
            event_id: _id,
          };
          secureApiCall(deleteEventUrl, "post", JSON.stringify(requestData), {
            "Content-type": "application/json",
          })
            .then((response) => {
              if (!response.err) {
                //eventRes
                const {eventRes} = response.payload;
                history.push(`/my-events`);

              }else{
                toast.error(response.msg);
              }
              console.log("--response--", response);
            })
            .catch((err) => {
               toast.error("something went wrong ");
              console.log(err);
            });
          
        }
     
        };

   
    return (
      <div>
        <Header></Header>
        <Navbar></Navbar>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          {myEventData && myEventData.map((event,index)=>(
            <section key={index} className="content-header">
              <h1>
                <span>{event.title}</span>
              </h1>
              <span className="ser_span">
                <input type="text" name="search" placeholder="search here" />
                <button className="ser_in_btn">
                  <i className="fas fa-search" />
                </button>
              </span>
            </section>
          ))}
          <section className="content">
            <div className="row">
              <div className="col-md-9">
                {myEventData && myEventData.map((event,index)=>(
                  <div key={index} className="event-detailbox">
                  <div className="event-box">
                  {event.banner.length===0?
                        <span
                        className="event-img"
                        style={{ backgroundImage: `url(${defaultCoverImage})` }}
                        >
                        <span className="participants">
                          <span>{event.total_joined} </span>
                          Participants Confirmed
                        </span>
                        </span>
                 
                    :
                      <span
                      className="event-img"
                      style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL_ENDPOINT+event.banner[0].coverImage})`}}
                    >
                      <span className="participants">
                        <span>{event.total_joined} </span>
                        Participants Confirmed
                      </span>
                    </span>
                }

                    <span className="event-description">
                      <h5>{event.owner_name}</h5>
                      <p>You created on {moment(event.createdAt).format('ll')}</p>
                      <div className="btnbox">
                        <a
                          href={`/edit-event/${event.slug}`}
                          className="btn btn-dark"
                        >
                          Edit
                        </a>


                        <a
                        onClick={()=>deleteEvent(1)}
                          className="btn btn-dark"
                        >
                          Delete
                        </a>

                        <a
                          href={`/edit-event/${event.slug}/invite`}
                          className="btn btn-dark"
                        >
                          Invite
                        </a>

                        <a
                        onClick={()=>duplicateEvent(1)}
                          className="btn btn-dark"
                        >
                          Duplicate
                        </a>
                        {/* <a href="#" className="btn btn-orange-small">Interested</a> */}
                      </div>
                    </span>
                  </div>
                  <div className="eventcommonbox">
                    <h4>Description</h4>
                    <div className="detaildesc">
                      <p>
                        The {event.discipline} event will
                        start on {moment(event.date).format('ll')} at{" "}
                        {event.start_time}{" "}
                      </p>
                      <p><Markup content={nl2br(event.description) }/></p>
                    </div>
                  </div>
                    <div key={index} className="join-interested-list">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="eventcommonbox">
                          <h4>Joined List
                             {joinedUsersData && joinedUsersData.length === 0?<></>:
                              <CSVLink data={joinedUsersData && joinedUsersData} filename={"Joined_users_list.csv"} > <i style={{marginLeft:"10px"}} className="fas fa-download"/></CSVLink>
                              }
                             </h4>
                          {/* <h5><i className="fas fa-download"></i></h5> */}
                          <ul className="list-ul">

                         {joinedUsersData && joinedUsersData.map((user,index)=>(
                            <li key={index}>
                              <div className="singlebox">
                                <a  style={{justifyContent:'space-between',display:'flex'}} href="#">
                                  <img
                                    src={defaultUser}
                                    alt
                                    className="featured-img"
                                  />
                                  <span className="clientname">
                                    {user.user_name}
                                  </span>
                                  <span>
                                
                                    {isFriend(user.user_id) ? 

                                    <span
                                  
                                    onClick={(e) => {
                                      sendFriendRequest(user.user_id._id);
                                      //setFriendName(`${val.first_name} ${val.last_name}`);
                                      //setStatus("pending");
                                    }}
                                  >
                                  <i style={{marginLeft:"10px"}}  className="fas fa-plus"/>

                                  </span>  
        
                                    : null }
                                  </span>
                                </a>
                                <span className="usr-icon">
                                  {/* <a href="#">
                                    <i className="fas fa-times user-remove-icon" />
                                    &nbsp;
                                  </a> */}
                                  {/* <a href="#">
                                    <i className="fas fa-user-plus" />
                                  </a> */}
                                </span>
                              </div>
                            </li>
                          ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="eventcommonbox">
                          <h4>Interested List</h4>
                          <ul className="list-ul">
                          {interestedUsers && interestedUsers.map((user,index)=>(
                            <li key={index}>
                              <div className="singlebox">
                                <a href="#">
                                  <img
                                    src={defaultUser}
                                    alt
                                    className="featured-img"
                                  />
                                  <span className="clientname">
                                    {user.user_name}
                                  </span>
                                </a>
                                <span className="usr-icon">
                                  
                                  {/* <a href="#">
                                    <i className="fas fa-user-plus" onClick={()=>sendFriendRequest(user.user_id,index)} />
                                  </a> */}
                                </span>
                              </div>
                            </li>
                          ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="eventcommonbox">
                          <h4>Friend List</h4>
                          <ul className="list-ul">
                          {friend && friend.map((user,index)=>(
                            <li key={index}>
                              <div className="singlebox">
                                <a href="#">
                                  <img
                                    src={defaultUser}
                                    alt
                                    className="featured-img"
                                  />
                                  <span className="clientname">
                                    {user.friend_name}
                                  </span>
                                </a>
                                <span className="usr-icon">
                                  
                                  {/* <a href="#">
                                    <i className="fas fa-user-plus" onClick={()=>sendFriendRequest(user.user_id,index)} />
                                  </a> */}
                                </span>
                              </div>
                            </li>
                          ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="eventcommonbox">
                          <h4>Tribe List</h4>
                          <ul className="list-ul">
                          {eventData && eventData.map((tribe,index)=>(
                            <li key={index}>
                              <div className="singlebox">
                                <a href="#">
                                  <img
                                    src={defaultUser}
                                    alt
                                    className="featured-img"
                                  />
                                  <span className="clientname">
                                    {tribe.title}
                                  </span>
                                </a>
                                <span className="usr-icon">
                                  
                                  {/* <a href="#">
                                    <i className="fas fa-user-plus" onClick={()=>sendFriendRequest(user.user_id,index)} />
                                  </a> */}
                                </span>
                              </div>
                            </li>
                          ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="join-interested-list">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="eventcommonbox">
                          <h4>Pending Invites List</h4>
                          <ul className="list-ul">
                          {eventPendingInvites && eventPendingInvites.map((invite,index)=>(
                            <li key={index}>
                              <div className="singlebox">
                                <a href="#">
                                  <img
                                    src={defaultUser}
                                    alt
                                    className="featured-img"
                                  />
                                  <span className="clientname">
                                    {invite.recipient_name}
                                  </span>
                                </a>
                                {/* <span className="usr-icon">
                                  <a href="#">
                                    <i className="fas fa-times user-remove-icon" />
                                    &nbsp;
                                  </a>
                                  <a href="#">
                                    <i
                                      className="fas fa-check"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </span> */}
                              </div>
                            </li>
                          ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ))}
              </div>
            {myEventData && myEventData.map((event,index)=>(
              <div key={index} className="col-md-3">
                <div className="eventtable eventcommonbox">
                  <h4>Event Detail</h4>
                  <div className="eventdetail-table">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th style={{ width: "50%" }}>Location</th>
                          <td style={{ width: "50%" }}>
                            {event.location}
                          </td>
                        </tr>
                        <tr>
                          <th>Discipline</th>
                          <td>{event.discipline}</td>
                        </tr>
                        <tr>
                          <th>Start Time</th>
                          <td>{event.start_time}</td>
                        </tr>
                        <tr>
                          <th>End Time</th>
                          <td>{event.end_time}</td>
                        </tr>
                        <tr>
                          <th>Total Distance</th>
                          <td>{event.total_distance} Miles</td>
                        </tr>
                        <tr>
                          <th>Average Speed</th>
                          <td>{event.average_speed} MPH</td>
                        </tr>
                        <tr>
                          <th>Total Time</th>
                          <td>{event.time} Minutes</td>
                        </tr>
                        <tr>
                          <th>Pit Stop</th>
                          <td>{event.food_stop}</td>
                        </tr>
                        <tr>
                          <th>Visibility</th>
                          <td>{event.visibility}</td>
                        </tr>
                      </tbody>
                    </table>
                    <p></p>
                  </div>
                 
                </div>
              </div>
            ))}
            </div>
          </section>
        </div>
      </div>
    );
}

export default MyEventDetail;

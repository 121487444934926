import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import userPic from "../../assets/img/user-pic.png";
import userTwo from "../../assets/img/sample-photos/user2-160x160.jpg";
import { getUserData } from "../../helpers/utils";
import { secureApiCall } from "../../services/conf/apiCall";

import defaultUser from "../../assets/img/default-user-3.jpg";
// import defaultUser from "../../assets/img/default_user.png"
import moment from "moment";
import "./navbar.scss";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Navbar = (props) => {
  const history = useHistory();
  const getTokenData = getUserData();
  const recentActivityUrl=process.env.REACT_APP_API_ENDPOINT+"/api/get-recent-activities";
  const getProfileImageUrl=process.env.REACT_APP_API_ENDPOINT+"/api/get-profile-image";
  const notificationUrl = process.env.REACT_APP_API_ENDPOINT+"/api/notification";  
  
  const [recentActivityData,setRecentActivityData]=useState([]);
  const [profileImage,setProfileImage]=useState([]);
  const [countNotifications,setCountNotifications]=useState(0);
  useEffect(()=>{
    secureApiCall(recentActivityUrl,"get").then((response)=>{
      console.log("---recent activity---",response.payload);
      if(!response.err){
        setRecentActivityData(response.payload);
      }
    }).catch((err)=>{
      console.log(err);
    })

    secureApiCall(getProfileImageUrl,"get").then((response)=>{
      console.log("---profileImage---",response.payload);
      if(!response.err){
        setProfileImage(response.payload);
      }
    }).catch((err)=>{
      console.log(err);
    })

    secureApiCall(notificationUrl,"get").then((response)=>{
      // console.log("---notification data---",response.payload.length);
      if(!response.err){
        setCountNotifications(response.payload.length);
      }
    }).catch((err)=>{
      console.log(err);
    })
  },[])

  const handelLogout = () => {
    localStorage.removeItem("authToken");
    history.push("/login");
  };

  const gotoProfile = () => {
    history.push("/profile");
  };

  return (
    <aside className="main-sidebar">
      {/* sidebar: style can be found in sidebar.less */}
      <section className="sidebar">
        {/* Sidebar user panel */}
        <div className="user-panel">
          <div onClick={gotoProfile} style={{cursor:'pointer'}} className="pull-left image">
          {!profileImage.profile_pic? 
            <img src={defaultUser} className="img-circle" alt="User Image" />
            :
            <img src={process.env.REACT_APP_IMG_URL_ENDPOINT+ (profileImage.profile_pic && profileImage.profile_pic.thumbnailImage)} className="img-circle" alt="User Image" />
          }
            </div>
          <div className="pull-left info">
            <p onClick={gotoProfile} style={{cursor:'pointer'}}>
              {getTokenData &&
                getTokenData.first_name + " " + getTokenData.last_name}
            </p>
            <span
              style={{ color: "#ff5100", cursor: "pointer" , ' paddingLeft': "15px",marginLeft:'15px'}}
              onClick={() => handelLogout()}
            >
              Logout
            </span>
          </div>
        </div>
        {/* sidebar menu: : style can be found in sidebar.less */}
        <ul className="sidebar-menu">
          <li className="header">MAIN</li>
          {/* <li className="active">
            <a href="/">
              <ion-icon name="ios-contact" className="icon" />
              <span>Home</span>
            </a>
          </li> */}
          <li className="active">
            <a href="/events">
            <i className="fas fa-calendar-alt"></i>
            &emsp;<span>Events</span>
            </a>
          </li>
          <li className="">
            <a href="/trainings">
              
            <i className="fas fa-walking"></i>
            &emsp;<span>Trainings</span>
            </a>
          </li>
         

          <li>
            <a href="/joined-events">
            <i className="fas fa-calendar-check"></i>
            &emsp;<span>Joined Events / Training</span>
            </a>
          </li>
         
          <li>
            {/* <a href="/trainings"> */}
            <a href="/my-events">
            <i className="fas fa-calendar-week"></i>
            &emsp;<span>My Events / Training</span>
            </a>
          </li>
         

          <li>
            <a href="/create-event">
            <i className="fas fa-plus-circle"></i>
            &emsp;<span>Add Event / Training</span>
            </a>
          </li>
          <li>
            <a href="/my-friends">
              <i className="fas fa-user-friends"></i>
              &emsp;<span>View My Friends </span>
            </a>
          </li>

          <li>
            <a href="/add-friends">
            <i className="fas fa-user-plus"></i>
            &emsp;<span>Add Friends</span>
            </a>
          </li>
           <li className="">
            <a href="/tribes">
            <i className="fas fa-briefcase"></i>
            &emsp;<span>Tribes</span>
            </a>
          </li>
          <li>
            {/* <a href="/trainings"> */}
            <a href="/my-tribes">
            <i className="fas fa-briefcase"></i>
            &emsp;<span>My Tribes</span>
            </a>
          </li>
          <li>
            <a href="/create-tribe">
            <i className="fas fa-plus-circle"></i>
            &emsp;<span>Add Tribe</span>
            </a>
          </li>
          
          <li>
            <a href="/profile">
            <i className="fas fa-cog"></i>
            &emsp;<span>Account Settings </span>
            </a>
          </li>
          <li>
          {countNotifications===0?
            <a href="#" onClick={()=>alert("You have no notification.")}>
              <i className="fas fa-bell"></i>
              &emsp;<span>Notifications</span>
            </a>
          :
            <a href="/notification">
              <i className="fas fa-bell"></i>
                &emsp;<span>Notifications</span>
                &ensp;<span className="notification_number" >{countNotifications}</span>
            </a>
          }
          </li>
          {/* <li>
            <a href="/tribes">
            <i className="fas fa-briefcase"></i>
              &ensp;<span>Tribes</span>
            </a>
          </li> */}
          <li>
            <a href="/support">
            <i className="far fa-question-circle"></i>
            &emsp;<span>Support</span>
            </a>
          </li>
        </ul>
        
        <div className="empty-space" />
        <ul className="recent-activities">
          <li className="header">RECENT ACTIVITIES</li>
        {recentActivityData && recentActivityData.map((activity,index)=>(
          <li key={index}>
            <a href="#">
              <div className="pull-left">
                <img src={defaultUser} className="img-circle" alt="User Image" />
              </div>
              <h4>
                <span>{activity.sender_user}</span> {activity.message} 
                {activity.payload? 
                  activity.payload.event_name?
                    <> {activity.payload.event_name}</>:<></>:<></>
                  }
                {/* <span>{activity.payload && activity.payload.event_name || activity.payload.tribe_name}</span>. */}
              </h4>
              <p>
                {moment(activity.createdAt).startOf('minute').fromNow()}
              </p>
            </a>
          </li>
        ))}
        </ul>
      </section>
      {/* /.sidebar */}
    </aside>
  );
};

export default Navbar;

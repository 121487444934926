import { useState } from "react";

function useValidation(props) {
  const [location, setLocation] = useState(props.location);
  const [region, setRegion] = useState(props.region);
  const [country, setCountry] = useState(props.country);
  const [date, setDate] = useState(props.date);
  const [startTime, setStartTime] = useState(props.start_time);
  const [endTime, setEndTime] = useState(props.end_time);
  

  const [locationError, setLocationError] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [startTimeError, setStartTimeError] = useState(null);
  const [endTimeError, setEndTimeError] = useState(null);

  const validateForm = () => {
    let error = true;
    if (!location || location === "") {
      setLocationError("Please select location");
      error = false;
    }
    if (!country || country === "") {
      setCountryError("Please select country");
      error = false;
    }
    if (!date || date === "") {
      setDateError("Please select date");
      error = false;
    }
    if (!startTime || startTime === "") {
      setStartTimeError("Please select staring time");
      error = false;
    }
    if (!endTime || endTime === "") {
      setEndTimeError("Please select ending time");
      error = false;
    }
    return error;
  };

  return {
    location,
    region,
    country,
    date,
    startTime,
    endTime,
    locationError,
    countryError,
    dateError,
    startTimeError,
    endTimeError,
    setLocation,
    setRegion,
    setCountry,
    setDate,
    setStartTime,
    setEndTime,
    validateForm,
  };
}
export default useValidation;

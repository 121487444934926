import React from "react";
import isAuthenticated  from '../../router/isAuthenticated';
import  { Redirect } from 'react-router-dom'

export const secureApiCall = async (url, method, formData, requestHeader) => {
  let headers = processHeader(requestHeader, true);

  try {
    let response = "";
    if (method === "get") {
      response = await fetch(url, {
        method: method,
        headers: headers,
      })
  } else {
      response = await fetch(url, {
        method: method,
      headers: headers,
        body: formData,
      });
    }
    let status_code=await response.status;
    // console.log("--status code---",status_code)
    if(status_code===401){
      localStorage.removeItem("authToken");
      return <Redirect to="/login"></Redirect>
    }
    return await response.json();

  } catch (err) {
    console.log(err);
    return err;
    // Handle errors here
  }
};

const processHeader = ( requestHeader, isProtected )=>{
  let header ={};
  if( isProtected){
     header['Authorization'] = isAuthenticated();
  }

  if (requestHeader){
    let headerObjectArray=Object.keys(requestHeader);
    if (headerObjectArray.length > 0) {
      headerObjectArray.map((key, index) => {
        header[key] = requestHeader[key];
      });
    }
  }
  return header;
}


export const ApiCall = async (url, method,formData, requestHeader) => {
  
  let headers=processHeader(requestHeader)
  // console.log(url,headers)
  try {
    let response = "";
    if( method === "get" ){
      response = await fetch(url, {
        method: method,
        headers: headers,
      })

      // console.log("response===",response)
    }else{
      response = await fetch(url, {
        method: method,
        headers: headers,
        body: formData,
      });
    }

    return await response.json();
  } catch (err) {
    console.log(err);
    return err;
    // Handle errors here
  }
};

//export  secureApiCall;

import React, { useState, useEffect,useCallback } from "react";
import { useStore } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Markup } from 'interweave';
import nl2br from "nl2br";
import "react-toastify/dist/ReactToastify.css";
import "./profilePage.scss";
import Cropper from 'react-easy-crop'

import Header from "../../components/header";
import Navbar from "../../components/navbar";
import { secureApiCall } from "../../services/conf/apiCall";
import useValidationHook from "./profilePageValidation";
import { getUserData } from "../../helpers/utils";
import header from "../../components/header";
import {ukCountries} from "../../constants/global";
import Spinner from "../../components/spinner";
import defaultUser from "../../assets/img/default-user-3.jpg"

const ProfilePage = (props) => {

  const url = process.env.REACT_APP_API_ENDPOINT + "/api/profile";
  const passwordUpdateUrl =process.env.REACT_APP_API_ENDPOINT + "/api/profile/update-password";
  const fetchDataUrl = process.env.REACT_APP_API_ENDPOINT +"/api/get-user-profile";
  const uploadProfileImageUrl=process.env.REACT_APP_API_ENDPOINT+"/api/upload-profile-image" 
  const deleteProfileImageUrl=process.env.REACT_APP_API_ENDPOINT+"/api/delete-profile-image" 

  const [userData, setUserData] = useState([]);
  const validationHook= useValidationHook([]);
  const [showUploadBtn,setShowUploadBtn]=useState(false);
  const [countriesData, setCountriesData] = useState(ukCountries);
  const [uploading,setUploading]=useState(false);
  const [showDeleteBtn,setShowDeleteBtn]=useState(false);
  const [isImage,setIsImage]=useState(false);
  const [imageData,setImageData]=useState(null);
 
  // react-easy-crop
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [cropRect,setCropRect]=useState();
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCropRect(croppedAreaPixels);
  }, [])

  //end

  const imageEndpoint=process.env.REACT_APP_IMG_URL_ENDPOINT;

  useEffect(() => {
    secureApiCall(fetchDataUrl, "get")
      .then((response) => {
      console.log("ProfilePage -> response", response.payload)
        if( !response.err){
          validationHook.initData(response.payload);
          setUserData(response.payload);
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showDeleteBtn,uploading]);

  let formData = new FormData();
  const [loading, setLoading] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  
  const pushPasswordData = async () => {
    let passwordData={
    password:validationHook.newPassword,
  };

    secureApiCall(passwordUpdateUrl, "post" ,JSON.stringify(passwordData),{'Content-type':'application/json'})
      .then((response) => {
        console.log(response);
        setPasswordCheck(false);
        toast.success("Success");
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const pushData = async () => {
    console.log(validationHook.profilePhoto);

    let profileData = {
      first_name: validationHook.firstName,
      last_name: validationHook.lastName,
      display_name: validationHook.displayName || `${validationHook.firstName} ${validationHook.lastName}`,
      email: validationHook.email,
      website_url: validationHook.website,
      phone_no: validationHook.phone || "",
      user_bio: validationHook.bio,
      address1: validationHook.address1,
      address2: validationHook.address2,
      pincode: validationHook.pincode,
      city: validationHook.city,
      provinces: validationHook.provinces,
      country: validationHook.country,
    };

    console.log("--push data--",profileData);

    Object.keys(profileData).map((key, index) => {
      console.log(profileData[key]);
      formData.append(key, profileData[key]);
    });
    
    
    console.log(profileData);
    console.log("ProfilePage -> formData", formData)

    try {
      let response = await secureApiCall(url, "post", formData);
      console.log(response);
      setLoading(false);
      setShowUploadBtn(false);
      toast.success("Success");

    } catch (err) {
        toast.success("Something went wrong");
      console.log(err);
    }
  };
  const uploadProfileImage=async()=>{

  console.log("ProfilePage -> cropRect", cropRect)
    setUploading(true);
    let profileData={
      file: validationHook.profilePhoto[0],
    }
    Object.keys(profileData).map((key, index) => {
      console.log(profileData[key]);
      formData.append(key, profileData[key]);
    });
    for (const key in cropRect ) {
       formData.append(key,cropRect[key]);
      }
    try {
      let response = await secureApiCall(uploadProfileImageUrl, "post", formData);
      console.log(response);
      setShowUploadBtn(false);
      setUploading(false);
      setIsImage(false);
      toast.success(response.msg);
      if(response.data){
        setUploading(false);
        setIsImage(false);
        toast.error(response.data);
      }
    } catch (err) {
      setUploading(false);
      setIsImage(false);  
      toast.error(err.data);
      // toast.error("Something went wrong");
      console.log(err);
    }
  }

  const handleProfileUpdate = (e) => {
    e.preventDefault();
    if (validationHook.validateForm()) {
      setLoading(true);
      pushData();
    }
  };
  const handlePasswordUpdate = (e) => {
    e.preventDefault();
    if (validationHook.validatePasswordForm()) {
      pushPasswordData();
      setPasswordCheck(true);
    }
  };

  var loadFile = function(event) {
  console.log("loadFile -> event", event.target.files);
    var output = document.getElementById('display-image');
    output.src = URL.createObjectURL(event.target.files[0]);
      setIsImage(true);
      setImageData(output.src);
    output.onload = function() {
      URL.revokeObjectURL(output.src) // free memory 
    }
  };

const handleChangeImage=()=>{
      document.getElementById('changeImage').click();
}

const handleDeleteProfileImage=(profilePicId)=>{
  setShowDeleteBtn(true);
  secureApiCall(`${deleteProfileImageUrl}?image_id=${profilePicId}`,'get')
  .then((response)=>{ 
    setShowDeleteBtn(false);
    toast.success(response.msg);
    console.log("handleDeleteProfileImage -> response", response)
   
  })
  .catch((err)=>{
    toast.error(err.msg);
    console.log("handleDeleteProfileImage -> err", err)
  })
}

  return (
    <>    
    <div className="wrapper">
    
      <Header></Header>
      <Navbar></Navbar>
      <form id="profile">

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>
              <span>Profile</span>
            </h1>
          </section>
          <section className="profilesetting">
            <div className="setting-form">
              <div className="setting-form-inner">
                <div className="row">
                  <div className="col-md-12 columns">
                    <h6 className="borderBottom">Personal Details</h6>
                  </div>
                  
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label>First Name<span className="text-danger"><sup>*</sup></span></label>
                      <input
                        className="form-control"
                        placeholder="enter your first name.."
                        type="text"
                        name="firstName"
                        defaultValue={userData && userData.first_name}
                        onSelectCapture={(e)=>{
                          validationHook.setFirstName(e.target.value);
                        }}
                        onChange={(e) => {
                          validationHook.setFirstName(e.target.value);
                        }}
                      />
                      {validationHook.firstNameError && (
                        <span className="text-danger small">
                          {validationHook.firstNameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label>Last Name<span className="text-danger"><sup>*</sup></span></label>
                      <input
                        className="form-control"
                        placeholder="enter your last name.."
                        type="text"
                        name="lastName"
                        defaultValue={userData && userData.last_name}
                        onSelectCapture={(e)=>{
                          validationHook.setLastName(e.target.value);
                        }}
                        onChange={(e) => {
                          validationHook.setLastName(e.target.value);
                        }}
                      />
                      {validationHook.lastNameError && (
                        <span className="text-danger small">
                          {validationHook.lastNameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label>Display Name</label>
                      <input
                        className="form-control"
                        placeholder="enter your display name"
                        type="text"
                        name="displayName"
                        defaultValue={userData && userData.display_name}
                        onChange={(e) => {
                          validationHook.setDisplayName(e.target.value);
                        }}
                        onSelectCapture={(e) => {
                          validationHook.setDisplayName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      {/* <label>Upload Profile Photo </label> */}
                      <input
                        className="form-control"
                        type="file"
                        name="profile_pic"
                        accept="image/x-png, image/jpeg"
                        style={{'display':'none'}}
                        id="changeImage"
                        defaultValue={userData.profile_pic && userData.profile_pic.original}
                        onChange={(e)=>{
                          setShowUploadBtn(true);
                          validationHook.setProfilePhoto(e.target.files);
                          loadFile(e);
                        }
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="setting-form-inner">
                <div className="row">
                  <div className="col-md-12 ">
                    <h6 className="borderBottom">Contact Details</h6>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label>Email ID </label>
                      <input
                        className="form-control"
                        placeholder="enter your email address.."
                        type="text"
                        readOnly
                        autoComplete="off"
                        name="email"
                        value={userData && userData.email || {}}
                        onChange={(e) => {
                          validationHook.setEmail(e.target.value);
                        }}
                      />
                      {validationHook.emailError && (
                        <span className="text-danger small">
                          {validationHook.emailError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label>Website URL  (Optional) </label>
                      <input
                        className="form-control"
                        placeholder="enter your website url.."
                        type="text"
                        name="website"
                        defaultValue={userData && userData.website_url}
                        onChange={(e) => {
                          validationHook.setWebsite(e.target.value);
                        }}
                        onSelectCapture={(e) => {
                          validationHook.setWebsite(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label>Phone No  (Optional)</label>
                      <input
                        className="form-control"
                        placeholder="enter your phone number"
                        type="text"
                        name="phone"
                        maxLength="16"
                        onSelectCapture={(e) => {
                          console.log(e.target.value);
                          validationHook.setPhone(e.target.value);
                        }}
                        defaultValue={userData && userData.phone_no}
                        onChange={(e) => {
                          console.log(e.target.value);
                          validationHook.setPhone(e.target.value);
                        }}
                      />
                      {/* {validationHook.phoneError && (
                        <span className="text-warning small">
                          {<Markup content={nl2br(validationHook.phoneError)}></Markup>}
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <h6 className="borderBottom">About Me  (Optional) </h6>
                    </div>
                  <div className="col-md-12 ">
                    <div className="form-group">
                      <label>Bio Description </label>
                      <textarea
                        className="form-control"
                        name="bio"
                        defaultValue={userData && userData.user_bio}
                        onChange={(e) => {
                          validationHook.setBio(e.target.value);
                        }}
                        onSelectCapture={(e) => {
                          validationHook.setBio(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting-form-inner">
                <div className="row">
                  <div className="col-md-12 ">
                    <h6 className="borderBottom">Address Information  (Optional)</h6>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="form-group">
                      <label>Address Line 1 </label>
                      <input
                        className="form-control"
                        placeholder="enter your Address.."
                        type="text"
                        name="line_address1"
                        defaultValue={userData.address && userData.address.address1}
                        onChange={(e) => {
                          validationHook.setAddress1(e.target.value);
                        }}
                        onSelectCapture={(e) => {
                          validationHook.setAddress1(e.target.value);
                        }}
                      />  
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="form-group">
                      <label>Address Line 2 </label>
                      <input
                        className="form-control"
                        placeholder="enter your Address.."
                        type="text"
                        name="line_address2"
                        defaultValue={userData.address && userData.address.address2}
                        onChange={(e) => {
                          validationHook.setAddress2(e.target.value);
                        }}
                        onSelectCapture={(e) => {
                          validationHook.setAddress2(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                    <div className="col-sm-6 ">
                    <div className="form-group">
                      <label>City </label>
                      <input
                        className="form-control"
                        placeholder="enter your City.."
                        type="text"
                        name="address_city"
                        defaultValue={userData.address && userData.address.city}
                        onChange={(e) => {
                          validationHook.setCity(e.target.value);
                        }}
                        onSelectCapture={(e) => {
                          validationHook.setCity(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="form-group">
                      <label>Region</label>
                      <input
                        className="form-control"
                        placeholder="enter your State.."
                        type="text"
                        name="address_state"
                        defaultValue={userData.address && userData.address.provinces}
                        onChange={(e) => {
                          validationHook.setProvinces(e.target.value);
                        }}
                        onSelectCapture={(e) => {
                          validationHook.setProvinces(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="form-group">
                      <label>Postcode</label>
                      <input
                        className="form-control"
                        placeholder="enter your postcode"
                        type="text"
                        name="address_zip"
                        defaultValue={userData.address && userData.address.pincode}
                        onChange={(e) => {
                          validationHook.setPincode(e.target.value);
                        }}
                        onSelectCapture={(e) => {
                          validationHook.setPincode(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="form-group">
                      <label>Country </label>
                      <div className="select-wrap">  
                        <select
                        className="form-control"
                        value={validationHook.country}
                        onChange={(e) => {
                          validationHook.setCountry(e.target.value);
                        }}
                      >
                        <option>Select Country</option>
                        {countriesData.map((value,index)=>(
                          <option key={index}>{value.name}</option>
                        ))}
                      </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="setting-form-inner">
                      
              <div className="setting-form-inner">
              {loading ? (
                // <button className="change-btn change-btn-secondary" type="button">
                  <Spinner loading={loading} color="#ff5100"></Spinner>      
                  // </button>
                      ) : (
                        <button
                          className="change-btn change-btn-secondary"
                          type="button"
                          onClick={handleProfileUpdate}
                        >
                          Update Profile
                        </button>
                      )}
              </div>  
                    </div>
                  </div>
                </div>
              </div>
              <br/><br/>
              <div className="setting-form-inner">
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="borderBottom">Update Password</h6>
                  </div>
                  <div className="col-sm-4 ">
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        className="form-control"
                        placeholder="enter your new password.."
                        type="password"
                        autoComplete="off"
                        name="newPassword"
                        onChange={(e) => {
                          validationHook.setNewPassword(e.target.value);
                        }}
                      />
                      {validationHook.newPasswordError && (
                        <span className="text-danger small">
                          {validationHook.newPasswordError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4 ">
                    <div className="form-group">
                      <label>Retype Password</label>
                      <input
                        className="form-control"
                        placeholder="enter your new password.."
                        type="password"
                        name="retypePassword"
                        autoComplete="off"
                        onChange={(e) => {
                          validationHook.setRetypePassword(e.target.value);
                        }}
                      />
                      {validationHook.retypePasswordError && (
                        <span className="text-danger small">
                          {validationHook.retypePasswordError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4">
                  <div className="form-group">
                  {passwordCheck ? (
                    <div style={{marginTop:"40px"}}>
                      <Spinner loading={passwordCheck} color="#ff5100" ></Spinner>
                      </div>
                    ) : (
                      <button
                        // className="new-btn new-btn-secondary"
                        className="password-btn change-btn-secondary"
                        type="button"
                        onClick={handlePasswordUpdate}
                      >
                        Update Password
                      </button>
                    )}
                    </div>
                  </div> 
                </div>
                <div className="col-md-12">
                    <h6 className="borderBottom">User Profile Image</h6>
                  </div>
                <div className="row">
                  <div className="col-md-6">
                    {userData && !userData.profile_pic?
                    <span >
                        <img 
                          id="display-image"
                          style={{width:"200px"}}
                          src={defaultUser}
                      ></img>
                    </span>
                    :
                    <>
                    <span>
                        <img 
                            id="display-image"
                            style={{width:"250px",height:'250px'}}
                            src={userData.profile_pic && imageEndpoint+ userData.profile_pic.thumbnailImage}
                        ></img>
                      
                    </span>
                    <span>
                    { isImage?
                      <Cropper
                              style={{containerStyle:{width:'250px',height:'250px'}}}
                              image={imageData}
                              crop={crop}
                              zoom={zoom}
                              aspect={300 / 300}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                          />
                      :<></>
                    }
                    </span>
                    </>
                  } 
                  </div>
                  
                  </div>
                    {/* <div>
                          <button type="button" className="btn btn-primary" onClick={()=>getCropData()}>Crop</button>
                    </div> */}
                    <div className="row">
                      <div className="col-md-12">
                        <input type="button" value="Change" onClick={handleChangeImage} className="change-btn change-btn-secondary" /> 
                        {!userData.profile_pic && !showDeleteBtn?
                          <></>
                          :
                          <input type="button" value="Delete" onClick={()=>{handleDeleteProfileImage(userData.profile_pic && userData.profile_pic._id)}} className="change-btn change-btn-secondary" /> 
                        }
                        {showUploadBtn?
                          uploading? 
                          <input type="button" disabled value="Please wait..." className="change-btn change-btn-secondary" />
                            :
                              <input type="button" value="Upload" onClick={uploadProfileImage} className="change-btn change-btn-secondary" /> 
                          :
                          <></>
                        }
                      </div>
                    </div>
              </div>
            </div>
          </section>
        </div>
      </form>
    </div>
  </>
 );
};

export default ProfilePage;

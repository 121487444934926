import React, { useEffect, useState } from 'react'
import Header from "../../components/header";
import Navbar from "../../components/navbar";
import { secureApiCall } from "../../services/conf/apiCall";
import  eventImage from "../../assets/img/sample-photos/event5.png"
import "../viewFriendsPage/viewFriendsPage.scss";
import defaultFeatureImage from "../../assets/img/feature_image.jpg";
import moment from "moment";

const MyEvents = (props) => {

    const getMyEventsUrl=process.env.REACT_APP_API_ENDPOINT+"/api/my-events";
    const [myEventsData,setMyEventsData]=useState([]);   
    const [eventNameFilter,setEventNameFilter]=useState("");
    const [eventDateFilter,setEventDateFilter]=useState(""); 
    const [isActive,setIsActive] = useState(true);   

    let addClass="active-class";

    useEffect(()=>{
        secureApiCall(`${getMyEventsUrl}?event_type=event`,'get')
        .then((response)=>{
            console.log("respone - event", response)
            if(!response.err){
                setMyEventsData(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        })
    },[])

    const handleEventFilters=(eventType)=>{
   
        secureApiCall(`${getMyEventsUrl}?event_name=${eventNameFilter}&event_date=${eventDateFilter}&event_type=${eventType?eventType:"event"}`,'get')
        .then((response)=>{
            console.log("--filtered data",response)
            if(!response.err){
                setMyEventsData(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        })

    }
    return (
        <div>
        <Header></Header>
        <Navbar></Navbar>
         <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header-new">
            <h1>
                <span className={isActive ? addClass : "margin_right"} style={{cursor:'pointer'}} onClick={()=>{
                    setIsActive(true);
                    handleEventFilters("event");
                }}> Events </span>
                <span className={isActive ? "margin_right"  : addClass } style={{cursor:'pointer'}} onClick={()=>{
                    setIsActive(false);
                    handleEventFilters("training");
                }} > Training </span>
            </h1>
        </section>
        <section className="filters">
            <div className="container m-0">
            <div className="form-horizontal">
            <div className="form-group">
                <label htmlFor="filter-by" className="col-lg-1 col-md-3 control-label">
                    Filter By
                </label>
                <div className="col-lg-3 col-md-3 ">
                    <input
                    id="filter-by"
                    type="text"
                    className="form-control input-dashed"
                    placeholder="Enter Name"
                    onChange={(e)=>setEventNameFilter(e.target.value)}
                    />
                </div>
                {/* <div className="col-lg-3 col-md-3">
                    <div className="input-group date" id="datepicker">
                    <input 
                        type="date"
                        className="form-control" 
                        placeholder="Select date" 
                        onChange={(e)=>setEventDateFilter(e.target.value)}
                    />
                    <div className="input-group-addon">
                    </div>
                    </div>
                </div> */}
                <div className="col-lg-3 col-md-3 " onClick={handleEventFilters}>
                    <label className="col-lg-1 col-md-3 control-label">&nbsp;</label>
                    <button className="new-btn new-btn-secondary" style={{borderRadius:"20px", marginTop:"2px"}}>
                        <span className="fas fa-search" /> Search
                    </button>
                </div> 
                </div>

            </div>
            </div>
        </section>
        <section className="content">
            <div className="row">
           {myEventsData && myEventsData.length > 0 ?
            myEventsData.reverse().map((value,index)=>(
                    <div key={index} className="col-lg-4 col-sm-6 col-xs-12">
                    <a href={`/my-event-detail/${value.slug}`} className="event-box">
                    {value.banner.length===0?
                        <div
                            className="event-img"
                            style={{ backgroundImage: `url(${defaultFeatureImage})` }}
                        >
                            <span className="participants">
                            <span>{value.total_joined} </span>
                            Participants Confirmed
                            </span>
                        </div>
                    :
                        <div
                            className="event-img"
                            style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL_ENDPOINT+value.banner[0].featureImage})` }}
                        >
                            <span className="participants">
                            <span>{value.total_joined} </span>
                            Participants Confirmed
                            </span>
                        </div>
                        
                    }
                    <div className="event-description"  style={{minWidth:"330px"}}>
                        <h5>
                            <div className='ellipsis-text'>
                            {value.title.substr(0,22)}...

                            </div>
                            </h5>
                        <p>You created on {moment(value.createdAt).format('ll')}.</p>
                    </div>
                    </a>
                </div>
                // :<></>
                ))
            :
            <div>No Data Available</div>
            }
            </div>
        </section>
        </div>
        </div>
    )
}

export default MyEvents

import React, { useEffect, useState }  from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

import {secureApiCall} from "../../services/conf/apiCall";
import "./footer.scss";

const Footer =(props)=>{
  let url="";
  const d=new Date();
  const history= useHistory();
  const [newsData,setNewsData]=useState([]);
  const [eventsTagsData,setEventsTagsData]=useState([]);
  const [latestEventData,setLatestEventData]=useState([]);
  const [eventsData,setEventsData]=useState([]);
  const [email,setEmail]=useState("");
  const [emailError,setEmailError]=useState(null);
  const [activeTagIndex,setActiveTagIndex]=useState(null); // default: 0


  const newsPublicUrl=process.env.REACT_APP_API_ENDPOINT+"/list-news";
  const getEventsTags=process.env.REACT_APP_API_ENDPOINT+"/events-tags";
  const searchByTagUrl=process.env.REACT_APP_API_ENDPOINT+"/list-events";
  const getAllTribesUrl = process.env.REACT_APP_API_ENDPOINT + "/list-all-tribes";
  const getLatestUpdatesUrl=process.env.REACT_APP_API_ENDPOINT+"/subscribe-to-tribeathlon"
  
  useEffect(()=>{
    secureApiCall(newsPublicUrl,"get")
    .then((response)=>{
      //console.log(response);
      if(!response.err){
        setNewsData(response.payload);
      }
    })
    
    secureApiCall(getEventsTags,"get")
    .then((response)=>{
      //console.log("--event tags--",response.payload);
      if(!response.err){
        setEventsTagsData(response.payload);
      }
    })
    // if(props.event_type==="tribe"){
    //   url=getAllTribesUrl;
    // }else{
    //   url=`${searchByTagUrl}?event_type=${props.event_type}`;
    // }
    secureApiCall(`${searchByTagUrl}?event_type=event`,"get")
    .then((response)=>{
      //console.log("-->",response);
      if(!response.err){
        setLatestEventData(response.payload);
      }
    }).catch(err=>{
      //console.log("err==>",err);
    })
  },[])

  const handleSearchByTags=(tag)=>{
    history.push(`/tag/${tag}`);
    secureApiCall(`${searchByTagUrl}?event_type=${props.event_type}&tags=${tag}`,"get")
    .then((response)=>{
      //console.log(response);
      if(!response.err){
        setEventsData(response.payload);
      }
    }).catch(err=>{
      //console.log("err==>",err);
    })
  }

  const validateEmail = (userEmail) => {
    //console.log("validateEmail -> userEmail", userEmail)
    if (
      userEmail &&
      /^$|^(([^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+([._-][^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+)*)|(".+"))@((?!-)[^}@]+)(((\[)?[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(\])?)|(([a-zA-Z0-9-]+\.)+[a-zA-Z1]{2,}))$/i.test(
        userEmail.trim() || ""
      )
    ) {
      setEmailError(null);
      return true;
    } else {
      setEmailError("Please enter a valid email address");
      return false;
    }
  };
  const validLoginData = () => {
    let error = true;
    if (!validateEmail(email)) {
      error = false;
    }
    return error;
  };

  const handleSubscribe=()=>{
    if(validLoginData()){
      secureApiCall(`${getLatestUpdatesUrl}?email=${email}`,'get')
      .then((response)=>{
        //console.log("handleSubscribe -> response", response)
        if(!response.err){
          toast.success(response.msg);
        }else{
          toast.warn(response.msg);
        }
        
      })
      .catch((err)=>{
        //console.log("handleSubscribe -> err", err)
      })
    }else{
      //console.log("Please check email");
    }
  }

  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="footer-widget">
              <span className="widget_title">Contact us</span>
              <div className="footer-address">
                {/* 5 Beatrice Road.
                <br />
                Liecester, LE3 9FH
                <br /> */}
                {/* <a href="tel:01162512303">0116 2512 303</a> */}
                {/* <br /> */}
                <a href="mailto:events@tribeathlon.co.uk">
                  events@tribeathlon.co.uk
                </a>
              </div>
              
              {/* <div className="social_icons">
                <ul>
                  <li>
                    <a href="#">
                      <i className="ion-social-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-pinterest" />
                    </a>
                  </li>tribeathlon
                  <li>
                    <a href="#">
                      <i className="ion-email" />
                    </a>
                  </li>
                </ul>
              </div>
               */}
              <a className="footer-logo" href="#">
                <img src={require("../../assets/img/logo.jpg")} />
              </a>
              <p className="copyright">© {d.getFullYear()}, TRIBEathlon</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footer-widget">
              {/* <span>Add pull through the latest events</span> */}
              {latestEventData && !latestEventData.length== 0 ?
                <>
                <span className="widget_title">Latest Events</span>
                <ul className="latest-posts">

               {latestEventData && latestEventData.slice(0,3).map((value,index)=>(
                <li key={index}>
                    <a style={{display:'flex'}} href={props.event_type==='tribe'?`/tribes/${value.slug}`:`/event/${value.slug}`}>
                      <span className="post-date">
                        {/* {props.event_type==='tribe'?
                        <>
                          {moment(value.createdAt).format('D')}<span>{moment(value.createdAt).format('MMM')}</span>
                        </>
                        : */}
                        <>
                          {moment(value.date).format('D')}<span>{moment(value.date).format('MMM')}</span>
                        </>
                        {/* } */}
                        </span>
                      <div className="post-title ellipsis-text">
                        {value.title}
                        <span className="post-comments">{value.discipline}</span>
                      </div>
                    </a>
                </li>
                ))}

              </ul>
              </>
              :
                <span className="widget_title">Loading Latest Events</span>
              }
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footer-widget">
              
              <span className="widget_title">Tags</span>
              <div className="footer-tags">
                {eventsTagsData && eventsTagsData.map((tags,index)=>(
                <span
                  key={index} 
                  // href={`/tag/${tags.tag_name}`} 
                  // className={`tag-single ${index == activeTagIndex ? "active" : "inactive" }` } 
                  className={`tag-single ${index == activeTagIndex ? "active" : "inactive" }` } 
                  style={{cursor:'pointer'}}
                  onClick={()=>{
                    handleSearchByTags(tags.tag_name);
                    setActiveTagIndex(index);
                  }}
                >
                  {tags.tag_name}
                </span>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footer-widget">
              <span className="widget_title">Sign up for updates</span>
              <p>
                Register your email address so you can access the community
                forum and get the latest updates and offers
              </p>
                <div className="form_row">
                  <input 
                    placeholder="Email address" 
                    type="email" 
                    onChange={(e)=>{
                      validateEmail(e.target.value); 
                      setEmail(e.target.value)
                      }
                    } 
                  />
                  <input type="submit" defaultValue="Sign up" onClick={()=>{handleSubscribe()}} />
                </div>
                {emailError && (
                    <p className="error-label text-danger"> {emailError} </p>
                  )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useEffect, useState } from "react";
import useValidationHook from "./validation";
import {useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  disciplines,
  typesOfAthlons,
  typesOfCycling,
  typesOfRunning,
  typesOfSwimming,
  distanceTypeCycling,
  distanceTypeRunning,
  distanceTypeSwimming,
  distanceTypeAthlons,
} from "../../../constants/global";
import DialogBox from "../../dialog";
import image from "../../../assets/img/events_bg.jpg"
import "./step.scss"
import { render } from "@testing-library/react";
import { secureApiCall } from "../../../services/conf/apiCall";


const Step = (props) => {
  let params=useParams();
  const [next, setNext] = useState(null);
  const history = useHistory();
  const [stepsData, setStepsData] = useState(props.stepData);
  const validationHook = useValidationHook(props.stepData);
  const [disciplineData,setDisciplineData]=useState([]);
  const [distanceData,setDistanceData]=useState([]);
  const [disciple,setDisciple]=useState(disciplines);
  const [newImgData,setNewImgData]=useState([]);
  const [type,setType]=useState(props.isEdit===true?props.stepData.event_type:"Event");
  const [imageSelected,setImageSelected]=useState(0)
  const [imageReceived,setImageReceived]=useState(validationHook.eventImage && validationHook.eventImage.length)
  const eventId=props.stepData.event_id;
  const deleteImageUrl=process.env.REACT_APP_API_ENDPOINT+`/api/delete-event-image/${eventId}`;

  // setDisciplineData(props.stepData.discipline);
  // setDistanceData((props.stepData.)
  //console.log("===============---------------",props.stepData);

  const pushData = () => {
    // console.log("validationHook.eventImage,", validationHook.eventImage);
     return {
      event_type:validationHook.eventType.toLowerCase(),
      title: validationHook.eventName,
      description: validationHook.description,
      file: validationHook.eventImage,
      discipline: validationHook.discipline,
      distance: validationHook.distance,
      discipline_type: validationHook.disciplineType,
    };
  };

  //validationHook.initData(props.stepData);


const handleDiscipline=(dataType)=>{
  //console.log("===asdsad===",dataType,dis)
  if(dataType==="Running"){
    setDisciplineData(typesOfRunning);
    setDistanceData(distanceTypeRunning);
  }
  if(dataType==="Swimming"){
    setDisciplineData(typesOfSwimming);
    setDistanceData(distanceTypeSwimming);
  }
  if(dataType==="Cycling"){
    setDisciplineData(typesOfCycling);
    setDistanceData(distanceTypeCycling);
  }
  if(dataType==="Athlons"){
    setDisciplineData(typesOfAthlons)
    setDistanceData(distanceTypeAthlons);
  // }
  }
}

// useEffect(() => {
//   validationHook.initData(props.stepData);

// }, []);

useEffect(() => {
  handleDiscipline(props.stepData.discipline);
  setStepsData(props.stepData);
  console.log("USEEFFECT====",props.stepData);
   validationHook.initData(props.stepData);
  setNewImgData(props.stepData.banner)
  setType(props.isEdit===true?props.stepData.event_type:"Event");

}, [props.stepData.discipline,props.stepData,props.stepData.file]);


  const handleNext = (e) => {
    e.preventDefault();
    //setNext(true);

    if (validationHook.validateForm()) {
      console.log("++++++++++++",pushData());
      props.nextStep(pushData());
      //handelOnClickNext();
    }
    // setTimeout(() => {
    //   setNext(false);
    // }, 1000);
  };
  const handleImageDelete =(e,position)=>{
    //setNewImgData(stepsData.banner);
    
    console.log(newImgData);
    let imgDel=newImgData[position];
    let postData = { img_del: imgDel };
    let returnValue= window.confirm("Do you Want to delete");
    if(returnValue){
      secureApiCall(deleteImageUrl, "post", JSON.stringify(postData), {
        "Content-type": "application/json",
      })
        .then((response) => {
          if (!response.err) {
            console.log(response);
            let delImg = newImgData.splice(position, 1);
            console.log("del img", delImg);
            toast.success(response.msg);
            setNewImgData([...newImgData]);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.success(err.msg);
        });
    }

  }


  // Change css of event and training button
  const [isActive,setIsActive]=useState(false);
  let cssAdd="change-btn change-btn-secondary-active";
  let cssChange="change-btn change-btn-secondary";

  return (
    <div className="steps-form create-steps">
      <div className="row">
      {props.isEdit === false ? 
        <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
          <label>Select Type</label>
            <div className="event_training_checkbox">
              
              <input
                className={isActive? cssChange : cssAdd}
                type="button"
                id="radioButton1"
                defaultChecked
                value="Event"
                defaultValue={stepsData.event_type}
                name="event/training"
                
                onClick={(e) => {
                  validationHook.setEventType(e.target.value)
                  // history.push("/create-event");
                  setType("Event");
                  setIsActive(false);
                }}
              />
              {/* <label
                className="form-check-label text_size padding_right"
                for="radioButton1"
              >
                Event
              </label> */}
            </div>
            <div
              className="event_training_checkbox">
              <input
                className={isActive? cssAdd : cssChange}
                type="button"
                id="radioButton2"
                defaultValue={stepsData.event_type}
                value="Training"
                name="event/training"
                onClick={(e) => {
                  validationHook.setEventType(e.target.value)
                  setType("Training");
                  setIsActive(true);
                  // history.push("/create-training");
                }}
              />
              {/* <label className="form-check-label text_size" for="radioButton2">
                Training
              </label> */}
            </div>
          </div>
        </div>
        :
        <></>  
      }
        <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <label>{type} name</label>
            <input
              className="form-control"
              maxLength={150}
              placeholder={`${type} Name`}
              defaultValue={stepsData.title}
              onChange={(e) => {
                validationHook.setEventName(e.target.value);
              }}
              type="text"
            />
            {validationHook.eventNameError && (
              <span className="text-danger">
                {validationHook.eventNameError}
              </span>
            )}
          </div>
          <div className="form-group">
            <label>{type} Description</label>
            <textarea
              className="form-control"
              placeholder={`${type} description`}
              rows={6}
              maxLength={500}
              defaultValue={stepsData.description}
              onChange={(e) => {
                validationHook.setDescription(e.target.value);
              }}
            />
            {validationHook.descriptionError && (
              <span className="text-danger">
                {validationHook.descriptionError}
              </span>
            )}
          </div>
          <div className="row">
            {props.isEdit === false ? (
              <div className="col-sm-6 form-group">
                <label>Upload {type} Image</label>
                <label
                  className="form-control custom-file"
                  htmlFor="my-file-selector"
                >
                  <input
                    id="my-file-selector"
                    type="file"
                    name="uploadImage"
                    multiple
                    accept="image/*"
                    style={{ display: "none" }}
                    // defaultValue={}
                    onChange={(e) => {
                      console.log("===>",e.target.files)
                      let count=e.target.files.length;
                      setImageSelected(count)
                      validationHook.setEventImage(e.target.files);
                    }}
                  />
                  <span className id="upload-file-info">
                    {imageSelected == 1 || imageSelected==0
                      ?(imageSelected +" image selected")
                    :
                      (imageSelected+" images selected")
                    }
                  </span>
                  <i className="fas fa-upload" />
                </label>
                {validationHook.eventImageError && (
                  <span className="text-danger">
                    {validationHook.eventImageError}
                  </span>
                )}
              </div>
            ) : (
              <div className="col-sm-12 form-group">
                <label>Upload {type} Image</label>
                <label
                  className="form-control custom-file"
                  htmlFor="my-file-selector"
                >
                  <input
                    id="my-file-selector"
                    type="file"
                    name="uploadImage"
                    accept="image/*"
                    multiple
                    style={{ display: "none" }}

                    onChange={(e) => {
                      console.log(e);
                      validationHook.setEventImage(e.target.files);
                      let count=e.target.files.length;
                      setImageReceived(count)
                    }}
                  />
                  <span className id="upload-file-info">
                    {imageReceived?imageReceived +" new image selected":"No new image selected"}
                  </span>
                  <i className="fas fa-upload" />
                </label>
                {validationHook.eventImageError && (
                  <span className="text-danger">
                    {validationHook.eventImageError}
                  </span>
                )}
                {/* {console.log(" stepsData.file ", stepsData.banner)} */}
                {stepsData && stepsData.banner ? (
                  stepsData.banner &&
                  stepsData.banner.map((ele, index) => (
                    <div
                      key={index}
                      style={{ display: "inline-block", marginLeft: "10px" }}
                    >
                      <img
                        src={process.env.REACT_APP_IMG_URL_ENDPOINT + ele.thumbnailImage}
                        style={{ height: "50px", width: "50px" }}
                      />
                      <br />
                      <button
                        onClick={(e) => {
                          handleImageDelete(e, index);
                        }}
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            )}

            {props.isEdit === true ? (
              <></>
            ) : (
              <div className="col-sm-6 form-group">
                <label>Discipline</label>
                <div className="select-wrap">
                  <select
                    className="form-control"
                    defaultValue={stepsData.discipline}
                    onChange={(e) => {
                      validationHook.setDiscipline(e.target.value);
                      handleDiscipline(e.target.value);
                    }}
                  >
                    <option>Select Discipline</option>
                    {disciple.map((val, index) => (
                      <option key={index} value={val.name}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {validationHook.disciplineError && (
                    <span className="text-danger">
                      {validationHook.disciplineError}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Distance</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  value={validationHook.distance}
                  onChange={(e) => {
                    validationHook.setDistance(e.target.value);
                  }}
                >
                  <option>Select Distance</option>
                  {distanceData.map((value, index) => (
                    <option key={index}>{value.name}</option>
                  ))}
                </select>
                {validationHook.distanceError && (
                  <span className="text-danger">
                    {validationHook.distanceError}
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <label>Type</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  name="discipline_type"
                  onChange={(e) => validationHook.setDisciplineType(e.target.value)}
                  value={validationHook.disciplineType}
                >
                  {" "}
                  <option>Select Type</option>
                  {disciplineData.map((value, index) => (
                    <option key={index}>{value.name}</option>
                  ))}
                </select>
                {validationHook.disciplineTypeError && (
                  <span className="text-danger small">
                    {validationHook.disciplineTypeError}
                  </span>
                )}
                {/* {validationHook.disciplineTypeError && (
                  <span className="text-danger">
                    {validationHook.disciplineTypeError}
                  </span>
                )} */}
              </div>
            </div>
          </div>
          <div className="action">
            <div />
            {next ? (
              <button className="btn btn-orange">Loading..</button>
            ) : (
              <button
                className="btn btn-orange"
                onClick={handleNext}
                type="submit"
              >
                Next step
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step;

import React from "react";
import { Route, Redirect } from "react-router-dom";
import isAdminAuthenticated from "./isAdminAuthenticated";

export default function AdminRoute({ component: Component, ...rest }) {
    
console.log("AdminRoute -> component", Component)
  return (
    <Route
      {...rest}
      render={(props) => {
        return isAdminAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/back-office/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

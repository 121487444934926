import React,{useState} from "react";
import {useHistory} from "react-router-dom";
import "./header.scss";

//import logo from "assets/img/logo.svg";
import logo from "../../logo.svg";
//import '../../assets/scss/dashboard.scss'
import { getUserData, getAdminData } from "../../helpers/utils";



const Header = (props) => {
  let getTokenData = !props.adminToken?getUserData():getAdminData();
  let history=useHistory();
  const handelLogout = () => {
    localStorage.removeItem(!props.adminToken?"authToken":"adminToken");
    history.push(!props.adminToken?"/login":"/back-office/login");
  };

const [visible,setVisible]=useState();
const addClassFromBody=(class_for_body)=>{
  document.body.classList.add(`${class_for_body}`);
}

const removeClassFromBody=(class_for_body)=>{
  document.body.classList.remove(`${class_for_body}`);
}
  return (
    <header className="main-header">
      {/* Logo */}
      <a href="/events" className="logo">
        {/* mini logo for sidebar mini 50x50 pixels */}
        <span className="logo-mini">
          <img src={require("../../assets/img/logo-small.png")} alt="" />
        </span>
        {/* logo for regular state and mobile devices */}
        <span className="logo-lg">
          <img src={require("../../assets/img/logo.png")} alt="" />
        </span>
      </a>
      {/* Header Navbar: style can be found in header.less */}
      <nav className="navbar navbar-static-top">
        {/* Sidebar toggle button*/}
        <a
          href="#"
          className="sidebar-toggle"
          data-toggle="push-menu"
          role="button"
        >
          {visible?            
            <i className="fas fa-times" onClick={()=>{removeClassFromBody("sidebar-open");setVisible(false)}}></i>
          :
            <i className="fas fa-bars" onClick={()=>{addClassFromBody("sidebar-open");setVisible(true)}}></i>
          }  
        </a>
        <div className="search-form">
          <form action="">
          
            {/* <div className="search-form-wrap">
              <div className="search-icon">
                <ion-icon name="ios-search" />
              </div>
              <div className="search-box">
                <input type="text" placeholder="Search here..." />
              </div>
            </div> */}
          </form>
        </div>
        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
          
            {/* <li>
              <a href="#" className="add-icon">
                <ion-icon name="ios-add-circle-outline" />
              </a>
            </li> */}
            {/* Messages: style can be found in dropdown.less*/}
            {/* <li className="dropdown messages-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <ion-icon name="ios-mail-open" />
                <span className="label label-yellow">4</span>
              </a>
            </li> */}
            {/* Notifications: style can be found in dropdown.less */}
            {/* <li className="dropdown notifications-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <ion-icon name="notifications-outline" />
                <span className="label label-orange">10</span>
              </a>
            </li> */}
            <div className="pull-left info" style={{marginTop:"15px"}}>
            <span>
            <i className="fas fa-user"></i>&nbsp;
              {getTokenData &&
               getTokenData.first_name + " " + getTokenData.last_name}
            </span>&nbsp;|&nbsp;
            <span
              style={{ color: "#ff5100", cursor: "pointer" }}
              onClick={() => handelLogout()}
            ><i className="fas fa-sign-out-alt"></i>&nbsp;Logout
              
            </span>
          </div>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;

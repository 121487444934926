import React, { useState } from "react";
import Stepper from "react-js-stepper";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import PageHeading from "../../components/pageHeading";
import Step1 from "../../components/createTraining/step1";
import Step2 from "../../components/createTraining/step2";
import Step3 from "../../components/createTraining/step3";
import Step4 from "../../components/createTraining/step4";

const CreateEventNew = () => {
  const steps = [
    {
      title: "Create Training",
    },
    {
      title: "Add details",
    },
    {
      title: "More Details",
    },
    {
      title: "invite friends",
    },
  ];

  const [activeStep, setActiveStep] = useState(1);

  const [stepOneData, setStepOneData] = useState({
    title: "",
    description: "",
    discipline: "",
    file: "",
    distance: "",
    event_type: "",
  });
  console.log(stepOneData, "sdsdsdsds");

  const [stepTwoData, setStepTwoData] = useState({
    location: "",
    region: "",
    start_date: "",
    end_date: "",
  });

  const [stepThreeData, setStepThreeData] = useState({
    total_distance: "",
    time: "",
    average_speed: "",
    upload_map: "",
    food_stop: "",
    visibility: "",
  });

  const handleOnClickStepper = (step) => {
    setActiveStep(step);
  };

  const handleOnClickNext = (val) => {
    console.log("next click");
    if (activeStep === 1) {
      setStepOneData(val);
    }
    if (activeStep === 2) {
      setStepTwoData(val);
    }
    if (activeStep === 3) {
      setStepThreeData(val);
    }
    let nextStep = activeStep + 1;
    setActiveStep(nextStep);
    //this.setState({ activeStep: nextStep });
  };

  const handleOnClickBack = () => {
    console.log("back click");
    let prevStep = activeStep - 1;
    setActiveStep(prevStep);

    //this.setState({ activeStep: prevStep });
  };
  return (
    <>
      <Header></Header>
      <PageHeading title="CREATE YOUR TRAINING SESSION"></PageHeading>

      <section className="main bg-transparent">
        <div className="steps-area">
          <Stepper
            className="new-step"
            steps={steps}
            activeStep={activeStep}
            onSelect={handleOnClickStepper}
            showNumber={false}
          />
          <div className="step_content ">
            {activeStep === 1 && (
              <Step1
                stepData={stepOneData}
                nextStep={handleOnClickNext}
              ></Step1>
            )}
            {activeStep === 2 && (
              <Step2
                stepData={stepTwoData}
                nextStep={handleOnClickNext}
                prevStep={handleOnClickBack}
              ></Step2>
            )}
            {activeStep === 3 && (
              <Step3
                stepData={stepThreeData}
                stepData1={stepOneData}
                stepData2={stepTwoData}
                prevStep={handleOnClickBack}
                nextStep={handleOnClickNext}
              ></Step3>
            )}
            {activeStep === 4 && (
              <Step4
                // dataStepTwo={stepTwoData}
                prevStep={handleOnClickBack}
                nextStep={handleOnClickNext}
              ></Step4>
            )}
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default CreateEventNew;

import React, { useEffect, useState } from "react";
import Stepper from "react-js-stepper";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import PageHeading from "../../components/pageHeading";
import Step1 from "../../components/createTribe/step1";
import Step2 from "../../components/createTribe/step2";
import Step3 from "../../components/createTribe/step3";
import Step4 from "../../components/createTribe/step4";
import "./editTribe.scss";
import { useParams } from "react-router-dom";
import { secureApiCall } from "../../services/conf/apiCall";


const EditTribe = () => {
  const params=useParams();

  const getTribeUrl=process.env.REACT_APP_API_ENDPOINT+`/api/list-events/${params.slug}`;
  const [eventData,setEventData]=useState({});

  useEffect(()=>{
    secureApiCall(getTribeUrl,'get').then((response)=>{
      
      if(!response.err){
        initStepOne(response.payload);
        initStepThree(response.payload);
        initStepTwo(response.payload);
      }
    }).catch((err)=>{
      console.log(err);
    })
  },[]);

  const steps = [
    { title: "Information" },
    { title: "invite" },
  ];
  const [activeStep, setActiveStep] = useState(1);
  const [eventId,setEventId]=useState([]);
  const [stepOneData, setStepOneData] = useState({
    title:"",
    description: "",
    discipline: "",
    file: "",
    banner:[],
    distance: "",
    discipline_type:"",
    event_type: "tribe",
    approval_required:false,
  });
 // console.log("Step One Data=====>",stepOneData);

  const [stepTwoData, setStepTwoData] = useState({
    location: "",
    region: "",
    country:"",
    date:"",
    start_time: "",
    end_time: "",
  });

  //console.log("Step Two Data=====>",stepTwoData);

  const [stepThreeData, setStepThreeData] = useState({
    total_distance: "",
    time: "",
    average_speed: "",
    upload_map: "",
    food_stop: "",
    visibility: "",
  });

  const initStepOne = (eventData) => {
    console.log("eventData.title", eventData.banner);
    setStepOneData({
      title: eventData.title,
      description: eventData.description,
      discipline: eventData.discipline,
      banner: eventData.banner,
      distance: eventData.distance,
      event_type: eventData.event_type,
      discipline_type: eventData.discipline_type,
      approval_required:eventData.approval_required,
      event_id:eventData._id,
    });
  };

  const initStepTwo = ( eventData) =>{
    setStepTwoData({
      location: eventData.location,
      date: eventData.date,
      region: eventData.region,
      start_time: eventData.start_time,
      end_time: eventData.end_time,
      
    });
  }

   const initStepThree = (eventData) => {
     setStepThreeData({
       total_distance: eventData.total_distance,
       time: eventData.time,
       average_speed: eventData.average_speed,
       upload_map: eventData.map_file,
       food_stop: eventData.food_stop,
       visibility: eventData.visibility,
       approval_required:eventData.approval_required,
     });
   };


  const handleOnClickStepper = (step) => {
    setActiveStep(step);
  };

  const handleOnClickNext = (val) => {
    console.log("handleOnClickNext -> val", val)
    let nextStep = activeStep;
    if (activeStep === 1) {
      setEventId({
        'event_id':val.event_id,
        'event_name':val.title,
      })
    }
    if (activeStep === 2) {
      setStepTwoData(val);
      nextStep = activeStep + 1;

    }
    if (activeStep === 3) {
      // setStepThreeData(val);
      // console.log(val.eventRes._id)
      
      nextStep = activeStep + 1;
    }

    setActiveStep(nextStep);

    //this.setState({ activeStep: nextStep });
  };

  const handleOnClickBack = () => {
    //console.log("back click");
    if( activeStep !==3){
      let prevStep = activeStep - 1;
      setActiveStep(prevStep);
    }

  };



  return (
    <>
      <Header></Header>
      <PageHeading title="EDIT YOUR TRIBE"></PageHeading>

      <section className="main bg-transparent">
        <div className="steps-area">
          <div className="stepper-container form-check ">
            <div className="stepper-container"></div>
          </div>

          <Stepper
            className="new-step"
            steps={steps}
            activeStep={activeStep}
            onSelect={handleOnClickStepper}
            showNumber={false}
          />

          <div className="step_content ">
            {activeStep === 1 && (
              <Step1
                stepData={stepOneData}
                stepData1={stepTwoData}
                stepData2={stepThreeData}
                isEdit={true}
                nextStep={handleOnClickNext}
              ></Step1>
            )}
            {/* {activeStep === 2 && (
              <Step2
                stepData={stepTwoData}
                isEdit={true}
                nextStep={handleOnClickNext}
                prevStep={handleOnClickBack}
              ></Step2>
            )}
            {activeStep === 3 && (
              <Step3
                stepData={stepThreeData}
                stepData1={stepOneData}
                stepData2={stepTwoData}
                isEdit={true}
                prevStep={handleOnClickBack}
                nextStep={handleOnClickNext}
              ></Step3>
            )} */}
            {activeStep === 2 && (
              <Step4
                // dataStepTwo={stepTwoData}
                stepData={eventId}
                isEdit={true}
                prevStep={handleOnClickBack}
                nextStep={handleOnClickNext}
              ></Step4>
            )}
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default EditTribe;

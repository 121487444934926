import React, { useEffect, useState,useRef } from "react";
import moment from "moment";

import PageHeading from "../../components/pageHeading";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import { secureApiCall, ApiCall } from "../../services/conf/apiCall";
import {
  regions,
  disciplines,
  typesOfAthlons,
  typesOfCycling,
  typesOfRunning,
  typesOfSwimming,
  distanceTypeCycling,
  distanceTypeRunning,
  distanceTypeSwimming,
  distanceTypeAthlons,
} from "../../constants/global";
import defaultFeatureImage from "../../assets/img/feature_image.jpg";
import {getStartDateOfWeek,getTodayDate,getStartingDateOfMonth} from "../../helpers/utils";
const TrainingList = (props) => {
  const [eventData, setEventData] = useState([]);
  const [regionData,setRegionData]=useState(regions);
  const [disciple,setDisciple]=useState(disciplines);
  const [disciplineData,setDisciplineData]=useState([]);
  const [distanceData,setDistanceData]=useState([]);
  const [durationFilter,setDurationFilter]=useState("");
  const [eventTypeFilter,setEventTypeFilter]=useState("training");
  const [disciplineFilter,setDisciplineFilter]=useState("");
  const [regionFilter,setRegionFilter]=useState("");
  const [disciplineTypeFilter,setDisciplineTypeFilter]=useState("");
  const [distanceFilter,setDistanceFilter]=useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setloading] = useState(false);

  let getAllEventsUrl = process.env.REACT_APP_API_ENDPOINT + "/list-events";
  useEffect( () => {

    secureApiCall(`${getAllEventsUrl}?event_type=training`, "get")
      .then((response) => {
        // console.log(response);
        setEventData(response.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEventFilters=()=>{
    // console.log("--",durationFilter,eventTypeFilter,regionFilter,disciplineFilter);

    secureApiCall(`
    ${getAllEventsUrl}?duration=${durationFilter}&event_type=${eventTypeFilter}&discipline=${disciplineFilter}&region=${regionFilter}&disciplineType=${disciplineTypeFilter}&distance=${distanceFilter}&query=${searchText}
    `, "get")
      .then((response) => {
        console.log(response);
        setEventData(response.payload);
        setloading(false)
      })
      .catch((err) => {
        console.log(err);
        setloading(false)
      });
  }

  
  useEffect( () => {
    setloading(true)
    handleEventFilters();
  }, []);

  
  useEffect( () => {
    handleEventFilters();
  }, [searchText,disciplineTypeFilter,durationFilter,disciplineFilter,disciplineData,distanceFilter,distanceData,regionFilter]);


  let eventType=eventData && eventData.map((val)=>val.event_type);



  const handleDiscipline=(dataType)=>{
    //console.log("===asdsad===",dataType,dis)
    if(dataType==="Running"){
      setDisciplineData(typesOfRunning);
      setDistanceData(distanceTypeRunning);
    }
    if(dataType==="Swimming"){
      setDisciplineData(typesOfSwimming);
      setDistanceData(distanceTypeSwimming);
    }
    if(dataType==="Cycling"){
      setDisciplineData(typesOfCycling);
      setDistanceData(distanceTypeCycling);
    }
    if(dataType==="Athlons"){
      setDisciplineData(typesOfAthlons)
      setDistanceData(distanceTypeAthlons);
    // }
    }
  }

  return (
    <>
      <Header></Header>
      <PageHeading title="FIND A TRAINING SESSION"></PageHeading>
      <section className="main bg-transparent">
        <div className="steps-area">
          <div className="steps-form">
            <div className="row">
              <div className="col-xs-12 col-12">
                <ul className="filter-options">
                  <li>
                    <div className="form-group">
                      <label>Select Date </label>
                      <div className="select-wrap">
                        <i className="fas fa-calendar" />
                        <select name="duration" id className="form-control" onChange={(e)=>setDurationFilter(e.target.value)}>
                          <option selected value="">&nbsp;Select one</option>
                          <option value="today" >Today</option>
                          <option value="this_week">This week</option>
                          <option value="this_month">This Month</option>
                          <option selected value="all">All</option>
                        </select>

                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <div className="form-group">
                      <label>Select your date range:</label>
                      <div className="select-wrap">
                        <i className="ion-android-calendar" />
                        <select name id className="form-control">
                          <option value>-Select one-</option>
                          <option value="Today" selected>
                            Fri, Aug 20 - Fri, Dec 20
                          </option>
                        </select>
                      </div>
                    </div>
                  </li> */}
                  {/* <li>
                    <div className="form-group">
                      <label>Event type</label>
                      <div className="select-wrap">
                      {eventTypeFilter==='event' &&
                        <i className="fas fa-flag" />
                      }
                      {eventTypeFilter ==='training' &&
                       <i className="fas fa-flag-checkered" />
                      }
                        <select name="event_type" id className="form-control"  onChange={(e)=>setEventTypeFilter(e.target.value)}>
                          <option value="event">Event</option>
                          <option value="training" selected>Training</option>
                        </select>
                      </div>
                    </div>
                  </li> */}
                  <li>
                    <div className="form-group">
                      <label>Discipline</label>
                      <div className="select-wrap">
                      {disciplineFilter == ''  && <i className="fas fa-trophy"></i> }
                      {disciplineFilter == 'Running'  && <i className="fas fa-running" /> }
                      {disciplineFilter == 'Athlons' && <i className="fas fa-football-ball" />}
                      {disciplineFilter == 'Cycling' && <i className="fas fa-biking"/>}
                      {disciplineFilter == 'Swimming' && <i className="fas fa-swimmer" />}
                        <select 
                          name="discipline" 
                          className="form-control" 
                          onChange={(e)=>{
                            setDisciplineFilter(e.target.value);
                            handleDiscipline(e.target.value);
                            }
                          }>
                          <option selected value="">&nbsp;All</option>
                          {disciple && disciple.map((discipline,index)=>(
                            <option key={index}>{discipline.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label>Discipline Type</label>
                      <div className="select-wrap">
                      { <i className="fas fa-bars"></i> }
                        <select 
                          name="discipline" 
                          className="form-control" 
                          onChange={(e)=>{
                            setDisciplineTypeFilter(e.target.value);
                            }
                          }>
                          <option selected value="">&nbsp;All</option>
                          {disciplineData && disciplineData.map((discipline,index)=>(
                            <option key={index}>{discipline.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label>Distance</label>
                      <div className="select-wrap">
                      {<i className="fas fa-route"></i> }
                        <select 
                          name="discipline" 
                          className="form-control" 
                          onChange={(e)=>{
                            setDistanceFilter(e.target.value);}
                          }>
                          <option selected value="">&nbsp;All</option>
                          {distanceData && distanceData.map((distance,index)=>(
                            <option key={index}>{distance.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  
                  <li>
                    <div className="form-group">
                      <label>Region</label>
                      <div className="select-wrap">
                        <i className="fas fa-map-marker-alt"/>
                        <select name="region" id className="form-control"  onChange={(e)=>setRegionFilter(e.target.value)}>
                          <option value="">&nbsp;Select one</option>
                          {regionData&&regionData.map((region,index)=>(
                            <option key={index}>{region.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                  <div className="form-group search-input">
                      <label className="empty-label">&nbsp;</label>
                      <div className="">
                        <span className="fas fa-search search-icon" /> 

                        <input type="text" id="search" className="form-control" onChange={(e)=>setSearchText(e.target.value)} style={{width:'200px',height:'40px'}} placeholder="Search" name="search" />

                      </div>
                    </div>
                    {/* <div className="form-group" onClick={()=>handleEventFilters()}>
                      <label className="empty-label">&nbsp;</label>
                      <button className="btn btn-primary btn-block">
                        <span className="fas fa-search" /> Search
                      </button>
                    </div> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">

              {eventData && eventData.length==0?
                  <div className="search-title">
                   { loading  ?  '   Loading Data' : 'No trainings found' }
Training
                  </div>
                :
                  <div className="search-title">
                     Showing {eventData && eventData.length} Training
                  </div>
                }

              
              </div>
            </div>
            <div className="row">
              {eventData &&
                 eventData.map((value, key) => (
                  <div className="col-xs-12 col-sm-6 col-md-4" key={key}>
                    <a
                      // href={`/event/${value._id}`}
                      href={`/training/${value.slug}`}
                      className="event-box"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <div className="event-img">
                      {value.banner.length===0?
                        <img
                          // src={`${value.banner}`}
                          src={defaultFeatureImage}
                          className="img-responsive"
                          alt=""
                        />
                        :
                        <img
                            // src={`${value.banner}`}
                            src={process.env.REACT_APP_IMG_URL_ENDPOINT+value.banner[0].featureImage}
                            className="img-responsive"
                            alt=""
                          />
                      }
                      </div>
                      <div className="event-description">
                        <h5>{value.title}</h5>
                        <div>
                          <ul>
                            <li>
                              <i className="fas fa-calendar" />
                              &emsp;{moment(value.date).format('ll')}
                            </li>
                            <li>
                              {value.discipline === "Running"? 
                                <span>
                                  <i className="fas fa-running" />
                                  &emsp;{value.discipline}
                                </span>
                                :value.discipline==="Swimming"?
                                <span>
                                  <i className="fas fa-swimmer" />
                                  &emsp;{value.discipline}
                                </span>
                                :value.discipline==="Cycling"?
                                <span>
                                  <i className="fas fa-biking" />
                                  &emsp;{value.discipline}
                                </span>
                                :value.discipline==="Athlons"?
                                <span>
                                  <i className="fas fa-football-ball" />
                                  &emsp;{value.discipline}
                                </span>:<></>
                              }
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt" />
                              &emsp;{value.location}
                            </li>
                            {/* <li>
                              <i className="fas fa-tag" />&emsp;FREE
                            </li> */}
                            <li></li>
                            <li></li>
                            <div className="totalUsers">
                              {/* { value.total_joined==0 ? <div></div> : <div>Total Joined ({value.total_joined})</div> }
                              { value.total_interested==0 ? <div></div> : <div>Total Interested ({value.total_interested})</div> } */}
                              { <>
                                  <i className="fas fa-users" style={{color:"#ff5100"}}></i>
                                  <div>Joined ({value.total_joined})</div> 
                                </>
                              }
                              { <>
                                  {/* <i className="fas fa-users" style={{color:"#ff5100"}}></i> */}
                                  <div>Interested ({value.total_interested})</div> 
                                </>
                              }
                            </div>    
                          </ul>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <Footer event_type="event"></Footer>
    </>
  );
};
export default TrainingList;

import React, { Component, useState } from "react";
import { useHistory , } from "react-router-dom";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";

import { ToastContainer, toast } from "react-toastify";
import { responsiveArray } from "antd/lib/_util/responsiveObserve";
import { secureApiCall } from "../../services/conf/apiCall";

import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const LoginPage = (props) => {
  const history = useHistory();
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const query = new URLSearchParams(props.location.search);
  const retUrl = query.get('retUrl')

console.log('retUrl',retUrl);
  const googleLoginUrl=process.env.REACT_APP_API_ENDPOINT+"/auth/google-login";
  const facebookLoginUrl=process.env.REACT_APP_API_ENDPOINT+"/auth/facebook-login";

  const GoogleClientId="592022656893-orjr9jbu1o02p2pa3630ja35vrn65uiu.apps.googleusercontent.com";
  const FacebookAppId="372159620034555";
  


  const validateEmail = (userEmail) => {
    if (
      userEmail &&
      /^$|^(([^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+([._-][^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+)*)|(".+"))@((?!-)[^}@]+)(((\[)?[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(\])?)|(([a-zA-Z0-9-]+\.)+[a-zA-Z1]{2,}))$/i.test(
        userEmail.trim() || ""
      )
    ) {
      setEmailError(null);
      return true;
    } else {
      setEmailError("Please enter a valid email address");
      return false;
    }
  };
  const validatePassword = (password) => {
    if (password.length > 6) {
      if (password) {
        setPasswordError(null);
        return true;
      } else {
        setPasswordError("Please enter password");
        return false;
      }
    } else {
      setPasswordError("Please enter password");
    }
  };

  const validLoginData = () => {
    let error = true;
    if (!validateEmail(userEmail)) {
      error = false;
    }
    if (!validatePassword(password)) {
      error = false;
    }
    return error;
  };

  const pushLoginData = () => {
    let formData = {
      email: userEmail.toLowerCase(),
      password: password,
    };
    let url = process.env.REACT_APP_API_ENDPOINT + "/auth/login";

    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((response) => {
        //response.json();
        console.log("===================", response);
        if (!response.err) {
          let token = response.token;
          localStorage.setItem("authToken", token);
          if(retUrl){
            history.push(retUrl);
          }else{
            history.push("/profile");
          }
          toast.success(`Welcome Back`);
        } else {
          // setServerError(response.statusText);
          setServerError("Incorrect Email id or Password");
        }
      })
      .catch((err) => {
        setServerError("Incorrect Email id or Password");
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validLoginData()) {
      pushLoginData();
    }
    setInterval(() => {
      setLoading(false);
    }, 1000);
  };

  const onFailure=(res)=>{
    console.log("onFailure -> res", res)
  }

  const loginWithGoogle=(res)=>{    
    secureApiCall(googleLoginUrl, "post" ,{},{'googleProfileToken':res.tokenId})
    .then((response)=>{
      console.log("loginWithGoogle -> response", response );
      if(response.payload){
        let token = response.payload;
        localStorage.setItem("authToken", token);
        history.push("/profile");
        toast.success(response.msg);
      }else{
        toast.warn(response.msg||"You are not registered");
      }
    })
    .catch((err)=>{
      console.log("signupWithGoogle -> err", err)
      toast.error(err.msg);
    })
  }

  const loginWithFacebook=(res)=>{
    secureApiCall(facebookLoginUrl, "post" ,{},{'facebookProfileToken':res.accessToken})
    .then((response)=>{
      console.log("loginWithFacebook -> response", response );
      if(response.payload){
        let token = response.payload;
        localStorage.setItem("authToken", token);
        history.push("/profile");
        toast.success(response.msg);
      }else{
        toast.warn(response.msg||"You are not registered");
      }
    })
    .catch((err)=>{
      console.log("signupWithGoogle -> err", err)
      toast.error(err.msg);
    })
  }

  return (
    <div>
      <Header></Header>
      <section className="main register-area">
        <div className="register-container">
          <div className="title">LogIn</div>
          {/* <div className="sub-title">Log in below with your credentials</div> */}
          <div className="sub-title"></div>
          <div className="row">
            <form method="post">
              <div className="col-md-6 or-divider">
              <div className="regi-form">
                {/* <div className="form-group">
              <label>User name</label>
              <input className="form-control" placeholder="enter username" value="" type="text">
          </div> */}
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    name="userEmail"
                    value={userEmail}
                    onChange={(e) => {
                      validateEmail(e.target.value);
                      setUserEmail(e.target.value);
                    }}
                    className="form-control"
                    placeholder="enter email address"
                  />
                  {emailError && (
                    <p className="error-label text-danger"> {emailError} </p>
                  )}
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => {
                      validatePassword(e.target.value);
                      setPassword(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Enter password"
                  />
                  {passwordError && (
                    <p className="error-label text-danger"> {passwordError} </p>
                  )}
                  <div className="text-right mt-10">
                    <small>
                      <a href="/forget-password">Forgot Password?</a>
                    </small>
                  </div>
                </div>
                {serverError && (
                  <p className="text-danger small text-center ">
                    {serverError}
                  </p>
                )}
                <div className="text-center">
                  {loading ? (
                    <button className="btn btn-orange">loading...</button>
                  ) : (
                    <button
                      onClick={handleLogin}
                      className="btn btn-orange"
                      type="submit"
                    >
                      Sign In
                    </button>
                  )}
                  <br />
                  <br />
                  <div className="clearfix" />
                  Don't have an account?{" "}
                  <a className="orange-link" href="/register">
                    Register here
                  </a>
                </div>
              </div>
            </div>
            </form>
            <div className="col-md-6">
              <div className="regi-social">
                <ul className="social-login">
                <li className="fb">
                    <FacebookLogin
                        appId={FacebookAppId}
                        // onClick={signupWithFacebook}
                        fields="name,email,picture"
                        scope="public_profile,user_friends"
                        callback={loginWithFacebook}
                        render={renderProps => (
                          <button 
                            style={{backgroundColor:"transparent",color:"white",border:"0px solid",padding:"16px"}} 
                            onClick={renderProps.onClick} 
                            disabled={renderProps.disabled} >Connect with FaceBook</button>
                        )}
                    ></FacebookLogin>
                  </li>
                  <li className="g-plus">
                    <GoogleLogin
                    className="g-plus"
                      clientId={GoogleClientId}
                      render={renderProps => (
                        <button 
                          style={{backgroundColor:"transparent",color:"white",border:"0px solid",padding:"16px"}} 
                          onClick={renderProps.onClick} 
                          disabled={renderProps.disabled}>Connect with Google</button>
                      )}
                      onSuccess={loginWithGoogle}
                      onFailure={onFailure}
                      buttonText="Connect with Google"
                    ></GoogleLogin>
                    {/* <a onClick={signupWithGoogle}>Connect with Google</a> */}
                  </li>
                </ul>
                <div className="terms-text">
                  By clicking the buttons above, you agree to TRIBEathlon's{" "}
                  <a className="orange-link" href="#">
                    terms&nbsp;
                  </a>
                  and
                  <a href="#">&nbsp;privacy policy </a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </section>
      <Footer></Footer>
    </div>
  );
};

export default LoginPage;

import React, {useEffect, useRef, useState } from "react";
import {useHistory,useParams} from "react-router-dom"
import {secureApiCall} from "../../../services/conf/apiCall"
import Header from "../../../components/publicHeader";
import Footer from "../../../components/footer";
import PageHeading from "../../../components/pageHeading";
import { ToastContainer, toast } from "react-toastify";

const InviteMembers = (props) => {
  const history=useHistory();
  const params=useParams();
  const search_box=useRef();

  const getEventsUrl = process.env.REACT_APP_API_ENDPOINT + `/list-events/${params.slug}`;
  const viewFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/my-friends";
  const inviteFriendsUrl=process.env.REACT_APP_API_ENDPOINT+"/api/send-tribe-invite";
  const searchFriendUrl=process.env.REACT_APP_API_ENDPOINT+"/api/search-friends";


  const [usersData,setUsersData]=useState([]);
  const [invite, setInvite] = useState([]);
  const [searchedUser,setSearchedUser]=useState([]);
  const [eventData,setEventData]=useState();
  const [disableBtn,setDisableBtn]=useState(false);
  
  // console.log("===step 4 data===",props.stepData);

    useEffect(() => {
    secureApiCall(viewFriendUrl,"get")
    .then((response)=>{
      // console.log(response);
      if(!response.err){
        setUsersData(response.payload);
        // // console.log(response.payload)
      }
    }).catch((err)=>{
      // console.log(err);
    })
    secureApiCall(getEventsUrl,"get")
    .then((response)=>{
      if(!response.err){
        setEventData(response.payload);
      }
    }).catch((err)=>{
      console.log(err);
    })

  },[])

  const sendInvite = () => {
    console.log(invite);
    try{
      if(!invite.length){
        toast.error("Please select members");
      }else{
        let event_id=eventData && eventData._id;
        let event_name=eventData && eventData.title;

      secureApiCall(inviteFriendsUrl,"post",JSON.stringify({invite,event_id,event_name}),{'Content-type':'application/json'})
      .then((response) => { 
          toast.success(response.msg);
          history.push("/my-tribes")
        })
        .catch((err) => {
          toast.error(err);
        });
      }
    }catch(err){
      // console.log(err);
      toast.error(err.msg);
      } 
    };

  const handleUserSearch=(e,userName)=>{
    // console.log("--my data",search_box.current.value)
    secureApiCall(searchFriendUrl,"post",JSON.stringify({user_name:search_box.current.value}),{'Content-type':'application/json'})
      .then((response) => {
        // console.log("--searched user--",response.payload)
         setSearchedUser(response.payload)
         setUsersData(response.payload)
       })
      .catch((err) => {
          // console.log(err);
      });
      // search_box.current.value="";

  }

  const handelNextClick = () => {
    props.nextStep();
  };
  const handelPrevClick = (e) => {
    props.prevStep();
  };

  return (
    <>
    <Header></Header>
    <PageHeading title="Invite Members"></PageHeading>

    <section className="main bg-transparent">
    <div className="steps-area">
        <div className="steps-form create-steps">
      <div className="row search-results">
        <div className="col-md-6 col-md-offset-3">
          
          <div className="form-group">
            <label className="text-center">
              Invite your friends to join...
            </label>
            <input
              className="form-control"
              placeholder="Search for friends"
              type="text"
              ref={search_box}
              
            />
            <a  onClick={()=>handleUserSearch()} className="icon-btn">
              <i className="fas fa-search" />
            </a>
          </div>
          <label className="text-center">
            Recommended Friends / Search Results
          </label>
          <ul>
          
          {usersData && usersData.map((value,index)=>(
            <li key={index}>
              <div className="img-box">
                <img
                  src={require("../../../assets/img/email-icon.png")}
                  alt=""
                />
              </div>
              <div className="user-detail">
                 <h6 className="username">{value.friend_name}</h6>
                <p className="stream">Runner</p>
                <p className="location">Leicester</p>
              </div>
              <label className="customcheck">
                <input type="checkbox"
                value={ value._id }
                onChange={
                  (e)=>{
                    let user_id= e.target.value;
                        if( e.target.checked){
                          invite.push(
                            user_id
                          )
                          if(invite.length>0){
                            setDisableBtn(true);
                          }
                        }else{
                          // console.log("position ", invite.indexOf(user_id) ,  user_id );
                         invite.splice(invite.indexOf(user_id),1) 
                         if(invite.length<=0){
                          setDisableBtn(false);
                        }
                        }
                        setInvite(invite)
                        // console.log( invite)
                      }
                    
                    }
                />
                <span className="checkmark" />
              </label>
            </li>
          ))}
          
           </ul>
          <div className="action">
            <span></span>
            {disableBtn?
              <button className="btn btn-orange" type="submit" onClick={sendInvite}>
                Invite to Tribe
              </button>
              :
                <></>
            }

          </div>
          <div className="text-center">
            <a href="/my-tribes" className="view-event">
              Skip &amp; view your tribe
            </a>
          </div>
        </div>
      </div>
    </div>
      </div>
    </section>
    <Footer></Footer>
  </>
    );
};

export default InviteMembers;

import React, { Component, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import useValidationHook from "./validation";
import { ToastContainer, toast } from "react-toastify";
import {secureApiCall} from "../../services/conf/apiCall";
import registerPage from ".";
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const AdminRegisterPage = (props) => {
  const validationHook = useValidationHook();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  const googleUrl=process.env.REACT_APP_API_ENDPOINT+"/auth/google-signup";
  const facebookUrl=process.env.REACT_APP_API_ENDPOINT+"/auth/facebook-signup";
  
  const GoogleClientId="592022656893-orjr9jbu1o02p2pa3630ja35vrn65uiu.apps.googleusercontent.com";
  const FacebookAppId="372159620034555";
  
  const onSuccess=async(res)=>{
    console.log("onSuccess -> res", res);

    // let profileObj=await getUserSocialProfileData(res.tokenId);
    signupWithGoogle(res.tokenId);
  }
  const onFailure=(res)=>{
    console.log("onFailure -> res", res)  
  }

  const pushLoginData = () => {
    let formData = {
      first_name: validationHook.userFirstName,
      last_name: validationHook.userLastName,
      email: validationHook.userEmail,
      password: validationHook.password,
    };
    const url = process.env.REACT_APP_API_ENDPOINT + "/auth/admin-register";

    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if(response.ok){
          console.log("pushLoginData -> response", response)
          toast.success("Success! you registered successfully. Please verify your email");
          history.push("/back-office/login");
        }else{
          toast.error("Admin already exist");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validationHook.validationForm()) {
      setLoading(true);
      pushLoginData();
    }
    setInterval(() => {
      setLoading(false);
    }, 1000);
  };

  const signupWithGoogle=(googleProfileToken)=>{
    secureApiCall(googleUrl, "post" ,{},{'googleProfileToken':googleProfileToken})
    .then((response)=>{
      console.log("signupWithGoogle -> response", response.payload.length);
      if(response.payload.length>0){
        toast.success(response.msg);
        history.push('/login');
      }else{
        toast.warn(response.msg);
        history.push('/login');
      }
    })
    .catch((err)=>{
      console.log("signupWithGoogle -> err", err)
      toast.error(err.msg);
    })
  }

  const signupWithFacebook=(facebookProfileToken)=>{
  secureApiCall(facebookUrl, "post" ,{},{'facebookProfileToken':facebookProfileToken})
    .then((response)=>{
      console.log("signupWithGoogle -> response", response.payload.length);
      if(response.payload.length>0){
        toast.success(response.msg);
        history.push('/login');
      }else{
        toast.warn(response.msg);
        history.push('/login');
      }
    })
    .catch((err)=>{
      console.log("signupWithGoogle -> err", err)
      toast.error(err.msg);
    })

  }
  const responseFacebook=(res)=>{
  console.log("responseFacebook -> res", res)
  signupWithFacebook(res.accessToken)
  }


  return (
    <div>
      {/* <Header></Header> */}
      <section className="main register-area">
        <div style={{ marginTop:"5%"}}>
          <div className="title">Admin Register</div>
          <br/>
          <div className="row" style={{textAlign:"center",textAlign:"-webkit-center"}}>
          <form>
              <div className=""  style={{width:"60%"}}>
                <div className="form-group col-md-6">
                <p  style={{textAlign:"start"}}><label>First Name</label></p>
                  <input
                    type="text"
                    name="first_name"
                    defaultValue={validationHook.userFirstName}
                    onChange={(e) => {
                      validationHook.setUserFirstName(e.target.value);
                    }}
                    className="form-control"
                    placeholder="First name"
                  />
                  {validationHook.firstNameError && (
                    <p className="error-label text-danger">
                      {validationHook.firstNameError}
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6">
                <p  style={{textAlign:"start"}}><label>Last Name</label></p>
                  <input
                    type="text"
                    name="last_name"
                    defaultValue={validationHook.userLastName}
                    onChange={(e) => {
                      validationHook.setUserLastName(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Last name"
                  />
                  {validationHook.lastNameError && (
                    <p className="error-label text-danger">
                      {" "}
                      {validationHook.lastNameError}{" "}
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6">
                <p  style={{textAlign:"start"}}><label>Email address</label></p>
                  <input
                    type="email"
                    name="userEmail"
                    defaultValue={validationHook.userEmail}
                    onChange={(e) => {
                      validationHook.setUserEmail(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Email"
                  />
                  {validationHook.emailError && (
                    <p className="error-label text-danger">
                      {" "}
                      {validationHook.emailError}{" "}
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6">
                <p  style={{textAlign:"start"}}><label>Password</label></p>
                  <input
                    type="password"
                    name="password"
                    defaultValue={validationHook.password}
                    onChange={(e) => {
                      validationHook.setPassword(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Password"
                  />
                  {validationHook.passwordError && (
                    <p className="error-label text-danger">
                      {" "}
                      {validationHook.passwordError}{" "}
                    </p>
                  )}
                </div>
                {serverError && (
                  <p className="text-danger text-center">{serverError}</p>
                )}
                <div className="text-center">
                  {loading ? (
                    <button className="btn btn-orange">loading...</button>
                  ) : (
                    <button
                      onClick={handleLogin}
                      className="btn btn-orange"
                      type="submit"
                    >
                      Sign Up
                    </button>
                  )}
                </div>
              </div>
          </form>
          </div>
        </div>
      </section>
      {/* <Footer></Footer> */}
    </div>
  );
};

export default AdminRegisterPage;

import React, { Component } from "react";
import "./homePage.scss";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";

const HomePage =(props) =>{
  return (
    <>
      <Header></Header>
      <div>
        <div className="header-btns-fixed-mobile">
          <a href="/events" className="btn btn-primary">
            <i className="ion-android-star" /> Find an Event
          </a>
          <a href="/login" className="btn btn-secondary store">
            <i className="ion-android-lock" /> Login / Register
          </a>
        </div>
        {/* <section className="bg-orange">
          <div className="title-white">Create your next event</div>
        </section> */}
      </div>
      <div>
        <section
          className="banner-section"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/events_bg.jpg") + ")",
          }}
        >
          <div className="container">
            <a href="/events" className="banner-link-text">
              <span className="main-title">
                <b>EVENTS</b>
              </span>
              <span className="sub-title">
                <b>
                  Find your next event <i className="ion-arrow-right-b" />
                </b>
              </span>
            </a>
          </div>
        </section>
        <section
          className="banner-section right"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/events_bg1.jpg") + ")",
          }}
        >
          <div className="container">
            <a href="/trainings" className="banner-link-text">
              <span className="main-title">
                <b>Train</b>
              </span>
              <span className="sub-title">
                <b>
                  Create your own event <i className="ion-arrow-right-b" />
                </b>
              </span>
            </a>
          </div>
        </section>
        <section
          className="banner-section"
          style={{
            backgroundImage:
              "url(" +
              require("../../assets/img/events_bg2.jpg") + ")",
          }}
        >
          <div className="container">
            <a href="/tribes" className="banner-link-text">
              <span className="main-title">
                <b>Tribe</b>
              </span>
              <span className="sub-title">
                <b>
                  Manage your Tribe <i className="ion-arrow-right-b" />
                </b>
              </span>
            </a>
          </div>
        </section>
      </div>
      <Footer></Footer>
    </>
  );
}

export default HomePage;

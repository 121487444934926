import React, { Component,useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment"
import Header from "../../components/header";
import DashboardNavbar from "../../components/dashboardNavbar";
import PageHeading from "../../components/pageHeading";
import { secureApiCall } from "../../services/conf/apiCall";
import { toast } from "react-toastify";

const Dashboard = (props) => {

  const [usersData,setUsersData]=useState([]);
  const [eventsData,setEventsData]=useState([]);
  const [tribeData,setTribeData]=useState([]);
  const [selectType,setSelectType]=useState("event")
  const [status,setStatus]=useState("");

  const getUsersUrl=process.env.REACT_APP_API_ENDPOINT+"/admin/get-all-users";
  const getEventsUrl=process.env.REACT_APP_API_ENDPOINT+"/admin/get-all-events";
  const getTribesUrl=process.env.REACT_APP_API_ENDPOINT+"/admin/get-all-tribes";
  const updateEventStatusUrl=process.env.REACT_APP_API_ENDPOINT+"/admin/update-status"
  const updateUserStatusUrl =process.env.REACT_APP_API_ENDPOINT+"/admin/update-user-status"
  const deleteUserUrl =process.env.REACT_APP_API_ENDPOINT+"/admin/delete-user"
  const deleteEventUrl = process.env.REACT_APP_API_ENDPOINT + "/admin/delete-event";
 
  useEffect(() => {
  
    getUsers();
    getEvents();
    getTribes();

  }, [])


  const getTribes=()=>{
    secureApiCall(getTribesUrl,'get')
    .then((response)=>{
      console.log("Dashboard -> response ->Tribes", response)
      setTribeData(response.payload);
    })
    .catch((err)=>{
      console.log("Dashboard -> err", err)
    })
  }

  const getUsers=()=>{
    secureApiCall(getUsersUrl,'get')
    .then((response)=>{
    console.log("Dashboard -> response-> Users", response)
      setUsersData(response.payload);
    })
    .catch((err)=>{
      console.log("Dashboard -> err", err)
    })
  }

  const getEvents =()=>{
    secureApiCall(getEventsUrl,'get')
    .then((response)=>{
      console.log("Dashboard -> response ->Events", response)
      setEventsData(response.payload);
    })
    .catch((err)=>{
      console.log("Dashboard -> err", err)
    })
  }

  const handleChangeStatus=(event_id,event_status)=>{
    console.log("handleChangeStatus -> event_id,event_status", event_id,event_status)
      
      secureApiCall(`${updateEventStatusUrl}?event_id=${event_id}&event_status=${event_status}`,"get")
        .then((response)=>{
          console.log("response==>",response);
          toast.success(response.msg);
        })
        .catch((err)=>{
          console.log("Error==>",err);
        })
    }
     const handleChangeUserStatus=(user_id,user_status)=>{
        console.log("handleChangeUserStatus -> user_id,user_status", user_id,user_status)
        secureApiCall(`${updateUserStatusUrl}?user_id=${user_id}&user_status=${user_status}`,"get")
          .then((response)=>{
            console.log("response==>",response);
            toast.success(response.msg);
          })
          .catch((err)=>{
            console.log("Error==>",err);
          })
      }

      const deleteUser = (user) => {

        let res=window.confirm("Do you want to Delete ?");
        if(res){

          const {_id} = user;
          let requestData = {
            user_id: _id,
          };
          secureApiCall(deleteUserUrl, "post", JSON.stringify(requestData), {
            "Content-type": "application/json",
          })
            .then((response) => {
              if (!response.err) {
                  getUsers();
              }else{
                toast.error(response.msg);
              }
              console.log("--response--", response);
            })
            .catch((err) => {
               toast.error("something went wrong ");
              console.log(err);
            });
          
        }
     
        };

        const deleteEvents = (event) => {

          let res=window.confirm("Do you want to Delete ?");
          if(res){
  
            const {_id} = event;
            let requestData = {
              event_id: _id,
            };
            secureApiCall(deleteEventUrl, "post", JSON.stringify(requestData), {
              "Content-type": "application/json",
            })
              .then((response) => {
                if (!response.err) {
                if(selectType === "tribe"){
                  getTribes();
                }else{
                  getEvents();
                }
  
                }else{
                  toast.error(response.msg);
                }
                console.log("--response--", response);
              })
              .catch((err) => {
                 toast.error("something went wrong ");
                console.log(err);
              });
            
          }
       
          };
      const onDelete=(e,val)=>{
        e.preventDefault();
        if(selectType === "event" || selectType === "training" || selectType === "tribe"){
           deleteEvents(val);
        }else if(selectType === "user"){
           deleteUser(val);
        }
      }
  return (
    <>    
    <div className="wrapper">
    
      <Header adminToken={"adminToken"}></Header>
      <DashboardNavbar 
        onPress={(val)=>{
          console.log("Dashboard -> val", val)
          setSelectType(val);
        }}
      />
      <form id="profile">

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>
              <span>Dashboard</span>
            </h1>
          </section>
          <section className="profilesetting">
            <div className="setting-form">
              <div className="setting-form-inner">
              <table className="table" style={{overflow:"scroll"}}>
                <thead >
                  {selectType==='user'?
                  
                  <tr>
                    {/* <th scope="col">#</th> */}
                    <th scope="col">Name</th>
                    <th scope="col">Display Name</th>
                    <th scope="col">Email</th>
                    <th scope="col"></th>
                    <th>Action</th>

                  </tr>
                  :
                  <tr>
                    {/* <th scope="col">#</th> */}
                    <th scope="col">Title</th>
                    <th scope="col">Owner</th>
                    <th scope="col">Discipline</th>

                    {selectType==='tribe'?
                      <></>
                      :
                      <th scope="col">Held On</th>
                    }
                    <th scope="col">CreatedAt</th>
                    <th scope="col">UpdatedAt</th>
                    <th scope="col">Visibility</th>
                    <th>Action</th>
                  </tr>
                  }
                </thead>
                <tbody>
                  { selectType==='user'?
                    usersData && usersData.map((val,index)=>(
                      <tr>
                        <td>{val.first_name+" "+val.last_name}</td>
                        <td>{val.display_name}</td>
                        <td>{val.email}</td>
                        <td>
                          <div className="action" style={{fontSize: "12px"}}>
                          <span>
                              <label className="switch">
                                <input type="checkbox" 
                                  defaultChecked={val.status==='active'?false:true} 
                                  onChange={(e)=>{
                                    handleChangeUserStatus(val._id,val.status);
                                  // console.log("Dashboard -> val", val)
                                  }}
                               />
                                <span className="slider round" />
                              </label>
                            </span>
                          </div>
                       
                        </td>
                        <td>
                        <div>
                      <span style={{cursor:'pointer'}} onClick={(e)=>onDelete(e,val)}>  <i className="fas fa-trash"></i></span>
                          </div>
                        </td>
                    
                      </tr>
                    ))
                    :
                  selectType==='tribe'?
                    tribeData && tribeData.map((val,index)=>(
                      val.event_type===selectType?
                      <tr key={index}>
                        {/* <th scope="row">{index+1}</th> */}
                        <td>{val.title}</td>
                        <td>{val.owner_name}</td>
                        <td>{val.discipline}</td>
                        {/* <td>{moment(val.date).format('LL')}</td> */}
                        <td>{moment(val.createdAt).format('LL')}</td>
                        <td>{moment(val.updatedAt).format('LL')}</td>
                        <td>
                          <div className="action" style={{fontSize: "12px"}}>
                          <span>
                              <label className="switch">
                                <input type="checkbox" 
                                  defaultChecked={val.status==='publish'?false:true} 
                                  onChange={(e)=>{
                                    handleChangeStatus(val._id,val.status);
                                  // console.log("Dashboard -> val", val)
                                  }}
                               />
                                <span className="slider round" />
                              </label>
                            </span>
                          </div>
                        </td>
                        <td>
                        <div>
                        <span style={{cursor:'pointer'}} onClick={(e)=>onDelete(e,val)}>  <i className="fas fa-trash"></i></span>
                          </div>
                        </td>
                      </tr>
                      :
                      <></>
                      ))
                    :
                    eventsData && eventsData.map((val,index)=>(
                      val.event_type===selectType?
                      <tr key={index}>
                        {/* <th scope="row">{index+1}</th> */}
                        <td>{val.title}</td>
                        <td>{val.owner_name}</td>
                        <td>{val.discipline}</td>
                        <td>{moment(val.date).format('LL')}</td>
                        <td>{moment(val.createdAt).format('LL')}</td>
                        <td>{moment(val.updatedAt).format('LL')}</td>
                        <td>
                        {/* <div className="row search-results"> */}
                          <div className="action" style={{fontSize: "12px"}}>
                          <span>
                              <label className="switch">
                                <input type="checkbox" 
                                  defaultChecked={val.status==='publish'?false:true} 
                                  onChange={(e)=>{
                                    // setStatus()
                                    handleChangeStatus(val._id,val.status);
                                  console.log("Dashboard -> val", e.target.value?false:true)
                                    
                                  }}
                               />
                                <span className="slider round" />
                              </label>
                            </span>
                          </div>
                        {/* </div> */}
                        </td>
                        <td>
                        <div>
                        <span style={{cursor:'pointer'}} onClick={(e)=>onDelete(e,val)}>  <i className="fas fa-trash"></i></span>
                          </div>
                        </td>
                      </tr>
                      :
                      <></>
                  ))}
                </tbody>
              </table>
              </div>
            </div>
          </section>
        </div>
      </form>
    </div>
  </>
    );
};

export default Dashboard;

import React, { useEffect, useState,useRef } from "react";
import PageHeading from "../../components/pageHeading";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import { secureApiCall, ApiCall } from "../../services/conf/apiCall";
import {regions,disciplines} from "../../constants/global";
import defaultFeatureImage from "../../assets/img/feature_image.jpg";
import {getStartDateOfWeek,getTodayDate,getStartingDateOfMonth} from "../../helpers/utils"
import moment from "moment";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
const EventListByTags = (props) => {

  // const getTokenData=getUserData()
  const params=useParams();
  console.log("EventListByTags -> params", params)
  let tag=params.tags;
  
  const [eventData, setEventData] = useState([]);
  const [regionData,setRegionData]=useState(regions);
  const[disciplineData,setDisciplineData]=useState(disciplines);
  const [durationFilter,setDurationFilter]=useState("");
  const [eventTypeFilter,setEventTypeFilter]=useState("event");
  const [disciplineFilter,setDisciplineFilter]=useState("");
  const [regionFilter,setRegionFilter]=useState("");
  const getAllEventsUrl = process.env.REACT_APP_API_ENDPOINT + "/list-events";

  
  const [currentPage,setCurrentPage]=useState(1);
  const [pageSize,setPageSize]=useState(12);
  const [pages,setPages]=useState(10);
  const [alleventData, setAllEventData] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect( () => {
    secureApiCall(`${getAllEventsUrl}?tags=${tag}`, "get")
      .then((response) => {
        console.log("==>",response);
          setCurrentPage(0)
        const pagedItems = paginate(response.payload,pageSize);
        setPages(pagedItems)
         setEventData(pagedItems[0]);
         setAllEventData(response.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [tag]);

  const paginate = (arr, size) =>{
    return arr.reduce((acc, val, i) => {
      let idx = Math.floor(i / size)
      let page = acc[idx] || (acc[idx] = [])
      page.push(val)
  
      return acc
    }, [])
  }

  const handleEventFilters=()=>{
      secureApiCall(`${getAllEventsUrl}?duration=${durationFilter}&event_type=${eventTypeFilter}&discipline=${disciplineFilter}&region=${regionFilter}&tags=${tag}`, "get")
      .then((response) => {
        console.log(response);
        setEventData(response.payload);
      })
      .catch((err) => {
        console.log(err);
      }); 
  }

  const fetchMoreData = () => {
    const pageIndex = currentPage + 1;
    const currenteventData =  pages[pageIndex];
    if(currenteventData){
      setEventData([...eventData,...currenteventData]);
      setCurrentPage(pageIndex);
      if(currenteventData.length < pageSize){
          setHasMore(false);
      }  
    }else{
      setHasMore(false);

    }
    
}

  // let eventType=eventData && eventData.map((val)=>val.event_type);
  return (
    <>
      <Header></Header>
      <PageHeading title={`Tag / ${tag}`}></PageHeading>
      <section className="main bg-transparent">
        <div className="steps-area">
          <div className="steps-form">
            <div className="row">
            <div className="col-xs-12 col-12">
                <ul className="filter-options">
                  <li>
                    <div className="form-group">
                      <label>Select Date </label>
                      <div className="select-wrap">
                        <i className="fas fa-calendar" />
                        <select name="duration" id className="form-control" onChange={(e)=>setDurationFilter(e.target.value)}>
                          <option selected value="">&nbsp;Select one</option>
                          <option value={getTodayDate()} >Today</option>
                          <option value={getStartDateOfWeek(new Date())}>This week</option>
                          <option value={moment(getStartingDateOfMonth(new Date())).format('L')}>This Month</option>
                          <option value={'all'}>All</option>
                        </select>

                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label>Event type</label>
                      <div className="select-wrap">
                      {eventTypeFilter==='event' &&
                        <i className="fas fa-flag" />
                      }
                      {eventTypeFilter ==='training' &&
                       <i className="fas fa-flag-checkered" />
                      }
                        <select name="event_type" id className="form-control"  onChange={(e)=>setEventTypeFilter(e.target.value)}>
                          <option value="event" selected>Event</option>
                          <option value="training" >Training</option>
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label>Discipline</label>
                      <div className="select-wrap">
                      {disciplineFilter == ''  && <i className="fas fa-trophy"></i> }
                      {disciplineFilter == 'Running'  && <i className="fas fa-running" /> }
                      {disciplineFilter == 'Athlons' && <i className="fas fa-football-ball" />}
                      {disciplineFilter == 'Cycling' && <i className="fas fa-biking"/>}
                      {disciplineFilter == 'Swimming' && <i className="fas fa-swimmer" />}
                        <select name="discipline" id className="form-control" onChange={(e)=>setDisciplineFilter(e.target.value)}>
                          <option selected value="">&nbsp;All</option>
                          {disciplineData && disciplineData.map((discipline,index)=>(
                            <option key={index}>{discipline.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group">
                      <label>Region</label>
                      <div className="select-wrap">
                        <i className="fas fa-map-marker-alt"/>
                        <select name="region" id className="form-control"  onChange={(e)=>setRegionFilter(e.target.value)}>
                          <option value="">&nbsp;Select one</option>
                          {regionData&&regionData.map((region,index)=>(
                            <option key={index}>{region.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="form-group" onClick={()=>handleEventFilters()}>
                      <label className="empty-label">&nbsp;</label>
                      <button className="btn btn-primary btn-block">
                        <span className="fas fa-search" /> Search
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
              {eventData && eventData.length==0?
                  <div className="search-title">
                   Loading Data
                  </div>
                :
                  <div className="search-title">
                    Showing {eventData && eventData.length} Results
                  </div>
                }
              </div>
            </div>

            
            <InfiniteScroll
    dataLength={eventData && eventData.length}
    next={fetchMoreData}
    hasMore={true}
    loader={<h4>Loading...</h4>}
  >
  <div className="row">
              {eventData &&
                 eventData.reverse().map((value, index) => (
                  <div className="col-xs-12 col-sm-6 col-md-4" key={index}>
                    <a
                      // href={`/event/${value._id}`}
                      
                      href={value.event_type==="training" ?
                              `/training/${value.slug}`
                              : 
                              value.event_type === "tribe" ?
                              `/tribes/${value.slug}`
                              :`/event/${value.slug}`
                            }
                      className="event-box"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <div className="event-img">
                     {value.banner.length===0 ?
                          <img
                            src={defaultFeatureImage} 
                            className="img-responsive"
                            alt=""
                          />
                          :  
                            <img
                            src={process.env.REACT_APP_IMG_URL_ENDPOINT+value.banner[0].featureImage } 
                            className="img-responsive"
                            alt=""
                          />
                        }
                      </div>
                      <div className="event-description">
                        <h5>
                        <div className="ellipsis-text" style={{display:'inline-block'}}>
                                {value.title}
                            </div>
                          </h5>
                        {value.event_type==='tribe'?
                           <div>
                           <ul>
                             <li> 
                               {value.discipline === "Running"? 
                                 <span>
                                   <i className="fas fa-running" />
                                   &emsp;{value.discipline}
                                 </span>
                                 :value.discipline==="Swimming"?
                                 <span>
                                   <i className="fas fa-swimmer" />
                                   &emsp;{value.discipline}
                                 </span>
                                 :value.discipline==="Cycling"?
                                 <span>
                                   <i className="fas fa-biking" />
                                   &emsp;{value.discipline}
                                 </span>
                                 :value.discipline==="Athlons"?
                                 <span>
                                   <i className="fas fa-football-ball" />
                                   &emsp;{value.discipline}
                                 </span>:<></>
                               }
                             </li>
                           </ul>
                         </div>
                      
                        
                        :                      
                        <div>
                          <ul>
                            <li>
                              <i className="fas fa-calendar" />
                              &emsp;{moment(value.date).format('ll')}
                            </li>
                            <li>
                              {value.discipline === "Running"? 
                                <span>
                                  <i className="fas fa-running" />
                                  &emsp;{value.discipline}
                                </span>
                                :value.discipline==="Swimming"?
                                <span>
                                  <i className="fas fa-swimmer" />
                                  &emsp;{value.discipline}
                                </span>
                                :value.discipline==="Cycling"?
                                <span>
                                  <i className="fas fa-biking" />
                                  &emsp;{value.discipline}
                                </span>
                                :value.discipline==="Athlons"?
                                <span>
                                  <i className="fas fa-football-ball" />
                                  &emsp;{value.discipline}
                                </span>:<></>
                              }
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt" />
                              &emsp;
                              <div className="related-ellipsis-text" style={{display:'inline-block'}}>
                              {value.location}
                              </div>
                            </li>
                          </ul>
                        </div>
                        }
                      </div>
                    </a>
                  </div>
                ))}
            </div>

  </InfiniteScroll>
          
          </div>
        </div>
      </section>
      
      <Footer></Footer>
    </>
  );
};
export default EventListByTags;

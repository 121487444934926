import React from 'react';

const PageHeading = ( props)=>{
  return (
    <section className="bg-orange">
      <div className="title-white">{props.title}</div>
    </section>
  );
}

export default PageHeading;

import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Markup } from 'interweave';
import ImageGallery from 'react-image-gallery';
import moment from "moment";

import "../event/event.scss";
import "../eventList/eventList.scss";
import PageHeading from "../../components/pageHeading";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import isAuthenticated from "../../router/isAuthenticated";
import { secureApiCall, ApiCall } from "../../services/conf/apiCall";
import {getUserData} from "../../helpers/utils"
// import userImg from "../../assets/img/follower3.png"
import userImg from "../../assets/img/default-user-3.jpg";
import DialogBox from "../../components/dialog/dialogBox";
import "react-image-gallery/styles/scss/image-gallery.scss";
import defaultCoverImage from "../../assets/img/cover_image.jpg";
import defaultThumbnailImage from "../../assets/img/thumbnail_image.jpg";
import defaultFeatureImage from "../../assets/img/feature_image.jpg";
let nl2br=require('nl2br');

const Event = (props) => {

  let history = useHistory();
  let params = useParams();
  let isToken=localStorage.getItem("authToken");
  let userId=getUserData()._id;
  let userSlug = getUserData().user_slug;

  const [eventData, setEventData] = useState([]);
  const [joinedUsersData,setJoinedUsersData]=useState([]);
  const [interestedUsers,setInterestedUsers]=useState([]);
  const [viewJoinedUsers,setViewJoinedUsers]=useState(true);
  const [viewInvitedUsers,setViewInvitedUsers]=useState(false);
  const [carousalImages,setCarousalImages]=useState([]);
  const [relatedEventData, setRelatedEventData] = useState([]);
  const [interestedBtn,setInterestedBtn]=useState("");
  const [regBtnStatus,setRegBtnStatus]= useState("");
  const [leaveBtnStatus,setLeaveBtnStatus]= useState("");
  const [isDisabled,setIsDisabled]=useState(false);
  const [isDisabledInterestedBtn,setIsDisabledInterestedBtn] =useState(false);
  const [intBtnStatus,setIntBtnStatus] = useState(null);
  

  // let getEventsUrl = process.env.REACT_APP_API_ENDPOINT + `/list-events/${params.id}`;
  // let getEventsSecureUrl = process.env.REACT_APP_API_ENDPOINT + `/api/list-events/${params.id}`;
  let getEventsUrl = process.env.REACT_APP_API_ENDPOINT + `/list-events/${params.slug}`;
  let getEventsSecureUrl = process.env.REACT_APP_API_ENDPOINT + `/api/list-events/${params.slug}`;
  let joiningRequest =process.env.REACT_APP_API_ENDPOINT + `/api/request-join-event`;
  let InterestRequest =process.env.REACT_APP_API_ENDPOINT + `/api/interest-send-event`;
  // let getJoinedUsersUrl=process.env.REACT_APP_API_ENDPOINT+`/event-joined-users/${params.id}`;
  // let getInterestedUsersUrl=process.env.REACT_APP_API_ENDPOINT+`/event-interested-users/${params.id}`;
  let getJoinedUsersUrl=process.env.REACT_APP_API_ENDPOINT+`/event-joined-users/${params.slug}`;
  let getInterestedUsersUrl=process.env.REACT_APP_API_ENDPOINT+`/event-interested-users/${params.slug}`;
  let friendRequestUrl = process.env.REACT_APP_API_ENDPOINT + "/api/friends-request";
  let relatedEventUrl = process.env.REACT_APP_API_ENDPOINT + "/list-events";
  let leaveEventUrl=process.env.REACT_APP_API_ENDPOINT + "/api/leave-event";
  let notInterestedUrl=process.env.REACT_APP_API_ENDPOINT + "/api/not-interested";
 
  let url;
  let eventType;
  let disciplineType;
  let pathName =props.location.pathname;

  useEffect(() => {
    if(isToken===null){
      url=getEventsUrl;
    }else{
      url=getEventsSecureUrl;
    }
    secureApiCall( url, 'get')
    .then( response =>{
    console.log("Event -> response", response)
      
      if(! response.err){
        console.log("----",response.payload);   
        eventType=response.payload.event_type;
        disciplineType=response.payload.discipline;
        let startDate=response.payload.date;

        // let startDate ="2022-02-26";
        secureApiCall(`${relatedEventUrl}?event_type=${eventType}&discipline=${disciplineType}&startDate=${startDate}`, "get")
            .then((response) => {
              console.log("related events",response);
              const data = [...response.payload];
              data.length = 8;

              setRelatedEventData(data);
            })
            .catch((err) => {
            console.log(err);
        });
        setInterestedBtn(response.payload.alreadyInterested?"Not Interested":"Interested");
        if(response.payload.isPending){
          setRegBtnStatus("Pending");
          setIsDisabled(true);
        }else{
          setRegBtnStatus('Register Now');
          setIsDisabled(false);
        }
        setIntBtnStatus(response.payload.alreadyJoined)
        console.log("Event -> eventType", eventType)
        setEventData([response.payload]);
        if(response.payload.banner.length===0){
          carousalImages.push({
            original:defaultCoverImage ,
            thumbnail:defaultThumbnailImage,
          })
        }
        response.payload.banner.map((image)=>{
            carousalImages.push({
              original:process.env.REACT_APP_IMG_URL_ENDPOINT+ image.coverImage ,
              thumbnail:process.env.REACT_APP_IMG_URL_ENDPOINT+ image.thumbnailImage,
            })
        })
        setCarousalImages(carousalImages);
      }
    })
    .catch( err=>{
      console.log( err);
    })

    secureApiCall(getJoinedUsersUrl,'get')
        .then((response)=>{
            console.log(response);
            if(!response.err){
                setJoinedUsersData(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        });

    secureApiCall(getInterestedUsersUrl,'get')
        .then((response)=>{
            console.log(response);
            if(!response.err){
              setInterestedUsers(response.payload);
            }
        }).catch((err)=>{ 
            console.log(err);
        });
  }, []);

  const handelJoin = (event_id,event_type,externalLink)=>{
    let res=window.confirm(`Do you want to register for this ${event_type}?`);
    if(res){
      if(!isAuthenticated()){
        history.push('/login');
      }
      let headers = {
        "Content-type": "application/json",
      };
      let formData = {
        event_id: event_id
      };
      let formDataString = JSON.stringify(formData);
      secureApiCall(joiningRequest, "post", formDataString, headers)
      .then( res =>{
      console.log("handelJoin -> res", res)
        if( !res.err){
          setRegBtnStatus("Leave Event");
          setIsDisabled(true);
          setIntBtnStatus(true);
          toast.success("Your register request raised successfully");
          if(!externalLink == ""){
            setTimeout(()=>{
              // let showAlert= window.confirm(`Do you want to open external link`);
              // if(showAlert){
                window.open(externalLink,"_blank");
              // }
            },500)
          }   
          }else{  
          toast.error(res.msg);
        }

      })
      .catch( err =>{
        console.log( err);
        toast.error("Something went wrong");
        setRegBtnStatus("Register Now");
        setIsDisabled(false);
        setIntBtnStatus(false);
      });
      }else{
        console.log("result: ",res);
      }
  }

  const handelInterest = (event_id,event_type) =>{
    let res=window.confirm(`Do you want to express interest in this ${event_type}?`);
    if(res){
    if (!isAuthenticated()) {
      history.push("/login");
    }
    
    let len=interestedUsers.length;
    let headers = {
      "Content-type": "application/json",
    };
    let formData = {
      event_id: event_id,
    };
    let formDataString = JSON.stringify(formData);
    secureApiCall(InterestRequest, "post", formDataString, headers)
      .then((res) => {
        if (!res.err) {
          setInterestedBtn("Not Interested")
          setIsDisabledInterestedBtn(true);
          interestedUsers.splice(len+1,0,interestedUsers);
          toast.success("Your interest raised successfully");
          setInterestedUsers([...interestedUsers]);

        }else{
          toast.error(res.msg);
        }

      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
    }else{
      console.log("result: ",res);
    }
  }

  const handleAddFriend=(friend_id,position)=>{
    let requestData = {
      friend_id: friend_id,
    };
    secureApiCall(friendRequestUrl, "post", JSON.stringify(requestData), {
      "Content-type": "application/json",
    })
      .then((response) => {
        if (!response.err) {
           interestedUsers.splice(position, 1);
           toast.success( "Friend request raise");
           setInterestedUsers([...interestedUsers]);
        }else{
          toast.error(response.msg);
        }
        console.log("--response--", response);
      })
      .catch((err) => {
         toast.error("something went wrong ");
        console.log(err);
      });
  };
  const handleLeaveEvent=(eventId,event_type)=>{
    let confirm=window.confirm(`Do you want to leave this ${event_type}?`);
    if(confirm){
      secureApiCall(`${leaveEventUrl}?event_id=${eventId}`,'get')
      .then((response) => {
        setLeaveBtnStatus("Register Now");
        setIsDisabled(true);
        setIntBtnStatus(false);
        toast.success(response.msg);
        // history.push("/events");
      })
      .catch((err) => {
         toast.error("something went wrong ");
        console.log(err);
      });
    }
  }

  const handleNotInterestedEvent=(eventId,event_type)=>{
    let confirm=window.confirm(`Do you want to remove your interest from this ${event_type}?`);
    if(confirm){
      secureApiCall(`${notInterestedUrl}?event_id=${eventId}`,'get')
      .then((response) => {
        setInterestedBtn("Interested")
        setIsDisabledInterestedBtn(true);
        toast.success(response.msg);
        // history.push("/events");
      })
      .catch((err) => {
         toast.error("something went wrong ");
        console.log(err);
      });
    }
  }
  

  // Change tab css of Interested and Joined user 
  const [isActive,setIsActive]=useState(false);
  let cssAdd={borderBottom:"2px solid rgb(255, 81, 0)",paddingBottom:"5px"};
  let cssChange={borderBottom:"none"};

  return (
    <>
      <Header></Header>
      {
        eventData  && eventData.map((val,index)=>(
          <PageHeading key={index} title={val.title}></PageHeading>     
        ))
      }
      <section className="main bg-transparent">
        <div className="steps-area">
          {/* {eventData.map((value) => ( */}
          <div className="steps-form single-event">
            <div className="row">
              <div className="col-xs-12 col-md-6 col-lg-8">
              <div
                  id="myCarousel"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner" role="listbox">
                    <ImageGallery 
                      items={carousalImages}
                      showFullscreenButton={false}
                      showPlayButton={false}
                    ></ImageGallery>
                  </div>
                </div>
               {eventData && eventData.map((val,index)=>(
               <>
                <div className="search-title">{val.event_type} Description</div>
                {/* <p>{value.description}</p> */}
                <p key={index}><Markup content={nl2br(val.description) }/>
                </p>
                </>
               ))}
                {/* <DialogBox></DialogBox> */}
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4">
                <div className="event-details">
                  {/* <h5>Event Details</h5> */}
               <h5>{eventData && eventData.map(val=>val.title)}</h5>
                  {eventData && eventData.map((event,index)=>(
                   <>
                    <span><i style={{paddingRight:15}} className="fas fa-calendar-day" />{moment(event.date).format('ll')}, {(event.start_time).replace(" ","")} - {(event.end_time).replace(" ","")}</span><br/>
                    <span ><i style={{paddingRight:15}} className="fas fa-map-marker-alt" />{event.location}</span><br/>
                   <ul style={{marginTop:"20px"}}>
                    <li>
                      {event.discipline === "Running"? 
                          <span>
                            <i className="fas fa-running" />
                            {event.discipline}
                          </span>
                          :event.discipline==="Swimming"?
                          <span>
                            <i className="fas fa-swimmer" />
                            {event.discipline}
                          </span>
                          :event.discipline==="Cycling"?
                          <span>
                            <i className="fas fa-biking" />
                            {event.discipline}
                          </span>
                          :event.discipline==="Athlons"?
                          <span>  
                            <i className="fas fa-football-ball" />
                            {event.discipline}
                          </span>:<></>
                      }
                    </li>
                    <li>

                    {event.discipline === "Running"? 
                          <span>
                            <i className="fas fa-solid fa-road" />
                            {event.discipline_type}
                          </span>
                          :event.discipline==="Swimming"?
                          <span>
                            <i className="fas fa-swimmer" />
                            {event.discipline_type}
                          </span>
                          :event.discipline==="Cycling"?
                          <span>
                            <i className="fas fa-biking" />
                            {event.discipline_type}
                          </span>
                          :event.discipline==="Athlons"?
                          <span>  
                            <i className="fas fa-football-ball" />
                            {event.discipline_type}
                          </span>:<></>
                      }

                    
                    </li>
                    <li>
                    <i className="fas fa-route"></i> {event.total_distance} Km
                    </li>
                    <li>
                    <i className="fas fa-hourglass"></i> {event.time} Minutes
                    </li>
                    <li>
                    <i className="fas fa-tachometer-alt"></i> {event.average_speed} KMPH
                    </li>
                    <li>
                      {event.food_stop? 
                        <> <i className="fas fa-stop-circle" ></i>{event.food_stop}</>
                      :
                        <></> 
                      }
                    </li>
                    {isToken===null?
                      <>
                        { event.map_file==""?
                          <></>
                          :
                          <>
                            {/* <li>
                              <i className="fas fa-download"></i>
                              <a onClick={()=>{
                                alert("Sorry! You can not download map. You need to login first. Thank You");
                                history.push("/login")
                              }}>
                                <span>Download Map</span>
                              </a>
                            </li> */}
                          </>
                        }
                      </>
                      :
                      <>
                        { !event.map_file ?
                          <></>
                          :
                          <>
                            <li>
                              <i className="fas fa-download"></i>
                              <a href={process.env.REACT_APP_IMG_URL_ENDPOINT+event.map_file}  style={{cursor:'pointer'}}>
                                  Download Map {event.map_file}
                                </a>
                            </li>
                          </>
                        }
                      </>
                    }
                    <li> 
                      <i className="fas fa-user"></i>
                        <a href={event.owner_name_slug === userSlug ?"/profile": `/u/${event.owner_name_slug}`} style={{cursor:'pointer'}} >
                          <span>{event?.userInfo?.[0].first_name}</span>
                          </a>
                    </li>
                  </ul>
                  </>
                  ))}
                {
                  isToken===null || isToken===""?
                  <div className="action">
                    <button className="btn btn-secondary-outline" onClick={()=>{history.push(`/login/?retUrl=${pathName}`)}}>Register Now</button>
                    <button className="interested-btn interested-btn-primary" onClick={()=>{history.push(`/login/?retUrl=${pathName}`)}}>Interested</button>
                  </div>
                  :
                  eventData && eventData.map((val,index)=>(
                   <div className="action">
                     {
                      val && val.isEditable && (
                        <a href={`/edit-event/${val.slug}`} className="btn btn-secondary-outline">{`Edit ${val.event_type}`}</a>
                      ) 
                     }
                     {
                        val && !val.isEditable && (
                          <>
                          {
                            val.alreadyJoined ?  (
                              <button className="btn btn-secondary-outline" onClick={() =>handleLeaveEvent(val._id,val.event_type)} disabled={isDisabled}>{isDisabled? leaveBtnStatus : `Leave ${val.event_type}`}</button>
                            ) : (
                              <button className="btn btn-secondary-outline" onClick={() => {handelJoin(val._id,val.event_type, val.external_link);}} disabled={isDisabled} >{regBtnStatus}</button>
                            )
                          }
                          </>
                        )

                     }
                     {
                       val && !val.isEditable && (
                         <>
                         {
                          intBtnStatus?<></>:
                           val.alreadyInterested ? (
                            <button className="interested-btn-active interested-btn-active-primary" onClick={() => {handleNotInterestedEvent(val._id,val.event_type);}} disabled={isDisabledInterestedBtn}>{interestedBtn}</button>
                          ):(
                            <button className="interested-btn interested-btn-primary" onClick={() => {handelInterest(val._id,val.event_type);}} disabled={isDisabledInterestedBtn}>{interestedBtn}</button>
                          )
                         }
                         </>
                       )
                     }
                   </div>   
                ))}
             </div>
            { 
            joinedUsersData =="" && interestedUsers ==""?
               (<></>)
               : <div>
                  <div className="event-details event-details-joined">
                    <div style={{display:"flex",justifyContent:"space-evenly"}}>
                    {joinedUsersData?
                      <a  className="text-black " data-toggle="tab"  style={{cursor:'pointer'}}>
                        <span className="jo_span "
                           onClick={()=>{
                             setViewJoinedUsers(true);
                             setViewInvitedUsers(false)
                             setIsActive(false);
                            }}
                            style={isActive?cssChange:cssAdd}
                          id="jo_span"
                        >
                          {eventData.map((val)=>(
                            val.total_joined==0 ? <>Joined</> : <>Joined ({val.total_joined})</>
                          ))}
                        </span>
                      </a>
                    :(<></>)}
                    {interestedUsers? 
                      <a className="text-black" data-toggle="tab"  style={{cursor:'pointer'}}>
                        <span
                        className="in_span"
                           onClick={()=>{
                             setViewInvitedUsers(true);
                             setViewJoinedUsers(false);
                             setIsActive(true)
                            }}
                            style={isActive?cssAdd:cssChange}
                            id="in_span"
                        >
                          {eventData.map((val)=>(
                          val.total_interested==0 ? <>Interested</> : <>Interested ({val.total_interested})</>
                        ))}
                        </span>
                      </a>
                    :(<></>)}
                    </div>
                  <div className="tab-content">
                  {viewJoinedUsers 
                      ? 
                      (
                        <div
                        className="join-interested-list join-interested-list1 tab-pane fade in active"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="list-ul list-ul1">
                            {joinedUsersData && joinedUsersData.map((user,index)=>(
                              userId===user.user_id
                              ?
                              <>
                              <li key={index}>
                                <div className="singlebox singlebox1">
                                  <a href={user.user_name_slug === userSlug ?"/profile": `/u/${user.user_name_slug}`}>
                                    <img src={userImg} alt className="featured-img" />
                                    <span className="clientname">{user.user_name}</span>
                                  </a>
                                </div>
                              </li>
                              </>
                              :
                              <li key={index}>
                                <div className="singlebox singlebox1">
                                  <a href={user.user_name_slug === userSlug ?"/profile": `/u/${user.user_name_slug}`}>
                                    <img src={userImg} alt className="featured-img" />
                                    <span className="clientname">{user.user_name}</span>
                                  </a>
                                  <span className="usr-icon"> 
                                  {isToken===null?<></>:
                                    <a href="#">
                                      <i className="fas fa-user-plus" onClick={()=>{handleAddFriend(user.user_id,index)}} />
                                    </a>
                                  } 
                                  </span>
                                </div>
                              </li>
                            ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      )
                      :(<></>)}
                    {viewInvitedUsers?
                      (
                        <div
                        className="join-interested-list join-interested-list1 tab-pane fade in active"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="list-ul list-ul1">
                            {interestedUsers && interestedUsers.map((user,index)=>(
                              userId===user.user_id?
                              <>
                                <li>
                                <div className="singlebox singlebox1">
                                  <a href={user.user_name_slug === userSlug ?"/profile": `/u/${user.user_name_slug}`}>
                                    <img src={userImg} alt className="featured-img" />
                                    <span className="clientname">{user.user_name}</span>
                                  </a>
                                </div>
                              </li>
                            </>:
                            <li>
                                <div className="singlebox singlebox1">
                                  <a href={user.user_name_slug === userSlug ?"/profile": `/u/${user.user_name_slug}`}>
                                    <img src={userImg} alt className="featured-img" />
                                    <span className="clientname">{user.user_name}</span>
                                  </a>
                                  <span className="usr-icon">
                                    {isToken===null?<></>:
                                      <a href={user.user_name_slug === userSlug ?"/profile": `/u/${user.user_name_slug}`}>
                                        <i className="fas fa-user-plus" onClick={()=>{handleAddFriend(user.user_id,index)}} />
                                      </a>
                                    }
                                  </span>
                                </div>
                              </li>
                            ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      )
                      :(<></>)
                    }
                </div>
                  {/* {eventData.map((val)=>(
                    <div className="totalUsers">
                      { val.total_joined==0 ? <div></div> : <div>Total Joined ({val.total_joined})</div> }
                      { val.total_interested==0 ? <div></div> : <div>Total Interested ({val.total_interested})</div> }
                    </div>
                    )
                  )} */}
            </div>
                 
                </div> 
            }
                {/* <div className="col-xs-6 col-md-12 col-lg-12" >
                  <div className="event-details">
                
                    <h5>Joined Participant</h5>
                    
                        {joinedUsersData.map((user,index)=>(
                          userId===user.user_id?
                          <ul key={index}>
                              <li><label>{index+1}.&nbsp;{user.user_name}</label></li>
                              // {/* <button className="new-btn new-btn-secondary"  onClick={()=>{handleAddFriend(user.user_id,index)}}>Add Friend</button> 
                              <button className="new-btn new-btn-secondary">View Profile</button>
                          </ul>
                          :
                          <ul key={index}>
                              <li><label>{index+1}.&nbsp;{user.user_name}</label></li>
                              <button className="new-btn new-btn-secondary"  onClick={()=>{handleAddFriend(user.user_id,index)}}>Add Friend</button>
                          </ul>
  
                        ))}
                  </div>
                </div>
                <div className="col-xs-6 col-md-12 col-lg-12">
                <div className="event-details" style={{paddingLeft:"10px"}}>
                    <h5>Interested&nbsp;Participant</h5>
                      {interestedUsers.map((user,index)=>(
                      userId===user.user_id?
                        <ul key={index}>
                            <li><label>{index+1}.&nbsp;{user.user_name}</label></li>
                            {/* <button className="new-btn new-btn-secondary"  onClick={()=>{handleAddFriend(user.user_id,index)}}>Add Friend</button> 
                            <button className="new-btn new-btn-secondary">View Profile</button>
                        </ul>
                        :
                        <ul key={index}>
                            <li><label>{index+1}.&nbsp;{user.user_name}</label></li>
                            <button className="new-btn new-btn-secondary"  onClick={()=>{handleAddFriend(user.user_id,index)}}>Add Friend</button>
                        </ul>
                      )
                    )
                    }
              
                    </div>
                </div>
               */}
              </div>
            </div>
            {relatedEventData && relatedEventData.length===1?
              <></>
            :
              eventData && eventData.map((val,index)=>(
              <div className="row">
                <div className="col-xs-12">
                  <div className="search-title">Related {val.event_type}s</div>
                </div>
              </div>
              ))
            }
            {eventData && eventData.map((val,index)=>(
            <div className="row">
                  {relatedEventData && relatedEventData.map((relEvent,index)=>(
                      relEvent._id === val._id
                      ?
                      <></>
                      :
                      <div key={index} className="col-xs-12 col-sm-6 col-md-3">
                    <a href={`/event/${relEvent.slug}`} className="event-box">
                      <div className="event-img">
                      {relEvent.banner.length===0 ?
                          <img
                            src={defaultFeatureImage} 
                            className="img-responsive"
                            alt=""
                          />
                          :  
                            <img
                            src={process.env.REACT_APP_IMG_URL_ENDPOINT+relEvent.banner[0].featureImage } 
                            className="img-responsive"
                            alt=""
                          />
                        }
                      </div>
                      <div className="event-description">
                        <h5>
                        <div className="ellipsis-text" style={{display:'inline-block'}}>
                                {relEvent.title}
                            </div>
                          </h5>
                        <div>
                          <ul>
                            <li>
                              <i className="fas fa-calendar" />{" "}
                              {moment(relEvent.date).format('ll')}
                            </li>
                            <li>
                              <i className="fas fa-clock" />{" "}
                              {relEvent.start_time}
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt"/>{" "}
                              {/* {relEvent.location} */}
                              <span className="related-ellipsis-text" style={{display:'inline-block'}}>
                              {relEvent.location.substr(0,16)}...
                              </span>
                            </li>
                            <li>
                            {relEvent.discipline === "Running"? 
                                <span>
                                  <i className="fas fa-running" />
                                  {relEvent.discipline}
                                </span>
                                :relEvent.discipline==="Swimming"?
                                <span>
                                  <i className="fas fa-swimmer" />
                                  {relEvent.discipline}
                                </span>
                                :relEvent.discipline==="Cycling"?
                                <span>
                                  <i className="fas fa-biking" />
                                  {relEvent.discipline}
                                </span>
                                :relEvent.discipline==="Athlons"?
                                <span>
                                  <i className="fas fa-football-ball" />
                                  {relEvent.discipline}
                                </span>:<></>
                            }
                          </li>
                          </ul>
                        </div>
                      </div>
                    </a>
                  </div>
                 
              ))}
            </div>
          ))}
          </div>
          {/* ))} */}
        </div>
      </section>
    
      <div className="clearfix"></div>  
      <Footer event_type="event"></Footer>
    </>
  );
};

export default Event;

import React from "react";

const Step = (props) => {
  const handelNextClick = () => {
    props.nextStep();
  };

  const handelPrevClick = (e) => {
    props.prevStep();
  };
  return (
    <div className="steps-form create-steps">
      <div className="row search-results">
        <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <label className="text-center">
              Invite your friends to join...
            </label>
            <input
              className="form-control"
              placeholder="Search for friends"
              defaultValue
              type="text"
            />
            <a href="#" className="icon-btn">
              <i className="fas fa-search" />
            </a>
          </div>
          <label className="text-center">
            Recommended Friends / Search Results
          </label>
          <ul>
            <li>
              <div className="img-box">
                <img
                  src={require("../../../assets/img/email-icon.png")}
                  alt=""
                />
              </div>
              <div className="user-detail">
                <h6 className="username">Username</h6>
                <p className="stream">Runner</p>
                <p className="location">Leicester</p>
              </div>
              <label className="customcheck">
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
            </li>
            <li>
              <div className="img-box">
                <img
                  src={require("../../../assets/img/email-icon.png")}
                  alt=""
                />
              </div>
              <div className="user-detail">
                <h6 className="username">Username</h6>
                <p className="stream">Runner</p>
                <p className="location">Leicester</p>
              </div>
              <label className="customcheck">
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
            </li>
            <li>
              <div className="img-box">
                <img
                  src={require("../../../assets/img/email-icon.png")}
                  alt=""
                />
              </div>
              <div className="user-detail">
                <h6 className="username">Username</h6>
                <p className="stream">Runner</p>
                <p className="location">Leicester</p>
              </div>
              <label className="customcheck">
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
            </li>
            <li>
              <div className="img-box">
                <img
                  src={require("../../../assets/img/email-icon.png")}
                  alt=""
                />
              </div>
              <div className="user-detail">
                <h6 className="username">Username</h6>
                <p className="stream">Runner</p>
                <p className="location">Leicester</p>
              </div>
              <label className="customcheck">
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
            </li>
            <li>
              <div className="img-box">
                <img
                  src={require("../../../assets/img/email-icon.png")}
                  alt=""
                />
              </div>
              <div className="user-detail">
                <h6 className="username">Username</h6>
                <p className="stream">Runner</p>
                <p className="location">Leicester</p>
              </div>
              <label className="customcheck">
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
            </li>
          </ul>
          <div className="action">
            <span>
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round" />
              </label>
              <div className="ml-10">Approval Needed</div>
            </span>
            <button className="btn btn-orange" type="submit">
              Invite to Event
            </button>
          </div>
          <div className="text-center">
            <a href="/trainings" className="view-event">
              Skip &amp; View Your Training
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step;

import React,{useState} from "react";
import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const DialogBox=(props)=> {
    const [show, setShow] = useState(true);
  
    const handleClose = () =>{setShow(false);}
    const handleShow = () => setShow(props.show);
    console.log("in react modal",props.show)
    return (
      <>
        {/* <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button>
   */}
        <Modal show={true} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default DialogBox;
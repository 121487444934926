import React from "react";
// import "../../assets/scss/style.scss";
import isAuthenticated from "../../router/isAuthenticated";
import "./publicHeader.scss";

const PublicHeader = (props) => {
  return (  
    <header className="header" id="header">
      <div className="container-fluid">
        <div className="row" id="logo_menu">
          <div className="col-xs-12 col-sm-5 header-btns">
            <a href="/events" className="btn btn-primary">
              <i className="fas fa-star" /> Events
            </a>
            <a href="/trainings" className="btn btn-primary">
              <i className="fas fa-star" /> Trainings
            </a>
          </div>
          <div className="col-xs-12 col-sm-2 header_logo">
            <a className="logo" href="/">
              <img src={require("../../assets/img/logo.jpg")} />
            </a>
          </div>

          {isAuthenticated() ? (
            <div className="col-xs-12 col-sm-5 header-right">

              <a href="/create-event" className="btn btn-primary-outline margin-right">
                <i className="fas fa-plus" /> Create
              </a>
              <a href="/joined-events" className="btn btn-secondary-outline">
              <i className="fas fa-columns"/> Dashboard
              </a>
              {/*<a id="menu-toggle" href="#" className="toggle"><i className="ion-navicon"></i> <span*/}
              {/*class="header_menu_text">Menu</span></a></div>*/}
            </div>
          ) : (
            <div className="col-xs-12 col-sm-5 header-right">  
              <a href="/login" className="btn btn-primary-outline margin-right">
                <i className="fas fa-plus" /> Create
              </a>
              <a href="/login" className="btn btn-secondary-outline">
                <i className="fas fa-lock" /> Login / Register
              </a>
              {/*<a id="menu-toggle" href="#" className="toggle"><i className="ion-navicon"></i> <span*/}
              {/*class="header_menu_text">Menu</span></a></div>*/}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default PublicHeader;

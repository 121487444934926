import React from "react";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";

const TermsPage = (props) => {
  return (
    <div>
      <Header></Header>
      <div>
        <section className="bg-orange">
          <div className="title-white">Terms &amp; Conditions</div>
        </section>
        <section className="main bg-transparent">
          <div className="steps-area">
            <div className="steps-form terms-condition">
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-8 col-lg-offset-2">
                  <div className="search-title">Text Block Open</div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam semper consequat justo, at pharetra turpis ultricies
                    et. Curabitur eget fringilla felis, sed sollicitudin ligula.
                  </p>
                  <p>
                    Etiam semper consequat justo, at pharetra turpis ultricies
                    et. Curabitur eget fringilla felis, sed sollicitudin ligula.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam semper consequat justo, at pharetra turpis ultricies
                    et. Curabitur eget fringilla felis, sed sollicitudin ligula.
                  </p>
                  <div className="panel-group event-accordian" id="accordion">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          className="collapsed"
                          href="#collapse1"
                        >
                          Event Rules
                          <i className="ion-ios-plus-empty" />
                          <i className="ion-ios-minus-empty" />
                        </a>
                      </div>
                      <div id="collapse1" className="panel-collapse collapse">
                        <div className="panel-body">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          className="collapsed"
                          href="#collapse2"
                        >
                          Event Guidelines / Further Info
                          <i className="ion-ios-plus-empty" />
                          <i className="ion-ios-minus-empty" />
                        </a>
                      </div>
                      <div id="collapse2" className="panel-collapse collapse">
                        <div className="panel-body">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          className="collapsed"
                          href="#collapse3"
                        >
                          T-Shirt / Medals
                          <i className="ion-ios-plus-empty" />
                          <i className="ion-ios-minus-empty" />
                        </a>
                      </div>
                      <div id="collapse3" className="panel-collapse collapse">
                        <div className="panel-body">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          className="collapsed"
                          href="#collapse4"
                        >
                          FAQ / Another
                          <i className="ion-ios-plus-empty" />
                          <i className="ion-ios-minus-empty" />
                        </a>
                      </div>
                      <div id="collapse4" className="panel-collapse collapse">
                        <div className="panel-body">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </section>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default TermsPage;

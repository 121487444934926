import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PageHeading from "../../components/pageHeading";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import {secureApiCall} from "../../services/conf/apiCall";
import Spinner from "../../components/spinner";
import { ToastContainer, toast } from "react-toastify";

import "./forgetPassword.scss";

const CheckEmail = (props) => {
  const params=useParams();
  const history=useHistory();
  // console.log("EmailVerify -> params", params.token)
  const [email,setEmail]=useState('')
  const [emailError,setEmailError]=useState(null);
  const [status,setStatus]=useState("Verify");
  const [viewVerifiedButton,setViewVerifiedButton]=useState(false)
  const verifyEmailUrl=process.env.REACT_APP_API_ENDPOINT+`/verify-email`;

  const validateEmail = (email) => {
    if (
      email &&
      /^$|^(([^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+([._-][^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+)*)|(".+"))@((?!-)[^}@]+)(((\[)?[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(\])?)|(([a-zA-Z0-9-]+\.)+[a-zA-Z1]{2,}))$/i.test(
        email.trim() || ""
      )
    ) {
      setEmailError(null);
      return true;
    } else {
      setEmailError("Please enter a valid email address");
      return false;
    }
  };
  const validateForm = () => {
    let error = true;
    if (!validateEmail(email)) {
        error = false;
    }
    return error
  }

  const handleVerifyEmail=()=>{
    console.log("email",email);
    if(validateForm()){
      console.log("handleVerifyEmail -> validateForm()", validateForm())
      setViewVerifiedButton(true);
      secureApiCall(`${verifyEmailUrl}?email=${email}`,'get')
      .then((response)=>{
        // console.log("handleVerifyEmail -> response", response)
        setStatus('Send Link');
        toast.success(response.msg);
        // history.push("/login");
      })
      .catch((err)=>{
        console.log("handleVerifyEmail -> err", err)
        toast.error(err.msg);
      })
    }
  }
  return (
    <>
      <Header></Header>
      <PageHeading title={'Forget Password'}></PageHeading>     
      <section className="main bg-transparent">
        <div className="steps-area">
          <div className="steps-form single-event">
            <div className="row">
              <div style={{textAlign:'center'}}>
                    <div className="col-md-6 col-md-offset-3">
                      <div className="form-group">
                        <label  style={{textAlign:'start'}}>Enter Your Email</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          >
                          </input>
                          {emailError && (
                            <span className="text-danger">
                              {emailError}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-md-6 col-md-offset-3">
                      <div className="form-group">
                        <button className="email-check-btn email-check-btn-primary" onClick={handleVerifyEmail} >{status}</button>
                      </div>
                    </div>
              </div>
            </div>
           </div>
        </div>
      </section>
    
      <div className="clearfix"></div>  
      <Footer></Footer>
    </>
  );
};

export default CheckEmail;

import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Navbar from "../../components/navbar";
import bg1 from "../../assets/img/sample-photos/event1.png";
import bg2 from "../../assets/img/sample-photos/event2.png";
import bg3 from "../../assets/img/sample-photos/event3.png";
import {secureApiCall} from "../../services/conf/apiCall";
import axios from "axios";
const ViewEventPage = (props) => {
  const [eventData, setEventData] = useState([]);

  let url = process.env.REACT_APP_API_ENDPOINT + "/api/list-events";
  useEffect(() => {
    secureApiCall(url,"get").then((response)=>{
      if(!response.err){
        setEventData(response.payload);
      }
    }).catch((err)=>{
      console.log(err)
    })
    // async function fetchData() {
    //   const result = await axios(url);
    //   setEventData(result.data.payload);
    //   console.log(result.data.payload);
    // }
    // fetchData();
  }, []);

  return (
    <div className="wrapper">
      <Header></Header>
      <Navbar></Navbar>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <h1>
            <span>View Events</span>
          </h1>
        </section>
        <section className="filters">
          <div className="container m-0">
            <div className="form-horizontal">
              <div className="form-group">
                <label htmlFor className="col-lg-1 col-md-3 control-label">
                  Filter By
                </label>
                <div className="col-lg-3 col-md-3">
                  <input
                    type="text"
                    className="form-control input-dashed"
                    placeholder="Enter username"
                  />
                </div>
                <div className="col-lg-3 col-md-3">
                  <input
                    type="text"
                    className="form-control input-dashed"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="input-group date" id="datepicker">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select date"
                    />
                    <div className="input-group-addon">
                      <i className="fa fa-calendar" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="row">
            {eventData.map((value) => (
              <div className="col-lg-4 col-sm-6 col-xs-12">
                <a href="#" className="event-box ">
                  <span
                    className="event-img"
                    style={{
                      backgroundImage: `url(${value.banner})`,
                    }}
                  >
                    <span className="participants">
                      <span>35</span>
                      Participants Confirmed
                    </span>
                  </span>
                  <span className="event-description">
                    <h5>{value.title}</h5>
                    <p>Nitesh Bhundia created on {value.start_date}</p>
                  </span>
                </a>
              </div>
            ))}
          </div>
          <div className="load-morebtn text-center">
            <button className="btn btn-orange" type="submit">
              Load More
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ViewEventPage;

import React from 'react'
import  { Redirect } from 'react-router-dom';

export  const getUserData = () => {
  let isToken=localStorage.getItem("authToken");
  if(isToken===null){
    return <Redirect to="/login"></Redirect>
  }
  let token =localStorage.getItem("authToken");
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export  const getAdminData = () => {
  let isToken=localStorage.getItem("adminToken");
  console.log("getAdminData -> isToken", isToken)
  if(isToken===null){
    return <Redirect to="/back-office/login"></Redirect>
  }
  let token =localStorage.getItem("adminToken");
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};


export const removeElement= function (ElementsArray,attrib,value){
  let len=ElementsArray.length;
  while(len--){
    if(ElementsArray[len] 
      && ElementsArray[len].hasOwnProperty(attrib) 
      && (arguments.length >2 && ElementsArray[len][attrib]===value)){
        ElementsArray.splice(len,1);
    }
  }
  console.log(" =======" , ElementsArray);
  return ElementsArray;
}

// export const removeElement= function (ElementsArray,attrib,value){
//   let len=ElementsArray.length;
//   while(len--){
//     if(ElementsArray[len] 
//       && ElementsArray[len].hasOwnProperty(attrib) 
//       && (arguments.length >2 && ElementsArray[len][attrib]===value)){
//         ElementsArray.splice(len,1);
//     }
//   }
//   console.log(" =======" , ElementsArray);
//   return ElementsArray
// }

var weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";



export const dateFormat=function(date){
  return (
    (new Date(date).getFullYear()) + "-" + 
    ("0" + (new Date(date).getMonth() + 1)).slice(-2) + "-" + 
    ("0" + (new Date(date).getDate())).slice(-2))+"  "+(weekday[new Date().getDay()]);
}

export const getTime=function (time){
  return (new Date(time).toLocaleTimeString('en-US',{ hour: '2-digit', minute: '2-digit'})+" "+(weekday[new Date().getDay()]))
}



export const restrictDatepicker=function(dtToday) {
  console.log('dtToday',dtToday);
  let month = (dtToday.getMonth() + 1).toString();
  let day = dtToday.getDate().toString();
  let year = dtToday.getFullYear();
  if ((dtToday.getMonth() + 1) < 10) {
    month = '0' + month.toString();
  }
  if ((dtToday.getDate()) < 10) {
    day = '0' + day.toString();
  }
  return (year + '-' + month + '-' + day);
}

export const getTodayDate=function(){
    let today= new Date().toISOString().slice(0, 10)
    return today;
  }
  
export const getStartDateOfWeek=function(date){
  var iDayOfWeek = date.getDay();
   var iDifference = date.getDate() - iDayOfWeek + (iDayOfWeek === 0 ?  -6:1);
  return new Date(date.setDate(iDifference)).toISOString().slice(0, 10);
} 
export const getStartingDateOfMonth=function(date){
  return new Date(date.getFullYear(), date.getMonth(), 1); 
}

import React, { Component, Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import isAuthenticated from "./isAuthenticated.js";
import NotFoundPage from "../views/notFoundPage";

import HomePage from "../views/homePage";
import LoginPage from "../views/loginPage";
import EventList from "../views/eventList";
import TribesList from "../views/tribesList";
import TrainingList from "../views/trainingList";
import Event from "../views/event";
import Training from "../views/trainings";
import Tribe from "../views/tribes";
import ProfilePage from "../views/profilePage";
import NotificationPage from "../views/notificationPage";
import ViewEventPage from "../views/viewEventPage";
import ViewFriendsPage from "../views/viewFriendsPage";
import AddFriendsPage from "../views/addFriendsPage";
import TermsPage from "../views/termsPage";
//import CreateEvent from "../views/createEvent";
import CreateEventNew from "../views/createEventNew";
import createTraining from "../views/createTraining";
import CreateTribe from "../views/createTribe";
//import CreateTraining from "../views/creatTribe";
import RegisterPage from "../views/registerPage";
import MyEvents from "../views/myEvents"
import MyJoinedEvents from "../views/myJoinedEvents"
import Test from "../views/test";
import EditEvent from "../views/editEvent";
import MyEventDetail from "../views/myEventDetail";
import ViewInviteFriendsPage from "../views/viewInviteFriendsPage";
import DialogBox from "../components/dialog";
import ProfileView from "../views/profileView";
import MyTribes from "../views/myTribes";
import MyTribeDetails from "../views/myTribeDetails";
import EditTribe from "../views/editTribe/editTribe";
import EventListByTags from "../views/eventListByTags";
import InviteMembers from "../components/createTribe/inviteMember";
import EmailVerify from "../views/emailVerify";
import CheckEmail from "../views/forgetPassword/checkEmail";
import NewPassword from "../views/forgetPassword/newPassword";
import Dashboard from "../views/dashboard";
import AdminLoginPage from "../views/adminLoginPage/adminLoginPage";
import AdminRegisterPage from "../views/adminRegisterPage/adminRegisterPage";
import AdminRoute from "./adminRoute";
import Support from "../views/support";
const routes = [
  {
    path: "/",
    component: HomePage,
    exact: true,
    type: "public",
  },
  {
    path: "/dialog",
    component: DialogBox,
    exact: true,
    type: "private",
  },
  {
    path: "/create-tribe",
    component: CreateTribe,
    exact: true,
    type: "private",
  },
  {
    path: "/create-Training",
    component: createTraining,
    exact: true,
    type: "private",
  },
  {
    path: "/create-event",
    component: CreateEventNew,
    exact: true,
    type: "private",
  },
  {
    path: "/edit-event/:slug",
    component: EditEvent,
    exact: true,
    type: "private",
  },
  {
    path: "/edit-event/:slug/:type",
    component: EditEvent,
    exact: true,
    type: "private",
  },
  {
    path: "/events",
    component: EventList,
    exact: true,
    type: "public",
  },
  {
    path:"/tag/:tags",
    component:EventListByTags,
    exact:true,
    type:"public",
  },
  {
    // path: "/event/:id",
    path: "/event/:slug",
    exact: true,
    component: Event,
    type: "public",
  },
  {
    path: "/training/:slug",
    exact: true,
    component: Event,
    type: "public",
  },
  {
    path: "/tribes",
    component: TribesList,
    exact: true,
    type: "public",
  },
  {
    // path: "/tribes/:id",
    path: "/tribes/:slug",
    exact: true,
    component: Tribe,
    type: "public",
  },
  {
    path: "/my-tribes",
    exact: true,
    component: MyTribes,
    type: "private",
  },
  {
    path: "/edit-tribe/:slug",
    exact: true,
    component: EditTribe,
    type: "private",
  },
  
  {
    path: "/my-tribe-details/:slug",
    exact: true,
    component: MyTribeDetails,
    type: "private",
  },
  {
    path: "/my-tribe-details/invite-members/:slug",
    exact: true,
    component:InviteMembers,
    type: "private",
  },

  {
    path: "/trainings",
    component: TrainingList,
    exact: true,
    type: "public",
  },
  {
    // path: "/training/:id",
    path: "/training/:slug",
    exact: true,
    component: Training,
    type: "public",
  },
  {
    path: "/my-events",
    exact: true,
    component: MyEvents,
    type: "private",
  },
  {
    path: "/my-event-detail/:id",
    exact: true,
    component: MyEventDetail,
    type: "private",
  },
  {
    path: "/joined-events",
    exact: true,
    component: MyJoinedEvents,
    type: "private",
  },
  {
    path: "/login",
    component: LoginPage,
    type: "public",
  },
  {
    path: "/register",
    component: RegisterPage,
    type: "public",
  },
  {
    path: "/profile",
    exact: true,
    component: ProfilePage,
    type: "private",
  },
  {
    path: "/notification",
    component: NotificationPage,
    type: "private",
  },
  {
    path: "/view-events",
    component: ViewEventPage,
    type: "public",
  },
  {
    path: "/my-friends",
    component: ViewFriendsPage,
    type: "private",
  },
  {
    path:"/view-invites-friends",
    component:ViewInviteFriendsPage,
    type:"private",
  },
  {
    path: "/add-friends",
    component: AddFriendsPage,
    type: "private",
  },
  {
    path:"/u/:user_slug",
    component: ProfileView,
    type:"private",
  },
  {
    path: "/terms",
    component: TermsPage,
    type: "public",
    exact: true,
  },
  {
    path: "/verify/:token",
    component: EmailVerify,
    type: "public",
    exact: true,
  },
  {
    path: "/forget-password",
    component: CheckEmail,
    type: "public",
    exact: true,
  },
  {
    path: "/reset-password/:token",
    component: NewPassword,
    type: "public",
    exact: true,
  },
  {
    path: "/support",
    component: Support,
    type: "private",
    exact: true,
  },
  {
    path: "/reset-password/:token",
    component: NewPassword,
    type: "public",
    exact: true,
  },
  {
    path: "/back-office/dashboard",
    component: Dashboard,
    exact: true,
    type: "admin",
  },
  {
    path: "/back-office/login",
    component: AdminLoginPage,
    type: "public",
    exact: true,
  },
  {
    path: "/back-office/admin-signup",
    component: AdminRegisterPage,
    type: "public",
    exact: true,
  },
  // {
  //   path : "/logout",
  //   Component :
  // },
  {
    path: "*",
    component: NotFoundPage,
    type: "public",
  },
];

const AppRouter = (props) => {
  let homeRoute = "/events";
  if (isAuthenticated()) {
    console.log("isAuthenticated", true);
    homeRoute = "/profile";
  }

  const routeComponents = routes.map(
    ({ path, component, exact, type }, key) => {
      if (type === "public") {
        return exact ? (
          <PublicRoute exact path={path} component={component} key={key} />
        ) : (
          <PublicRoute path={path} component={component} key={key} />
        );
      }else if(type === "admin"){
        return exact?(
          <AdminRoute exact path={path} component={component} key={key} />
        ) : (
          <AdminRoute path={path} component={component} key={key} />
        )
      } 
      else {
        return exact ? (
          <PrivateRoute exact path={path} component={component} key={key} />
        ) : (
          <PrivateRoute path={path} component={component} key={key} />
        );
      }
    }
  );

  return (
    <>
      <Suspense fallback={<div className="loader-div"></div>}>
        <Switch>
          {/* <Redirect exact from="/" to={homeRoute} /> */}

          {/* <Route path="/test/:id" component={Test} /> */}
          {routeComponents}
        </Switch>
      </Suspense>
    </>
  );
};

export default AppRouter;

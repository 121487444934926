import { useState } from "react";

function useValidation() {

  // let first_name = userData.first_name;
  //console.log("first_name", first_name);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [firstName, setFirstName] = useState( "");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [bio, setBio] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [city, setCity] = useState(null);
  const [provinces, setProvinces] = useState(null);
  const [country, setCountry] = useState(null);
  // const [nickName, setNickName] = useState("");
  // const [socialLinks, setSocialLinks] = useState({
  //   facebook: "",
  //   twitter: "",
  //   googlePlus: "",
  //   youtube: "",
  //   vimeo: "",
  //   pinterest: "",
  //   instagram: "",
  //   linkedin: "",
  // });

  const [profilePhotoError, setProfilePhotoError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [nickNameError, setNickNameError] = useState(null);
  const [displayNameError, setDisplayNameError] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [retypePasswordError, setRetypePasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [websiteError, setWebsiteError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [bioError, setBioError] = useState(null);
  const [address1Error, setAddress1Error] = useState(null);
  const [address2Error, setAddress2Error] = useState(null);
  const [pincodeError, setPincodeError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [provincesError, setProvincesError] = useState(null);
  const [countryError, setCountryError] = useState(null);

  const validateEmail = (email) => {
    if (
      email &&
      /^$|^(([^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+([._-][^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+)*)|(".+"))@((?!-)[^}@]+)(((\[)?[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(\])?)|(([a-zA-Z0-9-]+\.)+[a-zA-Z1]{2,}))$/i.test(
        email.trim() || ""
      )
    ) {
      setEmailError(null);
      return true;
    } else {
      setEmailError("Please enter a valid email address");
      return false;
    }
  };
  const validatePassword = (newPassword) => {
    if (newPassword.length > 6) {
      if (newPassword) {
        setNewPasswordError(null);
        return true;
      } else {
        setNewPasswordError("Please enter password");
        return false;
      }
    } else {
      setNewPasswordError("Please enter password");
    }
  };
  const checkRetypePassword = (retypePassword) => {
    if (validatePassword(newPassword)) {
      if (retypePassword === newPassword) {
        setRetypePasswordError(null);
        return true;
      } else {
        setRetypePasswordError("Please enter same password");
        return false;
      }
    } else {
      setRetypePasswordError("Please re-enter password");
    }
  };
  const validatePhone = (phone) => {
    let phoneno = /^[+]\d{10,15}$/;
    if(!phone){
      setPhoneError("Please enter phone Number");     
      return false;
    }
    if (phone.match(phoneno) ) {
      setPhoneError(null);
      return true;
    } else {
      setPhoneError("Phone number must start with + sign. Phone number accept only digits. Phone number maximum length 15 digits");
      return false;
    }
  };
  // const validatePostcode = () => {
  //   // let pin = /^\d{5}(?:[-\s]\d{4})?$/;
  //   let postcode=/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
  //   if (pincode.match(postcode) ) {
  //     setPincodeError(null);
  //     return true;
  //   } else {
  //     setPincodeError("Provided Postcode seems to be invalid. Example A1B 2C3.");
  //     return false;
  //   }
  // };
  const validateForm = () => {
    let error = true;
    //console.log("ssssssssssssssss", profilePhoto);
    // if (!profilePhoto || profilePhoto === "") {
    //   //console.log("ssssssssssssssss",profilePhoto);
    //   setProfilePhotoError("Please select an image");
    //   error = false;
    // }
    if (!firstName || firstName === "") {
      setFirstNameError("Please enter first name");
      error = false;
    }else{
      setFirstNameError(null);
    }
    if (!lastName || lastName === "") {
      setLastNameError("Please enter last name");
      error = false;
    }else{
      setLastNameError(null);
    }
    // if (!displayName || displayName === "") {
    //   setDisplayNameError("Please enter nick name");
    //   error = false;
    // }else{
    //   setDisplayNameError(null);
    // }

    // if (!validateEmail(email)) {
    //   error = false;
    // }
    // if (!validatePhone(phone)) {
    //   error = false;
    // }
    // if (!validatePostcode(pincode)) {
    //   error = false;
    // }else{
    //   setPincodeError(null);
    // }
    // if (!city || city === "") {
    //   setCityError("Please enter city");
    //   error = false;
    // }else{
    //   setCityError(null)
    // }
    // if (!provinces || provinces === "") {
    //   setProvincesError("Please enter state/provinces");
    //   error = false;
    // }else{
    //   setProvincesError(null);
    // }
    // if (!country || country === "") {
    //   setCountryError("Please enter country");
    //   error = false;
    // }else{
    //   setCountryError(null);
    // }
    // if (!address1 || address1 === "") {
    //   setAddress1Error("Please enter address");
    //   error = false;
    // }else{
    //   setAddress1Error(null);
    // }
    // if (!address2 || address2 === "") {
    //   setAddress2Error("Please enter address");
    //   error = false;
    // }else{
    //   setAddress2Error(null);
    // }
    return error;
  };
  const validatePasswordForm = () => {
    let error = true;
    if (!validatePassword(newPassword)) {
      error = false;
    }
    if (!checkRetypePassword(retypePassword)) {
      error = false;
    }
    return error;
  };

  const initData = ( userData) =>{
        // console.log("---init data---",userData);
    if(!userData || Object.keys(userData).length === 0 ){
      return
    }
    setFirstName(userData.first_name);
    setLastName(userData.last_name);
    setEmail(userData.email);
    setProfilePhoto(userData.profile_pic && userData.profile_pic.original);
    setDisplayName(userData.display_name);
    setWebsite(userData.website_url);
    setPhone(userData.phone_no);
    setBio(userData.user_bio);
    setAddress1(userData.address && userData.address.address1 );
    setAddress2(userData.address && userData.address.address2);
    setPincode(userData.address && userData.address.pincode);
    setCity(userData.address && userData.address.city);
    setProvinces(userData.address && userData.address.provinces);
    setCountry(userData.address && userData.address.country);
    
  }

  return {
    profilePhoto,
    firstName,
    lastName,
    // nickName,
    displayName,
    newPassword,
    retypePassword,
    email,
    website,
    phone,
    bio,
    pincode,
    address1,
    address2,
    city,
    provinces,
    country,
    // socialLinks,
    // profilePhotoError,
    firstNameError,
    lastNameError,
    // nickNameError,
    // displayNameError,
    // newPasswordError,
    // retypePasswordError,
    // emailError,
    // websiteError,
    // phoneError,
    // bioError,
    // pincodeError,
    // address1Error,
    // address2Error,
    // provincesError,
    // cityError,
    // countryError,
    setProfilePhoto,
    setFirstName,
    setLastName,
    // setNickName,
    setDisplayName,
    setNewPassword,
    setRetypePassword,
    setEmail,
    setWebsite,
    setPhone,
    setBio,
    setPincode,
    setAddress1,
    setAddress2,
    setCity,
    setProvinces,
    setCountry,
    // setSocialLinks,
    validateForm,
    validatePasswordForm,
    initData,
  };
}
export default useValidation;

import React, { useState } from "react";
import useValidationHook from "./validation";
import { useHistory } from "react-router-dom";
const Step = (props) => {
  const [next, setNext] = useState(null);
  const history = useHistory();

  const [stepsData, setStepsData] = useState(props.stepData);
  const validationHook = useValidationHook(props.stepData);
  const handlePushData = () => {
    let stepOneData = {
      title: validationHook.eventName,
      description: validationHook.description,
      discipline: validationHook.discipline,
      file: validationHook.eventImage[0],
      distance: validationHook.distance,
      event_type: validationHook.eventType,
    };
    return stepOneData;
  };
  const handelOnClickNext = () => {
    console.log("clicked", props);
    props.nextStep(handlePushData());
  };
  const handleNext = (e) => {
    e.preventDefault();
    setNext(true);
    if (validationHook.validateForm()) {
      handlePushData();
      handelOnClickNext();
    }
    setTimeout(() => {
      setNext(false);
    }, 1000);
  };

  return (
    <div className="steps-form create-steps">
      <div className="row">
      <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <div className="event_training_checkbox">
              <input
                className="form-check-input radio_button"
                type="radio"
                id="radioButton1"
                
                value="Event"
                name="event/training"
              />
              <label
                className="form-check-label text_size padding_right"
                for="radioButton1"
                onClick={() => {
                  history.push("/create-event");
                }}
              >
                Event
              </label>
            </div>
            <div
              className="event_training_checkbox"
              onClick={() => {
                history.push("/create-training");
              }}
            >
              <input
                className="form-check-input radio_button"
                type="radio"
                id="radioButton2"
                defaultChecked
                value="Training"
                name="event/training"
              />
              <label className="form-check-label text_size" for="radioButton2">
                Training
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <label>Training name</label>
            <input
              className="form-control"
              placeholder="Training title"
              defaultValue={stepsData.title}
              onChange={(e) => {
                validationHook.setEventName(e.target.value);
              }}
              type="text"
            />
            {validationHook.eventNameError && (
              <span className="text-danger">
                {validationHook.eventNameError}
              </span>
            )}
          </div>
          <div className="form-group">
            <label>Training Description</label>
            <textarea
              className="form-control"
              placeholder="Training description"
              rows={6}
              defaultValue={stepsData.description}
              onChange={(e) => {
                validationHook.setDescription(e.target.value);
              }}
            />
            {validationHook.descriptionError && (
              <span className="text-danger">
                {validationHook.descriptionError}
              </span>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Upload Training Image</label>
              <label
                className="form-control custom-file"
                htmlFor="my-file-selector"
              >
                <input
                  id="my-file-selector"
                  type="file"
                  name="uploadImage"
                  style={{ display: "none" }}
                  defaultValue={stepsData.banner}
                  onChange={(e) => {
                    validationHook.setEventImage(e.target.files);
                  }}
                />
                <span className id="upload-file-info">
                  Upload
                </span>
                <i className="fas fa-upload" />
              </label>
              {validationHook.eventImageError && (
                <span className="text-danger">
                  {validationHook.eventImageError}
                </span>
              )}
            </div>
            <div className="col-sm-6 form-group">
              <label>Discipline</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  defaultValue={stepsData.discipline}
                  onChange={(e) => {
                    validationHook.setDiscipline(e.target.value);
                  }}
                >
                  <option>Select Discipline</option>
                  <option value="Running">Running</option>
                  <option value="Cycling">Cycling</option>
                  <option value="Swimming">Swimming</option>
                  <option value="Athlons">Athlons</option>
                </select>
                {validationHook.disciplineError && (
                  <span className="text-danger">
                    {validationHook.disciplineError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Distance</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  defaultValue={stepsData.distance}
                  onChange={(e) => {
                    validationHook.setDistance(e.target.value);
                  }}
                >
                  <option>Select Distance</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
                {validationHook.distanceError && (
                  <span className="text-danger">
                    {validationHook.distanceError}
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <label>Type</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  defaultValue={stepsData.event_type}
                  onChange={(e) => {
                    validationHook.setEventType(e.target.value);
                  }}
                >
                  <option>Select Type</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
                {validationHook.eventTypeError && (
                  <span className="text-danger">
                    {validationHook.eventTypeError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="action">
            <div />
            {next ? (
              <button className="btn">Loading..</button>
            ) : (
              <button
                className="btn btn-orange"
                onClick={handleNext}
                type="submit"
              >
                Next step
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step;

import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import userPic from "../../assets/img/user-pic.png";
import userTwo from "../../assets/img/sample-photos/user2-160x160.jpg";
import { getAdminData } from "../../helpers/utils";
import { secureApiCall } from "../../services/conf/apiCall";

import defaultUser from "../../assets/img/default-user-3.jpg";
// import defaultUser from "../../assets/img/default_user.png"
import moment from "moment";
import "./dashboardNavbar.scss";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const DashboardNavbar = ({onPress}) => {
  const history = useHistory();
  const getTokenData = getAdminData();


  const handelLogout = () => {
    localStorage.removeItem("adminToken");
    history.push("/login");
  };


  return (
    <aside className="main-sidebar">
      {/* sidebar: style can be found in sidebar.less */}
      <section className="sidebar">
        {/* Sidebar user panel */}
        <div className="user-panel">
          <div className="pull-left image">
           <img src={defaultUser} className="img-circle" alt="User Image" />
      
            </div>
          <div className="pull-left info">
            <p>
              {getTokenData &&
                getTokenData.first_name + " " + getTokenData.last_name}
            </p>
            <span
              style={{ color: "#ff5100", cursor: "pointer" , ' paddingLeft': "15px"}}
              onClick={() => handelLogout()}
            >
              Logout
            </span>
          </div>
        </div>
        {/* sidebar menu: : style can be found in sidebar.less */}
        <ul className="sidebar-menu">
          <li className="header">MAIN</li>
          <li className="active">
            <a href="#" onClick={()=>{
              onPress("event");
            }}>
            <i className="fas fa-calendar-alt"></i>
            &emsp;<span>All Events</span>
            </a>
          </li>
          <li className="">
            <a href="#" onClick={()=>{
              onPress("training");
            }}>
            <i className="fas fa-walking"></i>
            &emsp;<span>All Training</span>
            </a>
          </li>
          <li className="">
            <a href="#" onClick={()=>{
              onPress("tribe");
            }}>
              
            <i className="fas fa-suitcase"></i>
            &emsp;<span>All Tribes</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={()=>{
              onPress("user");
            }}>
              <i className="fas fa-user-friends"></i>
              &emsp;<span>All Users </span>
            </a>
          </li>
          {/* <li>
            <a href="/joined-events">
              <i className="fas fa-arrow-circle-left"></i>
              &emsp;<span>Back to site</span>
            </a>
          </li> */}
        </ul>
      </section>
      {/* /.sidebar */}
    </aside>
  );
};

export default DashboardNavbar;

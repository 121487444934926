import React, { useEffect, useState } from 'react'
import Header from "../../components/header";
import Navbar from "../../components/navbar";
import { secureApiCall } from "../../services/conf/apiCall";
import {useParams} from "react-router-dom";
import  eventImage from "../../assets/img/sample-photos/event5.png"
import defaultFeatureImage from "../../assets/img/feature_image.jpg";
import {disciplines} from "../../constants/global";
import {getStartDateOfWeek,getTodayDate,getStartingDateOfMonth} from "../../helpers/utils";
import "./myJoinedEvents.scss"
import moment from "moment";

const MyJoinedEvents = (props) => {
    let params=useParams()
    let addClass="active-class";

    const joinedEventsUrl=process.env.REACT_APP_API_ENDPOINT+"/api/joined-events";
    const MyEventUrl = process.env.REACT_APP_API_ENDPOINT + `/api/list-events/${params.slug}`
    let interestedEventsUrl =process.env.REACT_APP_API_ENDPOINT + `/api/interested-events`;

    const[disciplineData,setDisciplineData]=useState(disciplines);
    const [durationFilter,setDurationFilter]=useState("");
    const [eventTypeFilter,setEventTypeFilter]=useState("");
    const [disciplineFilter,setDisciplineFilter]=useState("");  
    const [myEventsData,setMyEventsData]=useState();
    const [isActive,setIsActive] = useState(true);
    const [selectedEvent,setSelectedEvent]=useState("event");  
    const [selectedEventCategory,setSelectedEventCategory]=useState("Joined");  



    useEffect(()=>{
        secureApiCall(`${joinedEventsUrl}?event_type=event&eventCategory=${selectedEventCategory}`,'get')
        .then((response)=>{
            console.log("--mye",response)
            if(!response.err){
                setMyEventsData(response.payload);
            }
        }).catch((err)=>{
            console.log(err);
        })
    },[])

    const handleEventFilters=(eventType)=>{
        console.log('eventType',eventType);
        secureApiCall(`${joinedEventsUrl}?&event_type=${eventType}&discipline=${disciplineFilter}&eventCategory=${selectedEventCategory}`, "get")
          .then((response) => {
            console.log(response);
            setMyEventsData(response.payload);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      
    return (
        <div>
        <Header></Header>
        <Navbar></Navbar>
         <div className="content-wrapper">
        <section className="content-header-new">
            <h1>
            <span className={isActive ? addClass : "margin_right"} style={{cursor:'pointer'}} onClick={()=>{
                setIsActive(true);
                // handleEventFilters("event")
                handleEventFilters("event");
                setSelectedEvent('event')
            }}>Events </span>
            <span className={isActive ? "margin_right"  : addClass } style={{cursor:'pointer'}} onClick={()=>{
                setIsActive(false);
                setSelectedEvent('training')
                handleEventFilters("training")
                // handleEventFilters("training");
            }} >Training </span>
            </h1>
        </section>
        <section className="filters">
            <div className="container m-0">
            <div className="form-horizontal">
                <div className="form-group">
                <label htmlFor className="col-lg-1 col-md-2 control-label">
                    Filter By
                </label>
                {/* <div className="col-lg-2 col-md-3">
                    <select name="duration" id className="form-control input-dashed" onChange={(e)=>setDurationFilter(e.target.value)}>
                        <option selected value="">&nbsp;Select Duration</option>
                          <option value="today" >Today</option>
                          <option value="this_week">This week</option>
                          <option value="this_month">This Month</option>
                          <option value="all">All</option>
                    </select>   
                </div> */}
                {/* <div className="col-lg-2 col-md-2">
                    <select name="event_type" id className="form-control input-dashed"  onChange={(e)=>setEventTypeFilter(e.target.value)}>
                        <option value="event" selected>Event</option>
                        <option value="training">Training</option>
                    </select>
                </div> */}
                <div className="col-lg-6 col-md-6 flex">
                    <div className="input-group select">
                    
                    <div style={{flexDirection:'row', display:'flex', justifyContent:'space-between'}}>
                    <label className="radio-inline">
              <input
                name="optradio"
                defaultChecked
                type="radio"
                value="Joined"
                onChange={(e) => {
                    setSelectedEventCategory(e.target.value);
                }}
              />
              Joined
            </label>
            <label className="radio-inline">
              <input
                name="optradio"
                type="radio"
                value="Interested"
                onChange={(e) => {
                    setSelectedEventCategory(e.target.value);

                }}
              />
              Interested
            </label>

            <select name="discipline" id 
            style={{marginLeft:'10px'}}
            className="form-control input-dashed" onChange={(e)=>setDisciplineFilter(e.target.value)}>
                          <option selected value="">&nbsp;Select Discipline Type</option>
                          {disciplineData && disciplineData.map((discipline,index)=>(
                            <option key={index}>{discipline.name}</option>
                          ))}

                        </select>

                        <div className="input-group-addon">
                        <button 
                            className="new-btn new-btn-secondary" 
                            style={{borderRadius:"20px"}} 
                            onClick={()=>
                            handleEventFilters(selectedEvent)

                            } 
                        >
                            <span className="fas fa-search" /> Search
                        </button>
                    </div>
                    </div>
            
            
                        

                  
                    </div>
                </div>
          
            <br />
            <br />
          </div>
              
            </div>
            </div>
        </section>
        <section className="content">
            <div className="row">
           {myEventsData
           ?
            myEventsData.map((value,index)=>(
                    <div key={index} className="col-lg-4 col-sm-6 col-xs-12">
                    <a href={`/event/${value.slug}`} className="event-box">
                    {value.banner.length===0?
                        <div
                            className="event-img"
                            style={{ backgroundImage: `url(${defaultFeatureImage})` }}
                        >
                            <span className="participants">
                            <span>{value.total_joined} </span>
                            Participants Confirmed
                            </span>
                        </div>
                        :
                        <div
                            className="event-img"
                            style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL_ENDPOINT+value.banner[0].featureImage})` }}
                        >
                            <span className="participants">
                            <span>{value.total_joined} </span>
                            Participants Confirmed
                            </span>
                        </div>
                    }
                    <span className="event-description">
                        <h5>
                        <div className='ellipsis-text'>
                            {value.title}

                            </div>
                        </h5>
                        <p>You Joined on {moment(value.date).format('ll')} </p>
                    </span>
                    </a>
                </div>
                ))
            :
            <div>No Data Available</div>
            }
            </div>
        </section>
        </div>
        </div>
    )
}

export default MyJoinedEvents

import React, { useEffect, useState } from "react";
import Stepper from "react-js-stepper";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import PageHeading from "../../components/pageHeading";
import Step1 from "../../components/createEventNew/step1";
import Step2 from "../../components/createEventNew/step2";
import Step3 from "../../components/createEventNew/step3";
import Step4 from "../../components/createEventNew/step4";
import "./createEventNew.scss";
import { useParams } from "react-router-dom";
import { secureApiCall } from "../../services/conf/apiCall";


const CreateEventNew = () => {

  const steps = [
    { title: "Information" },
    { title: "Location & Time" },
    { title: "Details" },
    { title: "Invite" },
  ];
  const [activeStep, setActiveStep] = useState(1);
  const [eventId,setEventId]=useState([]);
  const [stepOneData, setStepOneData] = useState({
    title:"",
    description: "",
    discipline: "",
    file: "",
    distance: "",
    discipline_type: "",
    event_type:"event",
  });
  console.log("Step One Data=====>",stepOneData);

  const [stepTwoData, setStepTwoData] = useState({
    location: "",
    country:"United Kingdom",
    region: "",
    date:"",
    start_time: "00:00",
    end_time: "00:00",
  });

  console.log("Step Two Data=====>",stepTwoData);

  const [stepThreeData, setStepThreeData] = useState({
    total_distance: "",
    time: "",
    average_speed: "",
    upload_map: "",
    food_stop: "",
    visibility: "",
    approval_required:false,
    external_link:"",
  });

  console.log("Step Three Data=====>",stepThreeData);

  const handleOnClickStepper = (step) => {
    setActiveStep(step);
  };

  const handleOnClickNext = (val) => {
    console.log("next click");
    let nextStep = activeStep;
    if (activeStep === 1) {
      setStepOneData(val);
      nextStep = activeStep + 1;

    }
    if (activeStep === 2) {
      setStepTwoData(val);
      nextStep = activeStep + 1;

    }
    if (activeStep === 3) {
      // setStepThreeData(val);
      // console.log(val.eventRes._id)
      setEventId({
        'event_id':val.eventRes._id,
        'event_name':val.eventRes.title,
        'event_type':val.eventRes.event_type,
      })
      nextStep = activeStep + 1;
    }

    setActiveStep(nextStep);

    //this.setState({ activeStep: nextStep });
  };

  const handleOnClickBack = () => {
    console.log("back click");
    if( activeStep !==4){
      let prevStep = activeStep - 1;
      setActiveStep(prevStep);
    }

  };



  return (
    <>
      <Header></Header>
      <PageHeading title="CREATE YOUR NEXT EVENT/TRAINING SESSION"></PageHeading>

      <section className="main bg-transparent">
        <div className="steps-area">
          <div className="stepper-container form-check ">
            <div className="stepper-container"></div>
          </div>

          <Stepper
            className="new-step"
            steps={steps}
            activeStep={activeStep}
            onSelect={handleOnClickStepper}
            showNumber={false}
          />

          <div className="step_content ">
            {activeStep === 1 && (
              <Step1
                stepData={stepOneData}
                isEdit={false}
                nextStep={handleOnClickNext}
              ></Step1>
            )}
            {activeStep === 2 && (
              <Step2
                stepData={stepTwoData}
                isEdit={false}
                nextStep={handleOnClickNext}
                prevStep={handleOnClickBack}
              ></Step2>
            )}
            {activeStep === 3 && (
              <Step3
                stepData={stepThreeData}
                stepData1={stepOneData}
                stepData2={stepTwoData}
                isEdit={false}
                prevStep={handleOnClickBack}
                nextStep={handleOnClickNext}
              ></Step3>
            )}
            {activeStep === 4 && (
              <Step4
                // dataStepTwo={stepTwoData}
                stepData={eventId}
                isEdit={false}
                prevStep={handleOnClickBack}
                nextStep={handleOnClickNext}
              ></Step4>
            )}
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default CreateEventNew;

import { useState } from "react";

export default function useValidation() {
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");

  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const validateEmail = (userEmail) => {
    if (
      userEmail &&
      /^$|^(([^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+([._-][^<>()[\]{}/`~#$%&^*=?|!\\._,;:\s@"-]+)*)|(".+"))@((?!-)[^}@]+)(((\[)?[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(\])?)|(([a-zA-Z0-9-]+\.)+[a-zA-Z1]{2,}))$/i.test(
        userEmail.trim() || ""
      )
    ) {
      setEmailError(null);
      return true;
    } else {
      setEmailError("Please enter a valid email address");
      return false;
    }
  };
  const validatePassword = (password) => {
    if (password.length > 6) {
      if (password) {
        setPasswordError(null);
        return true;
      } else {
        setPasswordError("Please enter password");
        return false;
      }
    } else {
      setPasswordError("Please enter password");
    }
  };

  const validationForm = () => {
    let error = true;
    if (!userFirstName || userFirstName === "") {
      setFirstNameError("Please enter first name");
      error = false;
    }
    if (!userLastName || userLastName === "") {
      setLastNameError("Please enter last name");
      error = false;
    }
    if (!validateEmail(userEmail)) {
      error = false;
    }
    if (!validatePassword(password)) {
      error = false;
    }
    return error;
  };

  return {
    userFirstName,
    userLastName,
    userEmail,
    password,
    firstNameError,
    lastNameError,
    emailError,
    passwordError,
    setUserFirstName,
    setUserLastName,
    setUserEmail,
    setPassword,
    validationForm,
  };
}

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PageHeading from "../../components/pageHeading";
import Header from "../../components/publicHeader";
import Footer from "../../components/footer";
import {secureApiCall} from "../../services/conf/apiCall";
import Spinner from "../../components/spinner";
import { ToastContainer, toast } from "react-toastify";

import "./emailVerify.scss";

const EmailVerify = (props) => {
  const params=useParams();
  const history=useHistory();
  console.log("EmailVerify -> params", params.token)
  const [status,setStatus]=useState("Verify");
  const [viewVerifiedButton,setViewVerifiedButton]=useState(false)
  const verifyUserUrl=process.env.REACT_APP_API_ENDPOINT+`/verify/${params.token}`;

  const handleVerifyUser=()=>{
    setViewVerifiedButton(true);
    secureApiCall(verifyUserUrl,'get')
    .then((response)=>{
      setStatus('Verified');
      toast.success(response.msg);
      console.log("handleVerifyUser -> response", response);
      history.push("/login");
    })
    .catch((err)=>{
      toast.error(err.msg);
      console.log("handleVerifyUser -> err", err);
    })
  }
  return (
    <>
      <Header></Header>
      <PageHeading title={'Verify Your Email'}></PageHeading>     
      <section className="main bg-transparent">
        <div className="steps-area">
          <div className="steps-form single-event">
            <div className="row">
              <div  style={{textAlign:'center'}}>
                  <p>Please verify your email with the click on this button.</p>
                    <button className="interest-btn interest-btn-primary" onClick={handleVerifyUser} >{status}</button>
              </div>
            </div>
           </div>
        </div>
      </section>
    
      <div className="clearfix"></div>  
      <Footer></Footer>
    </>
  );
};

export default EmailVerify;

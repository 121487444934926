import React, { useState } from "react";
import useValidationHook from "./validation";
const Step = (props) => {
  const [stepData, setStepData] = useState(props.stepData);
  const [next, setNext] = useState(null);
  const validationHook = useValidationHook(props.stepData);

  console.log("dsdsdsdsds=======",props.stepData1,props.stepData2)
  const handleNext = (e) => {
    e.preventDefault();
    setNext(true);
    if (validationHook.validateForm()) {
      handelNextClick();
    }
    setTimeout(() => {
      setNext(false);
    }, 1000);
  };
  const handelNextClick = () => {
    props.nextStep();
  };

  const handelPrevClick = (e) => {
    props.prevStep();
  };
  return (
    <div className="steps-form create-steps">
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <div className="form-group">
            <label>Total Distance</label>
            <input
              className="form-control"
              placeholder="Distance(in miles)"
              defaultValue={stepData.total_distance}
              type="text"
              onChange={(e) => {
                validationHook.setTotalDistance(e.target.value);
              }}
            />
            {validationHook.totalDistanceError && (
              <span className="text-danger">
                {validationHook.totalDistanceError}
              </span>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Time</label>
              <input
                className="form-control"
                placeholder="Time"
                defaultValue={stepData.time}
                type="text"
              />
              {validationHook.timeError && (
                <span className="text-danger">{validationHook.timeError}</span>
              )}
            </div>
            <div className="col-sm-6 form-group">
              <label>Average Speed</label>
              <input
                className="form-control"
                placeholder="Average Speed"
                defaultValue={stepData.average_speed}
                type="text"
              />
              {validationHook.averageSpeedError && (
                <span className="text-danger">
                  {validationHook.averageSpeedError}
                </span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Upload Map</label>
            {/* <input type="file" id="exampleInputFile"> */}
            <label
              className="form-control custom-file"
              htmlFor="my-file-selector"
            >
              <input
                id="my-file-selector"
                type="file"
                style={{ display: "none" }}
                defaultValue={stepData.upload_map}
                onChange={(e) => {
                  validationHook.uploadMap(e.target.files);
                }}
              />
              <span className id="upload-file-info">
                No file Uploaded
              </span>
              <i className="fas fa-upload" />
            </label>
            {validationHook.uploadMapError && (
              <span className="text-danger">
                {validationHook.uploadMapError}
              </span>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label>Pit Stops</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  defaultValue={stepData.food_stop}
                  onChange={(e) => {
                    validationHook.setFoodStop(e.target.value);
                  }}
                >
                  <option>Select Pit Stops</option>
                  <option>Yes</option>
                  <option>No</option>

                </select>
                {validationHook.foodStopError && (
                  <span className="text-danger">
                    {validationHook.foodStopError}
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <label>Visibility</label>
              <div className="select-wrap">
                <select
                  className="form-control"
                  defaultValue={stepData.visibility}
                  onChange={(e) => {
                    validationHook.setVisibility(e.target.value);
                  }}
                >
                  <option>Select Visibility</option>
                  <option>Private</option>
                  <option>Public</option>
                  
                </select>
                {validationHook.visibilityError && (
                  <span className="text-danger">
                    {validationHook.visibilityError}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="action">
            <a
              href="#"
              className="icon-btn"
              onClick={(e) => {
                handelPrevClick();
              }}
            >
              <i className="ion-android-arrow-back" />
            </a>
            {next ? (
              <button className="btn btn-orange">Loading</button>
            ) : (
              <button
                className="btn btn-orange"
                onClick={handleNext}
                type="submit"
              >
                Publish
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <div className="row">
          <div className="col-md-12 text-center">
              <div className="form-group">
                  <label>Would you like to invite all your friends?</label>
                  <div className="clearfix"></div>
                  <label className="radio-inline">
                    <input type="radio" name="optradio" checked>Yes
                  </label>
                  <label className="radio-inline">
                    <input type="radio" name="optradio">No
                  </label>
                  <br><br>
              </div>
          </div>
      </div>
      <div className="row">
          <div className="col-md-6 col-md-offset-3 text-center">
              <div className="form-group">
                  <label>Search people to invite to your friends</label>
                  <div className="search-container">
                      <input className="form-control" placeholder="Search people here" type="text">
                      <button className="search-btn" type="button"><i className="ion-search"></i></button>
                  </div>
              </div>
          </div>
      </div>
      <div className="row">
          <div className="col-md-12 text-center">
              <button className="btn btn-orange btn-border" type="submit">Go back</button> &nbsp;&nbsp;
              <button className="btn btn-orange" type="submit">Publish event</button>
          </div>
      </div> */}
    </div>
  );
};

export default Step;
